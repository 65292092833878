.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;

  .status {
    font-family: Avenir;
    font-size: 24px;
    font-weight: 900;
    line-height: 24px;
    color: #000;
    margin-bottom: 20px;
    text-align: center;
  }

  .msg {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: #000;
    text-align: center;
  }

  .final_email {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: #000;
    text-align: center;
  }

  .reason {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: #000;
    text-align: center;
  }

  .select_field {}

  .input_field {
    margin: 0px;
  }

  .submit_btn {}
}