#credit-card-form .StripeElement {
  padding-bottom: 15px;
  border: 0;
  border-bottom: 1px solid #b3b3b3;
}

#credit-card-form .StripeElement.StripeElement--invalid {
  border-color: #ff4d4f;
}
.ElementsApp input {
  color: white !important;
}
.darkThemeEnabled {
  .MuiSelect-select:focus {
    background-color: #252728 !important;
  }
}
.whiteThemeEnabled {
  .MuiSelect-select:focus {
    background-color: #fff !important;
  }
}
