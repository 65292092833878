.container {
  display: flex;
  gap: 10px;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap-reverse;
  }

  .exportBtn {
    height: 40px;
    min-width: 112px;
    gap: 10px;
    border-radius: 2px;
    color: #215c73;
    border: 1px solid #215c73;
    // padding: 16px 20px;
    background-color: white;
    font-family: Avenir;
    font-size: 14px;
    font-weight: 900;
    line-height: 16px;

    svg {
      margin-right: 10px;
      vertical-align: bottom;
    }
  }

  .importBtn {
    height: 40px;
    min-width: 130px;
    gap: 10px;
    border-radius: 2px;
    color: #215c73;
    border: 1px solid #215c73;
    // padding: 16px 20px;
    background-color: white;
    font-family: Avenir;
    font-size: 14px;
    font-weight: 900;
    line-height: 16px;

    svg {
      margin-right: 10px;
      vertical-align: bottom;
    }
  }

  .addLeadBtn {
    height: 40px;
    min-width: 112px;
    gap: 10px;
    border-radius: 4px;
    color: white;
    border: 1px solid #c9b382;
    // padding: 16px 20px;
    background-color: #c9b382;
    font-family: Avenir;
    font-size: 14px;
    font-weight: 900;
    line-height: 16px;

    svg {
      margin-right: 5px;
      vertical-align: bottom;
    }
  }

  .createNewTagBtn {
    height: 40px;
    min-width: 112px;
    gap: 10px;
    border-radius: 4px;
    color: white;
    border: 1px solid #c9b382;
    padding: 0px 15px;
    background-color: #c9b382;
    font-family: Avenir;
    font-size: 14px;
    font-weight: 900;
    line-height: 16px;

    svg {
      margin-right: 5px;
      vertical-align: bottom;
    }
  }

  .bodyContainer {
    width: 100%;
    padding: 60px 45px;
    margin-top: 15px;
    border: 1.2px solid #dfe3e4;
    border-radius: 4px;
    background-color: #ffffff;

    .slider {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}