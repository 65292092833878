@import '~utils/styles/styles.scss';
#react-joyride-step-0 {
  .__floater__open {
    top: 40px !important;

    @media screen and (max-width: 576px) {
      width: 205px !important;
    }
  }

  .__floater__body {
    .react-joyride__tooltip {
      padding-left: 0px !important;
      padding-bottom: 0px !important;
      padding-right: 0px !important;

      .step0 {
        .image {
          @media screen and (max-width: 576px) {
            left: 15px !important;
          }
        }

        .buttons {
          @media screen and (max-width: 576px) {
            flex-direction: column !important;
          }

          .btn-left {
            @media screen and (max-width: 576px) {
              margin-top: 10px !important;
              display: flex !important;
              flex-direction: column !important;
              justify-content: center !important;
              align-items: center !important;
              padding: 7px 16px !important;
              width: 153px !important;
              height: 29px !important;
              font-family: 'Avenir' !important;
              font-style: normal !important;
              font-weight: 800 !important;
              font-size: 14px !important;
              line-height: 20px !important;
              letter-spacing: -0.24px !important;
            }
          }

          .btn-right {
            @media screen and (max-width: 576px) {
              margin-top: 10px !important;
              margin-left: 0px !important;
              display: flex !important;
              flex-direction: column !important;
              justify-content: center !important;
              align-items: center !important;
              padding: 7px 16px !important;
              width: 63px !important;
              height: 29px !important;
              font-family: 'Avenir' !important;
              font-style: normal !important;
              font-weight: 800 !important;
              font-size: 14px !important;
              line-height: 20px !important;
              letter-spacing: -0.24px !important;
            }
          }
        }
      }
    }

    .__floater__arrow {
      span {
        top: 0px !important;
      }
    }
  }
}

#react-joyride-step-1 {
  .__floater__open {
    @media screen and (max-width: 576px) {
      width: 250px !important;
    }
  }

  .__floater__body {
    .react-joyride__tooltip {
      padding-left: 0px !important;
      padding-bottom: 0px !important;
      padding-right: 0px !important;

      .step1 {
        .image {
          @media screen and (max-width: 576px) {
            left: 15px !important;
          }
        }

        .buttons {
          .btn-left {
            @media screen and (max-width: 576px) {
              margin-top: 10px !important;
              display: flex !important;
              flex-direction: column !important;
              justify-content: center !important;
              align-items: center !important;
              padding: 7px 16px !important;
              width: 153px !important;
              height: 29px !important;
              font-family: 'Avenir' !important;
              font-style: normal !important;
              font-weight: 800 !important;
              font-size: 14px !important;
              line-height: 20px !important;
              letter-spacing: -0.24px !important;
            }
          }

          .btn-right {
            @media screen and (max-width: 576px) {
              margin-top: 10px !important;
              margin-left: 10px !important;
              display: flex !important;
              flex-direction: column !important;
              justify-content: center !important;
              align-items: center !important;
              padding: 7px 16px !important;
              width: 63px !important;
              height: 29px !important;
              font-family: 'Avenir' !important;
              font-style: normal !important;
              font-weight: 800 !important;
              font-size: 14px !important;
              line-height: 20px !important;
              letter-spacing: -0.24px !important;
            }
          }
        }
      }
    }

    .react-joyride__tooltip:nth-child(1) :nth-child(1) {
      // padding-bottom: 5px !important;
    }
  }
}

#react-joyride-step-2 {
  .__floater__body {
    .react-joyride__tooltip {
      padding-left: 0px !important;
      padding-bottom: 0px !important;
      padding-right: 0px !important;

      .step2 {
        .image {
          @media screen and (max-width: 576px) {
            left: 15px !important;
          }
        }

        .buttons {
          .btn-left {
            @media screen and (max-width: 576px) {
              margin-top: 10px !important;
              display: flex !important;
              flex-direction: column !important;
              justify-content: center !important;
              align-items: center !important;
              padding: 7px 16px !important;
              // width: 153px !important;
              height: 29px !important;
              font-family: 'Avenir' !important;
              font-style: normal !important;
              font-weight: 800 !important;
              font-size: 14px !important;
              line-height: 20px !important;
              letter-spacing: -0.24px !important;
            }
          }
        }
      }
    }
  }
}

//this is for integration tab
#react-joyride-step-4 {
  .__floater__body {
    width: 220px !important;

    .react-joyride__tooltip {
      padding-left: 0px !important;
      padding-bottom: 0px !important;
      padding-right: 0px !important;

      .step4 {
        .image {
          left: 15px !important;

          @media screen and (max-width: 576px) {
            left: 15px !important;
          }
        }

        .buttons {
          .btn-left {
            @media screen and (max-width: 576px) {
              margin-top: 10px !important;
              display: flex !important;
              flex-direction: column !important;
              justify-content: center !important;
              align-items: center !important;
              padding: 7px 16px !important;
              // width: 153px !important;
              height: 29px !important;
              font-family: 'Avenir' !important;
              font-style: normal !important;
              font-weight: 800 !important;
              font-size: 14px !important;
              line-height: 20px !important;
              letter-spacing: -0.24px !important;
            }
          }
        }
      }
    }
  }
}

#react-joyride-step-5 {
  .__floater__body {
    width: 220px !important;

    .react-joyride__tooltip {
      padding-left: 0px !important;
      padding-bottom: 0px !important;
      padding-right: 0px !important;

      .step4 {
        .image {
          left: 15px !important;

          @media screen and (max-width: 576px) {
            left: 15px !important;
          }
        }

        .buttons {
          .btn-left {
            @media screen and (max-width: 576px) {
              margin-top: 10px !important;
              display: flex !important;
              flex-direction: column !important;
              justify-content: center !important;
              align-items: center !important;
              padding: 7px 16px !important;
              // width: 153px !important;
              height: 29px !important;
              font-family: 'Avenir' !important;
              font-style: normal !important;
              font-weight: 800 !important;
              font-size: 14px !important;
              line-height: 20px !important;
              letter-spacing: -0.24px !important;
            }
          }
        }
      }
    }
  }
}

#react-joyride-step-6 {
  .__floater__body {
    width: 220px !important;

    .react-joyride__tooltip {
      padding-left: 0px !important;
      padding-bottom: 0px !important;
      padding-right: 0px !important;

      .step4 {
        .image {
          left: 15px !important;

          @media screen and (max-width: 576px) {
            left: 15px !important;
          }
        }

        .buttons {
          .btn-left {
            @media screen and (max-width: 576px) {
              margin-top: 10px !important;
              display: flex !important;
              flex-direction: column !important;
              justify-content: center !important;
              align-items: center !important;
              padding: 7px 16px !important;
              // width: 153px !important;
              height: 29px !important;
              font-family: 'Avenir' !important;
              font-style: normal !important;
              font-weight: 800 !important;
              font-size: 14px !important;
              line-height: 20px !important;
              letter-spacing: -0.24px !important;
            }
          }
        }
      }
    }
  }
}

.calendar-drawer > div {
  width: 80%;
  padding: 40px 24px;
}

.calender-current-view-select-button {
  display: flex;
  flex-flow: nowrap;
  justify-items: self-end;
  font-family: Avenir;
  background: #f5f5f5;
  font-size: 16px;
  padding: 6px;
  color: black;
  border-radius: 4px;
  border: 1px solid #dfe3e4;
  float: left;
}

.calender-current-view-select {
  font-family: Avenir;
  background: #f5f5f5;
  font-size: 16px;
  color: #4a4a4a;
  max-height: 40px;
  border-radius: 4px;
  border: 1px solid #dfe3e4;
  float: left;
  @media screen and (max-width: $mobile-size) {
    border: 0px solid transparent;
    .MuiOutlinedInput-notchedOutline {
      border-color: transparent !important;
    }
  }

  &:focus {
    border: 1px solid #dfe3e4;
    background-color: unset;
  }

  div {
    &.MuiOutlinedInput-input {
      background-color: unset;
    }
  }
}

.account_header_title {
  font-family: Avenir;
  font-size: 18px;
  font-weight: 500;
  color: black;
  cursor: pointer;
}

.account_img_avatar_root {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.account_btn_title {
  font-weight: 500 !important;
}
