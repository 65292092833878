.container {
  width: 105px;
  height: 28px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 6px;
  border-radius: 4px;
  background-color: #e7e7e7;

  .icon {
    display: flex;
  }

  .type_name {
    font-family: Avenir;
    font-size: 10px;
    font-weight: 800;
    line-height: 24px;
    color: #213649;
  }
}
