.calendar-container-custom {
  margin-top: 0px;
  padding: 0px 0px 16px 0px;
  font-family: 'Brandon Text';
  justify-content: center;
  font-style: normal;
  font-size: 17px;
  font-weight: 600;
  margin: auto;

  .easy-booking-title {
    font-family: Avenir;
    font-size: 20px;
    font-weight: 850;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 10px;
    margin-bottom: 0;
    color: #215c73;
    text-align: center;
  }

  .non-easy-booking-title {
    display: none;
  }
}

.toolbar-container {
  display: flex;
  justify-content: center !important;
  align-items: baseline;
  height: 0px;
  margin-bottom: 67px;
  padding: 0px 10px 0px 5px;

  &.calendly {
    align-items: center !important;
    height: auto !important;
    margin-top: 20px;
    margin-bottom: 20px !important;
    padding: 0px 5px 0px 5px !important;
    .btn-current {
      padding: 0 !important;
    }
    .navigation-buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-height: 100%;
      gap: 10px;
      svg {
        cursor: pointer;
      }
    }
  }
}

.client-popper {
  .MuiPaper-root {
    .MuiAutocomplete-listbox {
      padding-top: 0px !important;
    }
  }
}

.btn-back {
  border: none;
  padding: 10px;
  margin: 3px;
  font-size: 30px;
}

.btn-current {
  border: none;
  padding: 8px 20px;
  margin: 3px;
}

.btn-next {
  border: none;
  padding: 10px;
  margin: 3px;
  font-size: 30px;
}

.label-date {
  display: flex;
  justify-content: center;
  align-items: center;
}
