$text-color-main: rgba(0, 0, 0, 0.87);

body {
  color: $text-color-main;
}

a {
  color: inherit;

  &:hover {
    color: inherit;
  }
}

.add-suffix {
  display: inline-block;
  position: relative;
  &::after {
    position: absolute;
    top: 30px;
    right: 1em;
    transition: all 0.05s ease-in-out;
  }
  &.percent-suffix::after {
    content: '%';
  }
  &.dollar-suffix::after {
    content: '$';
  }
  &:hover::after,
  &:focus-within::after {
    right: 1.9em;
  }
}

@supports (-moz-appearance: none) {
  .add-suffix::after {
    right: 1.9em;
  }
}

.pointer {
  cursor: pointer;
}

.input-suffix {
  .MuiInputBase-input {
    margin: 5px !important;
  }
}
