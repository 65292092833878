@import 'utils/styles/styles.scss';
.services-provider {
  padding: 0 240px;
  padding-top: 32px;

  .tile-bar-root {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%);
  }
  .service-item {
    margin-top: 5px;
  }
}

@media screen and (max-width: $laptop) {
  .services-provider {
    padding: 0 16px;
    padding-top: 16px;
  }
}
