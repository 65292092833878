.tabsStyle {
  position: relative;
  background-color: #6999ca;
  padding: 12px 4px;
  margin: 0px;
  border-radius: 10px 10px 0px 0px;
  cursor: pointer;
  /* color: #fff !important; */
  flex: 1;
}

.tabsStyleTem2 {
  position: relative;
  background-color: #b78aea;
  padding: 10px 4px;
  margin: 0px;
  border-radius: 10px 10px 0px 0px;
  cursor: pointer;
  /* color: #fff !important; */
  flex: 1;
}

.coverImage {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.coverImageTempTwo {
  display: flex;
  justify-content: center;
  /* padding: 10px; */
}

.templateOneImage {
  width: 100%;
  border-radius: 12px;
}

.templateTwoImage {
  width: 100%;
  border-radius: 12px;
}

.templateTwoImageMobile {
  width: 100%;
}

.noImageBackground {
  width: 100%;
  border-radius: 12px;
  max-height: 350px;
}

.stickyPaymentCard {
  position: sticky;
  top: 128px;
  width: 100%;
  margin-left: 15px;
}

@media screen and (min-width: 992) {
  .stickyPaymentCard {
    position: relative;
    margin-top: 20;
    margin-left: 15px;
  }
}

@media (max-height: 696px) {
  .templateTwoImage {
    height: 56%;
    /* Adjust this value accordingly */
  }
}

@media (min-height: 697px) and (max-height: 773px) {
  .templateTwoImage {
    height: 63%;
    /* Adjust this value accordingly */
  }
}

@media (min-height: 774px) and (max-height: 870px) {
  .templateTwoImage {
    height: 56%;
    /* Adjust this value accordingly */
  }
}

@media (min-height: 871px) and (max-height: 928px) {
  .templateTwoImage {
    height: 59%;
    /* Adjust this value accordingly */
  }
}

.session-items-one-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

@media (max-width: 576px) {
  .session-items-one-line {
    white-space: nowrap;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .templateOneHeading {
    .templateOnePadding {
      padding: 0px 10px !important;
    }
  }

  .templateOneImage {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
  }

  .noImageBackground {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}