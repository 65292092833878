.main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  .step_number {
    width: 40px;
    height: 40px;
    border: 1px solid #215c73;
    color: #215c73;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Avenir';
    font-size: 20px;
    font-weight: 800;
    line-height: 16px;
  }

  .step_description {
    font-family: 'Avenir';
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.87);
  }
}
