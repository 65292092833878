@import '../../../../utils/styles/styles.scss';

.container {
    p {
        margin-bottom: 0px;
    }

    .title {
        font-family: $font-family-Avenir;
        font-weight: 800;
        font-size: 16px;
    }

    .checkbox_container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 16px 0px;

        .checkbox {
            color: $primary-btn-color;
            padding: 0px 10px 0px 0px;
        }

        .checkbox_label {
            font-family: $font-family-Avenir;
            font-weight: 400;
            font-size: 16px;
        }
    }

    .selected_contacts_container {
        padding-bottom: 14px;

        .delete_title {
            font-family: $font-family-Avenir;
            font-weight: 800;
            font-size: 16px;
        }

        .selected_contacts {
            font-family: $font-family-Avenir;
            font-weight: 400;
            font-size: 16px;
        }
    }
}