@import '~utils/styles/styles.scss';

.client-details-container {
  background-color: white;

  .client-detail-header {
    padding: 20px;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #e7e7e7;

    .buttons-container {
      margin-left: auto;
      display: flex;
      gap: 10px;
      align-items: center;

      .MuiSvgIcon-root {
        font-size: 20px !important;
        color: #215c73;
      }

      .MuiButtonBase-root {
        padding: 10px 15px;
        text-transform: none;

        .MuiSvgIcon-root {
          font-size: 20px !important;
          color: #215c73;
        }
      }
    }

    @media screen and (max-width: $tablet) {
      flex-direction: column;

      .client-info {
        text-align: center;
      }

      .buttons-container {
        margin: 0 auto;
      }
    }
  }

  .title {
    background-color: #e7e7e7;
    padding: 10px;
    font-size: 20px;
    font-weight: 700;
    cursor: inherit;
  }

  .client-info-container {
    padding: 20px;

    .MuiSvgIcon-root {
      font-size: 18px !important;
    }

    .client-info-detail {
      line-height: 24px;
      padding: 10px;
      border-bottom: 1px solid #e7e7e7;

      p {
        &:last-child {
          margin-bottom: 0px;
        }
      }

      &:last-child {
        border-bottom: 0px;
      }
    }

    .contributions-link {
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      text-decoration: underline;

      &:hover {
        font-weight: bold;
      }
    }

    .client-info-data-table-container {
      display: flex;
      flex-direction: column;
      // height: 100%;
      overflow: hidden;

      .bordered-table {
        height: 100%;
        border: 1px solid #e7e7e7;
        border-radius: 6px;
        overflow: hidden;

        .MuiSvgIcon-root {
          font-size: 20px !important;
          color: #215c73;
        }

        .MuiTableContainer-root {
          box-shadow: none;
        }

        .no-wrap {
          white-space: break-spaces;
          overflow: inherit;
        }

        table {
          tr {
            &:last-child {
              td {
                border-bottom: 0px;
              }
            }
          }
        }

        .signature {
          aspect-ratio: 4/3;
          object-fit: contain;
          border: 1px solid #e7e7e7;
          padding: 2px;
          width: 32px;
          height: 32px;
          border-radius: 5px;
        }

        .download-icon {
          cursor: pointer;
          font-size: 24px !important;
        }

        @media screen and (max-width: 900px) {
          border-radius: 12px;
        }
      }

      .client-info-table-pagination {
        align-self: center;
        margin-top: 20px;
        padding: 10px;
        background-color: transparent;

        li {
          padding-left: 0px;
        }

        .Mui-selected {
          color: white;
          background-color: #215c73 !important;
        }
      }
    }
  }
}
