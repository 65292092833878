$mobile: 576px;

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-bottom: 50px;

  @media screen and (max-width: $mobile) {
    margin-bottom: 0px;
    padding: 30px 16px;
  }

  .image_container {
    height: 250px;
    width: 250px;
    border-radius: 10px;
    margin-top: 100px;
  }

  .noimagecompleting_text {
    font-family: Avenir;
    font-weight: 350;
    font-size: 16px;
    color: #000000;
    margin-top: 100px;
    text-align: center;
  }

  .completing_text {
    font-family: Avenir;
    font-weight: 350;
    font-size: 16px;
    color: #000000;
    text-align: center;
  }

  .noImagePassedText {
    font-family: Avenir;
    font-weight: 500;
    font-size: 24px;
    color: #000000;
    margin-top: 100px;
    text-align: center;

    .passedAge {
      font-family: Avenir;
      font-weight: 900;
      font-size: 24px;
      color: #259945;
      margin-left: 5px;
    }

    .failedAge {
      font-family: Avenir;
      font-weight: 900;
      font-size: 24px;
      color: #c11111;
      margin-left: 5px;
    }
  }

  .passedText {
    font-family: Avenir;
    font-weight: 500;
    font-size: 24px;
    color: #000000;
    text-align: center;

    .passedAge {
      font-family: Avenir;
      font-weight: 900;
      font-size: 24px;
      color: #259945;
      margin-left: 5px;
    }

    .failedAge {
      font-family: Avenir;
      font-weight: 900;
      font-size: 24px;
      color: #c11111;
      margin-left: 5px;
    }
  }

  .needText {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    text-align: center;
  }

  .statsText {
    font-family: Avenir;
    font-weight: 500;
    font-size: 16px;
    color: #47627b;
  }

  .noImagestatsText {
    font-family: Avenir;
    font-weight: 500;
    font-size: 16px;
    color: #47627b;
    margin-top: 40px;
  }

  .buttons_container {
    width: 15%;
    display: flex;
    justify-content: center;
  }

  .buttons_container_failed {
    display: flex;
    justify-content: center;
  }
}