.mainContainer {
  width: 100%;
  // margin: 15px 0px 25px 0px;
  .input {
    width: 100%;
    padding: 14px 20px;
    border-radius: 4px;
    border: 1px solid #dfe3e4;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: black;

    &:focus {
      outline: none;
      box-shadow: none;
      border: 1px solid #dfe3e4;
    }

    &::placeholder {
      color: #bdbdbd;
    }
  }

  .error {
    color: red;
    margin-top: 5px;
  }
}
