@import-normalize;
// @import url("https://fonts.googleapis.com/css?family=Libre+Franklin:300,400,500,800&display=swap");
@import './styles/fonts.scss';
@import './styles/basic.scss';
@import './styles/reboot';

body {
  margin: 0;
  // font-size: 1rem;
  font-family: 'Avenir', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.no-scroll {
    overflow: hidden !important;
  }

  .cke_notifications_area {
    display: none !important;
  }

  a {
    color: inherit;

    &:hover {
      color: inherit;
    }
  }
}

.smoked-gary-color {
  color: #a9a9a9;
}

.hoverable {
  &:hover {
    cursor: pointer;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.inline-block {
  display: inline-block;
}

.filter-submit-button {
  width: 123px !important;
  height: 36px !important;
  padding: 10px !important;
  // font-weight: 850 !important;
  border-radius: 5px !important;
  border: 0px 0px 2px 2px !important;
  gap: 20px !important;
  text-transform: none !important;
}

.filter-cancel-button {
  width: 123px !important;
  height: 36px !important;
  padding: 10px 24px 10px 24px !important;
  border-radius: 5px !important;
  // font-weight: 850 !important;
  gap: 20px !important;
  text-transform: none !important;
}

.filter-cancel-button-dark-theme {
  width: 123px !important;
  height: 36px !important;
  color: white !important;
  padding: 10px 24px 10px 24px !important;
  border-radius: 5px !important;
  // font-weight: 850 !important;
  border: 1.25px solid white !important;
  gap: 20px !important;
  text-transform: none !important;
}

.filter-menu-body {
  .MuiSelect-outlined.MuiSelect-outlined {
    border: 1px solid #dbe7f2 !important;
  }
}

.create-post-modal-styling {
  min-width: 400px;

  .upload-button-style {}

  .social-icons-div {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .social-style-main-div {
      flex: 1;
      padding: 5px;
      display: flex;
      justify-content: flex-start;
      width: 200px;
      align-items: center;
      gap: 5px;

      .facebook-checkbox-text {
        width: 200px;
        align-items: center;
        display: flex;
        padding-left: 5px;
      }
    }

    .checkbox-icon-style {
      flex: 1;
      padding: 5px;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.postAttachment {
  margin-top: 10px;
  position: relative;
  display: inline-block;
}

.delete-button {
  position: absolute;
  top: 7px;
  right: 7px;
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.thankYouPageHeadingStyling {
  font-family: Avenir;
  font-style: normal;
  text-align: center;
  font-size: min(5vw, 36px);
  margin: 5px;
}

.rename-rescource-field {
  .MuiInputBase-root {
    color: white !important;
  }
}

.add-upload-icon {
  background: #c4c4c4;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.Session-Page-Dropzone {
  p {
    margin: 0px 20px !important;
  }
}

.passowrd-popup-form {
  .MuiFormHelperText-root {
    color: red !important;
  }
}

.privacy-policy-link:hover {
  text-decoration: underline;
}

.master-calendar-right-side {
  width: calc(100% - 270px) !important;
}

.self-paced-modal-textfield {
  .MuiFormLabel-root.Mui-focused {
    font-family: 'Avenir';
    font-size: 18px;
    font-weight: 800;
    line-height: 16px;
    color: #000000DE;
  }
}