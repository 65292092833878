.conditionContainer {
  display: flex;
  // align-items: center;
  // margin-top: 10px;
  justify-content: space-between;
  width: 100%;
  // margin-bottom: 5px;
  align-items: center;
  background-color: #f1f8ff;
  padding: 20px 10px;
  border-radius: 8px;

  .fieldContainer {
    width: 30%;

    .tagNameInput {
      input {
        padding: 15px 10px;
        background-color: white;
        border-radius: 5px;
        border: 1px solid #dbe7f2;
        font-family: Avenir;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        color: black;
      }
    }

    .tagExistsSelect {
      div {
        padding: 15px;
      }
    }

    .contributionNameSelect {
      div {
        // padding: 15px;
        width: 100%;
      }
    }
  }

  .deleteBtnContainer {
    width: 5%;
    display: flex;
    justify-content: end;
    cursor: pointer;

    .deletBtn {
      cursor: pointer;
    }
  }
}

.toggleContainer {
  width: 100%;
  margin: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}