.contribution-recordings-header-container {
    background-color: white;
    box-shadow: 0 2px 4px 0 rgb(157 157 157 / 30%);
    padding: 20px 55px 25px;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-width: 320px;
    position: sticky;
    top: 0;
    z-index: 100;

    .heading-container {
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(0, 0, 0, 0.87);
        font-size: 1.675rem;
        font-weight: 500;
        margin: 0px;

        .heading {
            color: rgba(0, 0, 0, 0.87);
            font-size: 30px;
            line-height: 32px;
            font-weight: 500;
            display: inline-block;
            margin: 0px;
            max-width: 400px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.free-recording-container {
    padding: 30px 60px;

    .recording-list {
        padding-top: 30px;

        li {
            cursor: pointer;
            width: fit-content;
            margin-bottom: 5px;
        }
    }
}

.passcode-error-message {
    color: red;
}