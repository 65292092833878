@import '~utils/styles/styles.scss';

.chats-list-header-sort-order-toggle {
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  & &__button {
    padding: 6px;
    margin: -6px;
  }

  &__title {
    margin: 0 0 0 6px;

    font-size: 15.8px;
    font-weight: 300;

    @media screen and (max-width: $mobile-size) {
      font-size: 12px;
    }
  }
}
