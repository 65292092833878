@import 'styles/varibles.scss';
@import '~utils/styles/styles.scss';

.banner-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 14px 6px 21px;
  /*  margin-bottom: 24px; */
  margin: 8px 16px;
  border-radius: 4px;
  box-shadow: $box-shadow;
  background-color: $dark-ocean-blue-color;

  &__mobile {
    padding: 14px;
  }

  &__text {
    font-size: 18.3px;
    line-height: 1.53;
    letter-spacing: 0.5px;
    color: #ffffff;
    margin-bottom: 0;
  }

  &__button {
    text-transform: uppercase;
  }
}

@media screen and (max-width: $mobile-size) {
  .banner-container {
    flex-direction: column;

    &__text {
      margin-bottom: 8px;
    }
  }
}