.option_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  .checkbox_container {
    width: 5%;

    .checkbox {
      padding: 0px;
    }
  }

  .textfield_container {
    width: 90%;

    .input_root {
      &:hover {
        .input_notchedOutline {
          border-color: #e7e7e7 !important;
        }
      }
    }

    .input_notchedOutline {
      border-color: #e7e7e7 !important;
    }

    .input_focused {
      .input_notchedOutline {
        border-color: #e7e7e7 !important;
      }
    }
  }
}

.cross_btn_container {
  width: 5%;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}
