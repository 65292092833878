.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 20px;
  border: 1px solid #dfe3e4;
  border-radius: 4px;

  .row_item {
    display: flex;
    align-items: center;
  }
}
