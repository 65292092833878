.container {
  width: 200px;
  position: absolute;
  top: 57px;
  left: 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 15px 10px;
  box-shadow: 0px 1px 6.4px 0px rgba(0, 0, 0, 0.25);
  z-index: 100;

  .action {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    color: #213649;
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 10px;
    cursor: pointer;

    .icon {
      height: 15px;
      width: 20px;
      background-color: #e7e7e7;
      padding: 0px 4px;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .action_ name {}

    .action_name_selected {
      font-weight: 700;
    }
  }

  .last_item {
    border-bottom: 0px solid #e7e7e7;
    padding-bottom: 0px;
  }
}

.container_responsive {
  left: calc(50% - 80px) !important;
}

.content_container {
  width: 220px;
  position: absolute;
  top: 57px;
  left: 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 15px 10px;
  box-shadow: 0px 1px 6.4px 0px rgba(0, 0, 0, 0.25);
  z-index: 10;

  .action {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    color: #213649;
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 10px;
    cursor: pointer;

    .icon {
      background-color: #e7e7e7;
      padding: 0px 4px;
      border-radius: 2px;
    }

    .action_ name {}

    .action_name_selected {
      font-weight: 700;
    }
  }

  .last_item {
    border-bottom: 0px solid #e7e7e7;
    padding-bottom: 0px;
  }
}