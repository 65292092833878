.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  cursor: pointer;

  .text {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: #000000;
    text-align: center;

    .passedAge {
      font-family: Avenir;
      font-weight: 900;
      font-size: 24px;
      color: #259945;
      margin-left: 5px;
    }

    .failedAge {
      font-size: 24px !important;
      font-weight: 500 !important;
      line-height: 16px !important;
      color: #c11111;
      margin-left: 5px;
    }
  }

  .textContent {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px !important;
    color: #000000;
    text-align: left;

    .passedAge {
      font-family: Avenir;
      font-weight: 900;
      font-size: 24px;
      color: #259945;
      margin-left: 5px;
    }

    .failedAge {
      font-size: 24px !important;
      font-weight: 500 !important;
      line-height: 16px !important;
      color: #c11111;
      margin-left: 5px;
    }
  }

  .edit_btn_container {
    cursor: 'pointer';

    .editBtn {
      padding: 0px !important;
    }
  }
}

.textfield_root_resposive {
  width: 100% !important;
}

.input_root {
  width: 500px;

  &:hover {
    .input_notchedOutline {
      border-color: #e7e7e7 !important;
    }
  }
}

.input_root_responsive {
  width: 100% !important;
}

.input_notchedOutline {
  border-color: #e7e7e7 !important;
}

.input_focused {
  .input_notchedOutline {
    border-color: #e7e7e7 !important;
  }
}