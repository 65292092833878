.container {
  padding: 15px 0px;

  .email_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .field_input {
  }

  .phone_number {
    input,
    button {
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
      border-radius: 0px !important;

      &:hover {
        border-bottom: 2px solid black;
        background-color: white !important;
      }
    }

    button {
      padding: 12px 0px !important;
      div {
        gap: 10px;
      }
    }
  }
  .dropdownArrowClass {
    border: none !important;
    background-image: url('./Assets/PNG/DownIcon.png');
    height: 6px;
    width: 10px;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
