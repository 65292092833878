@import 'styles/varibles.scss';
@import '~utils/styles/styles.scss';


.session-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    width: 32%;
    height: 340px;
    background-color: #FFFFFF;
    box-shadow: 0px 3.77487px 3.77487px rgba(0, 0, 0, 0.05);
    border-radius: 3.77487px;
    margin-bottom: 15px;
    margin-right: 10px;

    &_title {
        width: 100%;
        font-family: 'Avenir';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 30px;
        display: flex;
        align-items: center;
        color: #213649;
        margin-top: 15px;
    }

    &_profile {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin-bottom: 17px;
        margin-top: 15px;
    }

    &_profileOneToOne {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        // margin-bottom: 17px;
        margin-top: 15px;
    }

    &_progressbarcontainer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &_bar {
        width: 85%;
    }

    &_label {
        width: 10%;
    }

    &_bottompanel {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin-top: 10px;

        &__datetime {
            width: 80%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        &__datetime-cont {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        &__joinbtn {
            width: 20%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        &__modulebtn {
            width: 45%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
}

.session-card-mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    width: 32%;
    height: 390px;
    background-color: #FFFFFF;
    box-shadow: 0px 3.77487px 3.77487px rgba(0, 0, 0, 0.05);
    border-radius: 3.77487px;
    margin-bottom: 15px;
    margin-right: 10px;

    &_title {
        width: 100%;
        font-family: 'Avenir';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 30px;
        display: flex;
        align-items: center;
        color: #213649;
        margin-top: 15px;
    }

    &_profile {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin-bottom: 17px;
        margin-top: 15px;
    }

    &_profileOneToOne {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        // margin-bottom: 17px;
        margin-top: 15px;
    }

    &_progressbarcontainer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &_bar {
        width: 85%;
    }

    &_label {
        width: 10%;
    }

    &_bottompanel {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin-top: 10px;

        &__datetime {
            width: 80%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        &__datetime-cont {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        &__joinbtn {
            width: 20%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        &__modulebtn {
            width: 45%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
}

.disabled-card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    width: 32%;
    height: 340px;
    background-color: #FFFFFF;
    box-shadow: 0px 3.77487px 3.77487px rgba(0, 0, 0, 0.05);
    border-radius: 3.77487px;
    margin-bottom: 15px;
    margin-right: 10px;
    z-index: 0;

    &_header {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        gap: 8px;
        background-color: #215C73;
        padding: 9px 10px;
        width: 100%;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        z-index: 1;
    }

    &_content-container {
        position: absolute;
        top: 70px;
        left: 0px;
        background-color: #FFFFFF;
        opacity: 0.5;
        width: 100%;
        height: calc(100% - 70px);
        z-index: 1;
        pointer-events: unset;
        cursor: not-allowed;
    }
}

.disabled-card-mobile {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    width: 32%;
    height: 390px;
    background-color: #FFFFFF;
    box-shadow: 0px 3.77487px 3.77487px rgba(0, 0, 0, 0.05);
    border-radius: 3.77487px;
    margin-bottom: 15px;
    margin-right: 10px;
    z-index: 0;

    &_header {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        gap: 8px;
        background-color: #215C73;
        padding: 9px 10px;
        width: 100%;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        z-index: 1;
    }

    &_content-container {
        position: absolute;
        top: 70px;
        left: 0px;
        background-color: #FFFFFF;
        opacity: 0.5;
        width: 100%;
        height: calc(100% - 70px);
        z-index: 1;
        pointer-events: unset;
        cursor: not-allowed;
    }
}

.Avatar {
    width: 30px;
    height: 30px;
}


@media screen and (max-width: $mobile-size) {
    .session-card {
        width: 100%;
        margin-right: 0px;
    }

    .session-card-mobile {
        width: 100%;
        margin-right: 0px;
    }

    .disabled-card-mobile {
        width: 100%;
        margin-right: 0px;
    }
}