.container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;

  .dragicon_container {
    width: 4%;

    .drag_icon {
      // color: #b2cae0;
      color: #cfcfcf;
    }
  }

  .input_container {
    width: 70%;
    display: flex;
    align-items: center;
    gap: 12px;

    .cross_btn {
      cursor: pointer;
    }
  }

  .correct_answer {
    width: 26%;

    .MuiFormControlLabel_root {
      margin: 0px;
    }

    .checkbox_root {
      color: #215c73;
    }
  }
}
