.container {
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: center;

  .icon {
    cursor: pointer;
  }

  .label {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: #213649;
  }
}
