.toggle_container {
  height: 32px;
  width: 145px;
  background-color: #dfe3e4;
  display: flex;
  align-items: center;
  // padding: 3px 4px;
  border-radius: 4px;

  .toggle_value {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    text-align: center;
    color: #213649;
    cursor: pointer;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;

    &.selected {
      background-color: #47627b;
      color: #fefeff;
    }
  }

  .selected {
    background-color: #47627b;
    color: #fefeff;
  }
}