.body {
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  // height: 100vh;
  // margin-bottom: 20px;
  // overflow-y: scroll;
  padding-bottom: 100px;

  .no_question {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .no_question {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .question_number {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 900;
    line-height: 23.94px;
    color: #282b2b;
  }

  .question_type_container {
    display: flex;
    justify-content: space-between;

    .toggle_container {
      display: flex;
      align-items: center;
      gap: 10px;

      .heading {
        font-family: Avenir;
        font-weight: 350;
        font-size: 16px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.87);
      }

      .toggle_button {
        margin-bottom: 0px;
      }
    }
  }

  .question_heading {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 900;
    line-height: 24px;
    color: #282b2b;
    display: flex;
    align-items: center;
  }

  .question {
    font-family: Avenir;
    font-size: 20px !important;
    font-weight: 800 !important;
    line-height: 20px !important;
    color: #213649 !important;
  }

  .question_container {
    justify-content: flex-start !important;
  }

  .editable_input_root {
    width: 100% !important;
  }

  .ckeditor_container {}

  .options_container {}

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    .add_choice_btn_container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .add_choice_btn {
        width: 115px;
        height: 40px;
        background-color: #fff;
        border: 1px solid #215c73;
        font-family: Avenir;
        font-weight: 800;
        font-size: 14px;
        line-height: 22px;
        min-width: 0px;
        padding: 0px;
        letter-spacing: 0px;
        color: black;
      }

      .add_option_btn {
        width: 130px;
        height: 40px;
        background-color: #fff;
        border: 1px solid #215c73;
        font-family: Avenir;
        font-weight: 800;
        font-size: 14px;
        line-height: 22px;
        min-width: 0px;
        padding: 0px;
        letter-spacing: 0px;
        color: black;
      }

      .options_count {
        font-family: Avenir;
        font-weight: 350;
        font-size: 16px;
        line-height: 22px;
        color: #215c73;
        cursor: pointer;
      }
    }



    .rating_dropdown_container {
      width: 300px;

      @media screen and (max-width: 576px) {
        width: 150px;
      }

      .select {
        width: 100%;
      }

      .select_icon {
        right: 8px !important;
      }

      .input_root {
        height: 40px;
        border: 1px solid #e7e7e7;
      }

      .input_notchedOutline {
        border: none !important;
      }
    }

    .max_character_input_root {
      height: 40px;
      width: 240px;
      border: 1px solid #e7e7e7;

      @media screen and (max-width: 576px) {
        width: 160px !important;
      }
    }

    .max_character_input_notchedOutline {
      border: none !important;
    }

    .add_choice_btn_container_responsive {
      width: 100%;
      justify-content: space-between;
    }

    .save_and_add_btn_container {
      position: relative;
      display: flex;
      justify-content: end;

      .save_and_next {
        min-width: 0px;
        letter-spacing: 0px !important;
      }

      .save_and_next_responsive {
        width: 100%;
        display: flex;
        justify-content: center;
      }

      .question_type_panel {
        top: 50px;
        left: 0px;
      }

      .question_type_panel_responsive {
        top: 50px;
        left: 50px;
      }
    }

    .save_and_add_btn_container_responsive {
      width: 100%;
    }

    .toggle_container {
      display: flex;
      align-items: center;
      gap: 10px;

      .heading {
        font-family: Avenir;
        font-weight: 350;
        font-size: 16px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.87);
      }

      .toggle_button {
        margin-bottom: 0px;
      }
    }
  }

  .toggle_container {
    display: flex;
    align-items: center;
    gap: 10px;

    .heading {
      font-family: Avenir;
      font-weight: 350;
      font-size: 16px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.87);
    }

    .toggle_button {
      margin-bottom: 0px;
    }
  }

  .actions_responsive {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
}