@import '~utils/styles/styles.scss';

.chat-message-separator {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  align-items: center;

  grid-column-gap: 15px;

  &__separator {
    height: 1px;
    width: 100%;

    background-color: $light-gray-color-2;
  }

  &__title {
    margin: 0;

    color: $gray-color;

    font-family: 'Avenir';
    font-size: 15.8px;
    font-weight: 300;
  }
}
