.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 16px;
  border-radius: 1px;
  background-color: #f5f5f5;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .main {
      display: flex;
      align-items: center;
      gap: 10px;

      .dragicon_container {
        .drag_icon {
          color: #b2cae0;
        }
      }

      .option_number {
        font-family: Avenir;
        font-size: 16px;
        font-weight: 900;
        line-height: 23.94px;
        color: #282b2b;
      }
    }

    .MuiFormControlLabel_root {
      margin: 0px;
      margin-right: 15px;
    }

    .checkbox_root {
      padding: 0px;
      margin-right: 10px;
    }

    .checkbox_root_checked {
      padding: 0px;
      margin-right: 8px;
    }

    .cross_icon_container {
      cursor: pointer;
    }
  }

  .body {
  }
}
