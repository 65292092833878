$mobile: 576px;

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 50px 20px;


  .header_text {
    font-family: Avenir;
    color: #213649;
    font-size: 20px;
    font-weight: 800;
    margin-top: 100px;

    @media screen and (max-width: $mobile) {
      margin-top: 0px;
    }
  }

  .form {
    width: 100%;
    max-width: 350px;
    min-width: 350px;


    .submit_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;

      .submit_btn {
        @media screen and (max-width: $mobile) {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: $mobile) {
      width: 100%;
    }
  }
}