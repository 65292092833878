.dark-notification-accordion {
    svg {
        color: white;
    }
}

.delete-application-form-modal {
    .cohere-modal {
        .modal-footer-container {
            .modal-footer-buttons {
                display: flex;
                .cancel-btn {
                    border : 1px solid black;
                    padding: 12px 16px !important;
                }
    
                .submit-btn {                
                    padding: 12px 16px !important;
                }
            }
        }
    }
}

#react-joyride-step-0  {   
    .__floater__body {
        
        .react-joyride__tooltip {
            padding-left: 0px !important;
            padding-bottom: 0px !important;
            padding-right: 0px !important;
            width: 533px !important;
            
            @media screen and (max-width: 576px) {
                width: 344px !important;
                max-width: unset !important;
                margin-left: -69px !important;
            }
        }
    }
}