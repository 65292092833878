.paginationWrapper {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 20px;

  .pagination {
    padding: 0px;
    background-color: #fafafa;

    li {
      padding-left: 0px;
    }

    .selected {
      background-color: #215c73;
      color: white;
      min-width: 25px;
      height: 25px;
      border-radius: 4px;
      &:hover {
        background-color: #215c73;
      }
    }
  }
}
