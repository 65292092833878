@import '~utils/styles/styles.scss';
.container {
  border-bottom: 1px solid #dfe3e4;
  padding-bottom: 15px;
  .form_control_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    // &:hover {
    //   background-color: transparent !important;
    // }
    .label {
      font-size: 14px;
      width: 100%;
      font-family: Avenir;
      max-width: 124px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @media screen and (max-width: $mobile-size) {
        max-width: 200px;
      }
    }

    .setting_icon {
      margin-bottom: '4px';
      cursor: pointer;
    }

    .close {
      margin-bottom: '4px';
      cursor: pointer;
      display: none;
      transition: display 0.5s ease;
    }
  }

  // .form_control_container:hover .close {
  //   display: block;
  // }

  .add_calendar_label {
    font-weight: 600;
    font-size: 14px;
  }
}
