$font-family: 'Avenir';

.main_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px;
  border: 1px solid #dfe3e4;
  border-radius: 4px;
  background-color: white;

  .main_heading {
    font-family: $font-family;
    font-size: 22px;
    font-weight: 800;
    line-height: 16px;
    color: black;
  }

  .section_action {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .description {
      font-family: $font-family;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.87);

      .email {
        font-family: $font-family;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        color: rgba(0, 0, 0, 0.87);
        margin-top: 10px;
      }
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;

      .need_help_text {
        font-family: $font-family;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: rgba(0, 0, 0, 0.87);
      }

      .support_btn {
        font-family: $font-family;
        font-size: 14px;
        font-weight: 800;
        line-height: 20px;
        color: #c9b382;
        border-color: #c9b382;
      }

      .call_btn {
        font-family: $font-family;
        font-size: 14px;
        font-weight: 800;
        line-height: 20px;
      }
    }
  }

  .instruction_heading {
    font-family: $font-family;
    font-size: 18px;
    font-weight: 800;
    line-height: 16px;
    color: #000;
  }

  .table_container_popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    // border-bottom: 1px solid #E7E7E7;

    .copy_btn {
      font-family: $font-family;
      font-size: 14px;
      font-weight: 800;
      line-height: 20px;
      margin-bottom: 20px;
      background-color: #c9b382;
      color: white;
      padding: 1rem 1.25rem;
      border-radius: 4px;
      cursor: pointer;
    }

    .troubleShootContainer {
      background-color: #F6E8AB33;
      width: 100%;
      padding: 20px 20px;
      flex-direction: row;
      margin-bottom: 30px;
      cursor: pointer;

      .title {
        font-family: $font-family;
        font-weight: 800;
        font-size: 14px;
        line-height: 18px;
        color: #213649;
        align-items: center;
        display: flex;
      }

      .description {
        font-family: $font-family;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #000000;
        margin-top: 10px;

        ul {
          list-style-type: disc;
        }
      }
    }
  }

  .table_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border-bottom: 1px solid #E7E7E7;

    .copy_btn {
      font-family: $font-family;
      font-size: 14px;
      font-weight: 800;
      line-height: 20px;
      margin-bottom: 20px;
      background-color: #c9b382;
      color: white;
      padding: 1rem 1.25rem;
      border-radius: 4px;
      cursor: pointer;
    }

    .troubleShootContainer {
      background-color: #F6E8AB33;
      width: 100%;
      padding: 20px 20px;
      flex-direction: row;
      margin-bottom: 30px;
      cursor: pointer;

      .title {
        font-family: $font-family;
        font-weight: 800;
        font-size: 14px;
        line-height: 18px;
        color: #213649;
        align-items: center;
        display: flex;
      }

      .description {
        font-family: $font-family;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #000000;
        margin-top: 10px;

        ul {
          list-style-type: disc;
        }
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    .checkbox_root {}

    .checked {
      color: #116582 !important;
    }

    .label {
      font-family: $font-family;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.87);
    }

    .verify_btn {
      font-family: $font-family;
      font-size: 14px;
      font-weight: 800;
      line-height: 20px;
    }
  }
}