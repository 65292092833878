.video-chat-participants-panel {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &__title {
    align-self: center;

    margin: 20px 0 10px 0;

    font-weight: bold;
  }

  &-participants {
    overflow: hidden auto;

    padding: 10px;

    border-top: 1px solid lightgray;

    &__item {
      &:not(:first-child) {
        padding-top: 10px;
      }

      &:not(:last-child) {
        padding-bottom: 10px;
        border-bottom: 1px solid lightgray;
      }
    }
  }
}
