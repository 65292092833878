.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 15px;
  margin-top: 15px;

  .field_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .addcontacts_btn {
      width: 220px;
      font-family: Avenir;
      font-weight: 900;
      font-size: 14px;
      line-height: 16px;
    }

    .tags_list {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .tag_container {
        position: relative;

        .cross_icon {
          cursor: pointer;
          position: absolute;
          top: -10px;
          right: -5px;
        }
        .tag {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: -0.24px;
          color: #ffffff;
          padding: 2px 8px;
          background-color: #c9b382;
          border-radius: 5px;
        }
      }
    }

    .field_label {
      width: 100%;
      font-family: Avenir;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      color: #213649;
    }

    .field {
      width: 100%;

      .textfield {
        width: 100%;
      }

      .textfield_underline {
        border-color: #979797;
      }

      .selectfield_underline {
        &::before {
          border-color: #979797;
        }
        &:hover::before {
          border-color: black;
        }
      }

      .selectfield_icon {
        path {
          fill: #838383 !important;
        }
      }

      .contacts_select {
        width: 100%;
      }

      .contrib_select {
        width: 100%;
      }
    }
  }
}
