.width-limiter {
  textarea {
    max-width: clamp(145px, 200px, 300px);
  }

  textarea::placeholder {
    font-size: 14px;
  }
}

.mentions {
  .mentions__suggestions {
    max-height: 190px;
    overflow-y: auto;
  }

  textarea {
    line-height: inherit;
    width: 100%;
    max-width: 100%;
    resize: none !important;
    padding: 0 !important;
    font-size: 15px !important;
    margin: 0px !important;

    // transform: translate3d(4px, 10px, 0);
    @media screen and (max-width: 576px) {
      line-height: normal;
      height: 50px !important;
    }
  }
}

.mentions__input {
  font-family: Avenir;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.24;
  letter-spacing: 0.25px;
  font-size: 1rem;
  border: none;
}

.mentions__highlighter {
  border: none !important;
  line-height: inherit;
}

.mentions--singleLine .mentions__control {
  display: inline-block;
  width: 130px;
}

.mentions--singleLine .mentions__highlighter {}

.mentions--singleLine .mentions__input {
  outline: 0;
}

.mentions--multiLine .mentions__control {}
.mentions--multiLine .mentions__highlighter {
  min-height: auto;
}

.mentions--multiLine .mentions__input {
  outline: 0;
  height: 100% !important;
}

.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item {}

.mentions__suggestions__item--focused {
  background-color: rgba(0, 0, 0, 0.04);
}

.mentions__mention {
  position: relative;
  z-index: 1;
  color: #116582;
  text-shadow: 1px 1px 1px #fafafa, 1px -1px 1px #fafafa, -1px 1px 1px #fafafa, -1px -1px 1px #fafafa;
  pointer-events: none;

  &.mentions__mention_dark {
    text-shadow: 1px 1px 1px #252728, 1px -1px 1px #252728, -1px 1px 1px #252728, -1px -1px 1px #252728;
  }
}

.is-create {
  textarea {
    padding: 10px !important;
    height: 130px !important;
    overflow: auto !important;
  }
  .mentions__highlighter {
    height: 130px !important;
  }
}