$header-height: 95px;

// Colors
$white: #fff;
$black: #000;
$black-87: rgba(0, 0, 0, 0.87);
$whisper: #e7e7e7;
$snow: #fafafa;
$avatar: #bdbdbd;

$DarkThemedColor: white;
$DarkThemedBackgroundColor: #252728;
$DarkThemedCardBackgroundColor: #2d2f31;
$DarkThemedCardOutlineColor: #696969;
$LightThemedColor: #282b2b;
$LightThemedBackgroundColor: #fafafa;
$LightThemedCardBackgroundColor: white;
$LightThemedCardOutlineColor: #e7e7e7;

// Shadows
$box-shadow: 0 2px 4px 0 rgba(157, 157, 157, 0.3);

$font-family-Avenir: Avenir;
