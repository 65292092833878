.dark-post-container {
  a {
    p {
      color: white;
    }
    span {
      color: white;
      :hover {
        color: white;
      }
    }
  }
}
