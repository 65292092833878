@import 'utils/styles/styles.scss';

.dialog-container {
  min-width: 400px;

  @media screen and (max-width: $mobile-size) {
    & {
      min-width: 100%;
    }
  }
}
