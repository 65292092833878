$mobile: 576px;

.container {
    flex: 1;
    padding: 50px 20px;
    max-width: 750px;
    min-width: 750px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    @media screen and (max-width: $mobile) {
        min-width: 250px;
        max-width: 250px;
    }

    .statsText {
        font-family: Avenir;
        font-weight: 500;
        font-size: 16px;
        color: #47627B;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: $mobile) {
            text-align: center;
        }


    }

    .noImagestatsText {
        font-family: Avenir;
        font-weight: 500;
        font-size: 24px;
        color: #000000;
        margin-top: 100px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: $mobile) {
            text-align: center;
        }
    }

}