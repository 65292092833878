.MuiOutlinedInput-input {
    padding: 12px 10px !important;
}

.custom-input-style {
    font-family: 'Avenir';

    .MuiInputBase-input {
        font-family: Avenir;
        font-size: 16px;
        font-weight: 350;
        line-height: 24px;
        letter-spacing: 0.15000000596046448px;
        text-align: left;
        padding: 9px 0px 8px 8px;
    }
}