.segmentGroupContainer {
  width: 100%;
  // background-color: #f1f8ff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  // padding: 8px;
  // margin-bottom: 10px;

  .segmentConditionsContainer {
    display: flex;
    flex-direction: column;
  }

  .addAnotherConditionBtn {
    width: 180px;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 800;
    line-height: 16px;
    color: #215c73;
    cursor: pointer;
    margin-top: 15px;
  }
}
