.container {
  padding: 0px;
  display: flex;
  align-items: center;
  width: 250px;
  height: 40px;
  box-shadow: none;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
}
