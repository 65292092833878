.video-chat-gallery-view-container {
  display: grid;
  align-items: stretch;
  justify-content: stretch;

  padding: 30px 10px;

  background-color: black;
}

.video-chat-gallery-view {
  display: grid;
  align-items: center;

  &-participants {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(168px, 1fr));
  }
}
