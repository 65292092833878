@import '~utils/styles/styles.scss';

.rich-text-notes-editor {
  &.loading-editor {
    display: none;
  }

  .cohere-modal {
    max-width: 760px;

    > div:first-child {
      border-bottom: 1px solid #e7e7e7;
      margin-bottom: 10px;
    }

    .copy-and-share-container {
      display: flex;
      float: right;
      margin: 8px 0 8px 0;

      .share-via-email {
        cursor: pointer;
        background-color: #efefef;
        padding: 8px 10px;
        margin-right: 20px;
        border-radius: 2px;
        color: black;
        font-size: 14px;

        svg {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }

      .copy-to-clipboard {
        cursor: pointer;
        background-color: #efefef;
        padding: 8px 10px;
        border-radius: 2px;
        color: black;
        font-size: 14px;

        img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }

      @media screen and (max-width: $mobile-size) {
        font-size: 14px;
      }

      @media screen and (max-width: $mobile-size-sm) {
        flex-direction: column;
        gap: 10px;
        width: 100%;

        .share-via-email {
          margin-right: 0;
        }
      }
    }

    .modal-footer-container {
      margin: 5px 0;

      > div:last-of-type {
        display: flex;
        width: 100%;

        button {
          width: 47%;
          height: 50px;

          &:first-of-type {
            &.cancel-btn {
              padding: 11px 30px !important;
            }

            margin-right: 6% !important;
          }
        }
      }
    }

    .cke_bottom {
      .cke_path {
        display: none;
      }
    }
  }
}

.notes-email-popup {
  .cohere-modal {
    width: 540px;

    .body {
      .emailInput {
        margin-left: 0px;
      }
    }
  }
}

.cke_dialog_body {
  .cke_dialog_tabs {
    .cke_dialog_tab {
      &[title='Upload'] {
        display: none;
      }
    }
  }
}
