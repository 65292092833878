.container {
    border-bottom: 2px solid #E7E7E7;

    .plan_purchase {
        border: 1px solid #215C73;
        padding: 4px 8px 4px 8px;
        border-radius: 4px;
        width: fit-content;
        font-family: Avenir;
        font-size: 14px;
        font-weight: 800;
        line-height: 24px;
        text-align: left;
        color: #215C73;
    }

    .no_purchase {
        border: 1px solid #C9B382;
        padding: 4px 8px 4px 8px;
        border-radius: 4px;
        width: fit-content;
        font-family: Avenir;
        font-size: 14px;
        font-weight: 800;
        line-height: 24px;
        text-align: left;
        color: #C9B382;
    }
}