@import '../../../styles/varibles.scss';

.container {
  .heading {
    font-family: Avenir;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.87);
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px;
    border-bottom: 1px solid #dfe3e4;
  }

  .body {
    padding: 18px;
  }

  .emailInput {
    width: 100% !important;

    & .MuiInputBase-input .MuiOutlinedInput-input {
      color: red !important;
      border-color: red !important;
    }
  }
}

.checkbox_root {
  padding: 0px 5px 0px 0px !important;
}

.MuiFormControlLabel_root {
  margin-left: 0px !important;
  margin-bottom: 0px !important;
}

.email_main_container {
  width: 100%;

  .email_main {
    width: 100%;
  }

  .email_root {
    border: 1px solid rgba(0, 0, 0, 0.23);
  }

  .email_notchedOutline {
    border: none;
  }

  .email_adornedEnd {
    padding-right: 1px;
  }

  .endAdornment {
    background-color: #f5f5f5;
    padding: 15px 0px 10px 25px;

    .select_root {
      padding-right: 100px;
      background-color: transparent;
      font-family: $font-family-Avenir;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      text-align: center;

      @media screen and (max-width: 500px) {
        padding-right: 25px;
      }
    }

    @media screen and (max-width: 500px) {
      padding: 10px 10px;
    }
  }
}