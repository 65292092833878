.container {
  width: 100%;

  .header {
    display: flex;
    border-bottom: 1px solid #e7e7e7;

    .tab {
      border-bottom: 3px solid transparent;

      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        background-color: transparent;
        font-family: Avenir;
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        min-width: auto;
      }

      .active_button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        background-color: transparent;
        font-family: Avenir;
        font-size: 16px;
        font-weight: 900;
        color: #116582;
        min-width: auto;
      }
    }

    .tab_responsive {
      width: 50% !important;
    }

    .active_tab {
      border-bottom: 3px solid #215c73;
    }
  }

  .body {
    padding: 0px 50px 50px 50px
  }
}