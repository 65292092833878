.container {
  .chat_btn {
    display: none;
  }
  .show {
    display: flex;
  }
  .getstarted_btn {
    .image {
      width: 35px;
      height: 35px;
      border: 1px solid white;
      border-radius: 20px;
      margin-right: 5px;
    }

    // &:hover + .chat_btn {
    //   display: flex !important;
    // }
  }
}
