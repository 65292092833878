.container {
  position: relative;
  width: 100%;
  height: 300px;
}

.customlogo {
  position: relative;
  width: 75px;
  height: 75px;
  border-radius: 10px;
}

.overlaycustomlogo {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 75px;
  height: 75px;
  opacity: 0;
  transition: 0.3s ease;
  /* background-color: red; */
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.3s ease;
  /* background-color: red; */
}

.container:hover .overlay {
  opacity: 1;
}

.container:hover .image {
  opacity: 0.5;
}

.customlogo:hover .overlaycustomlogo {
  opacity: 1;
}

.customlogo:hover .image {
  opacity: 0.5;
}

.button {
  /* color: white;
    font-size: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center; */
  background-color: rgb(201, 179, 130);
  border: none;
  border-radius: 5px;
  padding: 10px 10px;
  color: white;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.deletebutton {
  /* background-color: rgb(201, 179, 130); */
  border: none;
  border-radius: 5px;
  /* padding: 10px 10px; */
  color: black;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.fa-user:hover {
  color: #eee;
}

.signContainer {
  border: 1px solid #b2cae0;
  margin-top: 10px;
  border-radius: 5px;
  canvas {
    height: 200px !important;
    width: 100% !important;
    touch-action: none;
  }
}
.modal-form-style {
  width: 760px;
}
@media (max-width: 640px) {
  .modal-form-style {
    width: 460px;
  }
}
@media (max-width: 510px) {
  .modal-form-style {
    width: 400px;
  }
}
@media (max-width: 450px) {
  .modal-form-style {
    width: 350px;
  }
}
@media (max-width: 400px) {
  .modal-form-style {
    width: 320px;
  }
}

.client-side-modal {
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
.waiver-radio-group {
  padding-left: 10px;
}
.disableIcon {
  fill: rgb(169, 169, 169); /* Gray color in RGB values */
}
