.container {
  width: 100%;
  display: flex;
  justify-content: start;
  gap: 20px;

  .btn {}

  .btnMobile {
    font-size: 10px;
    padding: 10px;
    margin-bottom: 10px;
  }
}