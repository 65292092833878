@import 'styles/varibles.scss';

.chat-message-input {
  display: grid;
  grid-template-columns: 1fr repeat(2, max-content);
  align-items: center;
  grid-column-gap: 20px;

  padding: 16px 24px;

  & &__upload-attachment,
  & &__send {
    margin: -12px;
  }

  & &__send {
    outline: none;
  }
}

.chat-message-input-dark-themed {
  display: grid;
  grid-template-columns: 1fr repeat(2, max-content);
  align-items: center;
  grid-column-gap: 20px;

  padding: 16px 24px;

  & &__upload-attachment,
  & &__send {
    margin: -12px;
  }

  & &__send {
    outline: none;
  }

  textarea {
    color: white;
  }

  .react-input-emoji--container {
    color: $DarkThemedColor;
    background-color: $DarkThemedBackgroundColor;
  }

  .react-input-emoji--placeholder {
    color: $DarkThemedColor;
  }

  .MuiIconButton-root.Mui-disabled {
    color: #858585;
  }

  .react-input-emoji--button {
    svg {
      fill: white;
    }
  }
}

.chat-message-input__container {
  .MuiOutlinedInput-input {
    padding: 10.5px 15px !important;
    font-size: 16px !important;
  }

  .MuiOutlinedInput-root {
    border-radius: 21px !important;
  }

  &.is-dark {
    .MuiOutlinedInput-input {
      color: white;
    }
  }
}