.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .image_container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image_toggle_container {
    display: flex;
    justify-content: center;
    align-items: center;

    .image_toggle {
      width: 300px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;

      .heading {
        font-family: Avenir;
        font-size: 16px;
        font-weight: 350;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.87);
      }

      .toggle_button {
        margin: 0px;
      }
    }
  }

  .failed_msg {
    font-family: Avenir;
    font-size: 22px !important;
    font-weight: 600 !important;
    line-height: 25px !important;
    text-align: center;
    color: #47627b !important;
  }

  .failed_msg_responsive {
    font-family: Avenir;
    font-size: 20px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    text-align: center;
  }

  .score_percentage {
    font-family: Avenir;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 25px !important;
    color: #47627b !important;
  }

  .score_percentage_responsive {
    font-family: Avenir;
    font-size: 16px !important;
    font-weight: 350 !important;
    line-height: 25px !important;
    color: #47627b !important;
    text-align: center;
  }

  .correct_answers {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: #47627b;
    text-align: center;
  }

  .correct_answers_responsive {
    text-align: center;
  }

  .photo_size {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #9e9e9e;
  }

  .action_btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;

    .retake_btn {
      font-family: Avenir;
      font-weight: 800;
      font-size: 16px;
      text-align: center;
    }

    .button_btn {
      font-family: Avenir;
      font-weight: 500;
      font-size: 16px;
      text-align: center;
    }
  }

  .action_btns_responsive {
    width: 100%;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
  }

  .btn_text_input {
    width: 300px;

    .input_root {
      &:hover {
        .input_notchedOutline {
          border-color: #e7e7e7;
        }
      }
    }

    .input_notchedOutline {
      border-color: #e7e7e7;
    }

    .input_focused {
      .input_notchedOutline {
        border-color: #e7e7e7 !important;
      }
    }
  }

  .btn_link_input {
    width: 300px;

    .input_root {
      &:hover {
        .input_notchedOutline {
          border-color: #e7e7e7;
        }
      }
    }

    .input_notchedOutline {
      border-color: #e7e7e7;
    }

    .input_focused {
      .input_notchedOutline {
        border-color: #e7e7e7 !important;
      }
    }
  }
}