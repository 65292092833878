@import '~utils/styles/styles.scss';

.chat-message-content-media-document {
  display: grid;
  grid-template-columns: 32px max-content;
  grid-template-rows: repeat(2, 16px);
  grid-column-gap: 10px;

  &__icon {
    align-self: stretch;
    justify-self: stretch;

    grid-row: span 2;
  }

  &__name {
    grid-column: 2;

    margin: 0;

    font-size: 15.8px;
    font-weight: 900;
  }

  &__size {
    grid-column: 2;
    grid-row: 2;

    margin: 0;

    color: $gray-color;

    font-size: 13.72px;
    font-weight: 500;
  }

  &-icon {
    display: grid;
    align-items: center;
    justify-items: center;

    border-radius: 2px;

    background-color: $dark-ocean-blue-color-2;
    color: white;

    font-size: 16px;
  }
}
