.video-chat-side-panel-participant-volume-slider {
  &-target {
    &:hover {
      cursor: pointer;
    }
  }

  display: grid;
  grid-template-columns: 1fr max-content;
  grid-column-gap: 10px;
  align-items: center;

  width: 250px;
  padding: 7px 15px;

  &__volume-level {
    margin: 0;
  }
}
