.container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;

  .textfield_root {
    width: 100%;
  }

  .input_root {
    &:hover {
      .input_notchedOutline {
        border-color: #e7e7e7 !important;
      }
    }
  }

  .input_root_responsive {
    width: 100% !important;
  }

  .input_notchedOutline {
    border-color: #e7e7e7 !important;
  }

  .input_focused {
    .input_notchedOutline {
      border-color: #e7e7e7 !important;
    }
  }

  .input_input {
    font-family: Avenir;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0px;
    color: #213649;
  }

  .cross_icon_container {
    cursor: pointer;
  }
}

.drag_icon {
  color: #e7e7e7;
}
