.ranking_tag_container {
  width: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
  padding: 8px;
  border-radius: 5px;
  margin-right: 8px;

  .number {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: #213649;
  }

  .icon {
  }
}
