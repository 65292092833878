.checkbox_root {
  padding: 0px 5px 0px 0px !important;
}

.MuiFormControlLabel_root {
  margin-left: 0px !important;
  margin-bottom: 0px !important;
}

.email_details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}
