.main {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 15px;
  margin-top: 15px;

  .main_heading {
    font-family: Avenir;
    font-size: 20px;
    font-weight: 800;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.87);
  }

  .selected_tags_container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .heading {
      font-family: Avenir;
      font-size: 16px;
      font-weight: 800;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.87);
    }

    .tags {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }
  }

  .actions_main {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .heading {
      font-family: Avenir;
      font-size: 16px;
      font-weight: 800;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.87);
    }

    .options {
      .options_list {
        display: flex;
        flex-direction: column;
        gap: 0px;

        .radio_btn {
          margin-left: 0px;
          margin-bottom: 0px;
        }

        .checkbox_btn {
          margin-left: 0px;
          margin-bottom: 0px;
          align-items: flex-start;
        }
        .checkbox_control {
          padding-top: 0px;
        }
      }
    }
  }
}
