@media screen and (max-width: 576px) {
    .resources-image-modal {
        padding: 0 !important;

        .cohere-modal {
            height: 100%;
            background-color: black;
            border-radius: 0;

            svg {
                color: white;
            }

            div:nth-child(2) {
                padding: 0;
                margin: auto;
            }
        }
    }
}

.resources-progress {
    margin-top: 10px;
    margin-right: 10px;

    .upload-progress {
        position: relative !important;

    }
}

.resource-card-dropdown-icon {
    svg {
        color: black;
    }

}