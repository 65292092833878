.container {
  display: flex;
  align-items: flex-start;

  .rating_stars_container {
    display: flex;
    flex-wrap: wrap;
    gap: 9px;
  }
}
