@import '~utils/styles/styles.scss';

.chats-list-chat-general-info {
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  grid-column-gap: 12px;

  & &__select {
    padding: 8px;
  }

  &__title {
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 900;

    @media screen and (max-width: $mobile-size) {
      font-size: 14px;
    }
  }
}
