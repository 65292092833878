// use for styling //

.editor_container {
  width: 100%;
  height: 100%;
}

.custom-text-area-container {
  display: none;

  .disabled-ck-editor {
    pointer-events: none;
    opacity: 0.5;
  }

  &.custom-text-area-focused {
    &.custom-text-area-expanded {
      .rich-text-placeholder {
        height: 372px !important;
      }

      .cke_contents {
        height: 360px !important;
      }
    }

    .cke_contents {
      height: 120px !important;
    }

    .cke_top {
      display: block !important;
    }
  }

  .cke_contents {
    height: 162px !important;
  }

  .cke_top {
    display: none !important;
  }

  .cke_bottom {
    display: none;
  }
}