.myDIV + .hide {
  display: none;
}

.myDIV:hover + .hide {
  display: block;
  color: grey;
}

.underline_special {
  &::before {
    border-color: red;
    border-bottom: 3px solid green;
  }
}
