.container {
  position: relative;
  width: 200px;
  height: 210px;
  border-radius: 6px;
  padding: 10px;
  background-color: #fafafa;

  .image_placeholder {
    background-color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .upload_btn {
    position: relative;
    float: right;
    right: 10px;
    bottom: 45px;
    border-radius: 48px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    background: #cdba8f;
  }

  .delete_btn {
    position: absolute;
    right: 15px;
    top: 15px;
    background: #cdba8f;
    width: 25px;
    height: 25px;
    border-radius: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
