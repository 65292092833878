.main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 2px 8px;
  background-color: #c9b382;
  border-radius: 5px;

  &:hover {
    .cross_icon {
      display: flex;
      cursor: pointer;
    }
  }

  .tag_name {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #ffffff;
  }
  .cross_icon {
    display: none;
  }
}
