.container {
  padding: 20px 10px;

  .buttons_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .mainContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .automatedPayout {
        font-family: Avenir;
        font-size: 12px;
        font-weight: 800;
        line-height: 18px;
        text-align: left;
        color: #000000de;
        display: flex;
        flex-direction: row;
      }
    }

    .welcome_button {
      font-family: 'Avenir' !important;
      font-size: 14px !important;
      font-weight: 800 !important;
      line-height: 24.5px !important;
      text-align: center !important;
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
      align-items: center !important;
      padding: 6px 9px !important;
      height: 48px !important;
      letter-spacing: 0px !important;
    }

    .rightbuttoncontainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 5px;

      .welcome_button_invert {
        font-family: 'Avenir' !important;
        font-size: 14px !important;
        font-weight: 800 !important;
        line-height: 24.5px !important;
        text-align: center !important;
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;
        align-items: center !important;
        padding: 6px 16px 6px 16px !important;
        height: 48px !important;
        color: #215c73 !important ;
        border: 1px solid #215c73 !important ;
        letter-spacing: 0px !important;
      }

      .welcome_button {
        font-family: 'Avenir' !important;
        font-size: 14px !important;
        font-weight: 800 !important;
        line-height: 24.5px !important;
        text-align: center !important;
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;
        align-items: center !important;
        padding: 6px 16px 6px 16px !important;
        height: 48px !important;
        letter-spacing: 0px !important;
      }
    }
  }

  .export_button {
    font-family: 'Avenir' !important;
    font-size: 14px !important;
    font-weight: 800 !important;
    line-height: 24.5px !important;
    text-align: center !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    height: 40px !important;
    color: #215c73 !important;
    border: 1px solid #215c73 !important;
    border-radius: 2px !important;
    gap: 10px;
    letter-spacing: 0px !important;
  }
}
