.video-player-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 101;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: white;

  &__video-player {
    align-self: stretch;
    flex-grow: 1;
  }
}

.video-player {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: 1fr 66px;

  &__content {
    grid-column: 1;
    grid-row: 1;

    display: grid;
    align-items: stretch;
    justify-content: stretch;

    &-container {
      display: grid;
      align-items: stretch;
      justify-content: stretch;
    }

    &-inner {
      position: relative;
      display: flex;
      align-items: stretch;
      justify-content: center;
      background-color: #222222;
    }

    &-video {
      position: absolute;
      width: 100%;
      height: 100%;
      outline: none;
    }
  }

  &__footer {
    grid-column: 1;
    grid-row: 2;
  }

  &__side-panel {
    grid-row: 1 / 3;
    grid-column: 2;

    align-self: stretch;

    width: 400px;
    height: 100vh;
  }

  &-footer {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-column-gap: 20px;
    align-items: center;

    padding: 0 36px;

    background-color: black;

    &-toolbar {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: max-content;
      justify-content: center;
      grid-column-gap: 20px;
    }
  }
}

.video-player-mobile {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: 1fr 90px;

  &__content {
    grid-column: 1;
    grid-row: 1;

    display: grid;
    align-items: stretch;
    justify-content: stretch;
  }

  &__footer {
    grid-column: 1;
    grid-row: 2;
  }

  &__side-panel {
    grid-row: 1;
    grid-column: 1 / 2;

    align-self: stretch;
    width: 100%;
    background-color: white;
    z-index: 100;
  }

  &-footer {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-column-gap: 20px;
    align-items: center;

    padding: 0 36px;

    background-color: black;

    &-toolbar {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: max-content;
      justify-content: center;
      grid-column-gap: 20px;
    }
  }
}
