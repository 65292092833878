.editor_container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .field_name_container {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 15px;

    .field_name {
      font-family: Avenir;
      font-size: 14px;
      font-weight: 800;
      line-height: 16px;
      color: #213649;
    }
  }
  .field_value_container {
    display: flex;
    justify-content: space-between;
    align-items: end;
    gap: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dfe3e4;

    .field_value {
      font-family: Avenir;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #000000;
      text-align: left;
    }

    .hoverable {
      cursor: pointer;
      text-decoration: underline;
      &:hover {
        text-decoration: underline;
      }
    }

    .editbutton_container {
      .editbutton {
        height: 30px;
        font-family: Avenir;
        font-size: 14px;
        font-weight: 800;
        line-height: 20px;
        padding: 0px 10px;
        border-radius: 4px;
        border: 1px solid #dfe3e4;
        background-color: white;
        color: #215c73;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }
    }
  }
}
