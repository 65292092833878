.container {
  width: 127px;
  // position: absolute;
  // top: 0px;
  // left: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 15px 10px;
  box-shadow: 0px 1px 6.4px 0px rgba(0, 0, 0, 0.25);
  // z-index: 10;

  .action {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: #213649;
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 10px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .last_item {
    border-bottom: 0px solid #e7e7e7;
    padding-bottom: 0px;
  }
}
