.MagicModalContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: 20px;
  .MagicButtonContainer {
    // height: 80px;
    // background-color: #f7f3eb;
    // border-radius: 15px;
    // border: 10px solid #f7f3eb;

    .MagicButton {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      padding: 5px 10px;
      gap: 10px;
      img {
        height: 45px;
        width: 45px;
        margin-bottom: 3px;
      }

      .btnTxt {
        font-family: Avenir;
        font-size: 16.73px;
        font-weight: 800;
        line-height: 26.3px;
        text-align: center;
      }
    }
  }

  .MagicModalTitle {
    font-family: Avenir;
    font-size: 22px;
    font-weight: 900;
    text-align: center;
    color: #215c73;
  }

  .MagicModalDescription {
    color: #000000;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
  }

  .btn {
    font-family: Avenir !important;
    font-size: 14px !important;
    font-weight: 900 !important;
    line-height: 16px !important;
    letter-spacing: 1.25px !important;
    text-align: center !important;
    margin-bottom: 20px;
  }
}
