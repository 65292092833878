$mobile: 576px;

.container {
  flex: 1;
  display: flex;
  flex-direction: column;

  .preview_container {
    padding: 35px 0px;
    max-width: 750px;
    min-width: 750px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    @media screen and (max-width: $mobile) {
      min-width: 320px;
      max-width: 320px;
    }

    .header_container {
      display: flex;
      flex-direction: row;
      gap: 10px;

      .header {
        font-family: Avenir;
        font-weight: 900;
        font-size: 16px;
        color: #282b2b;
      }

      .question {
        display: flex;
        flex-direction: row;

        .question-content {
          max-width: 100%;
          overflow-wrap: break-word;
          word-wrap: break-word;
          word-break: break-word;
        }
      }
    }

    .header_container_form {
      display: flex;
      flex-direction: row;
      gap: 10px;
      margin-top: 150px;

      @media screen and (max-width: $mobile) {
        margin-top: 50px;
      }

      .header {
        font-weight: 800;
        font-size: 20px;
        font-family: Avenir;
        color: #213649;
      }

      .question {
        font-weight: 800;
        font-size: 20px;
        font-family: Avenir;
        color: #213649;
        display: flex;
        flex-direction: row;

        .question-content {
          max-width: 100%;
          overflow-wrap: break-word;
          word-wrap: break-word;
          word-break: break-word;
        }
      }
    }

    .options_container {
      .sub_header {
        font-family: Avenir;
        font-weight: 500;
        font-size: 14px;
        color: #282b2b;
      }

      .option_block {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        background-color: #ffffff;

        .option_left_panel {
          // background-color: #DFE3E4;
          padding: 1px 10px 10px 10px;

          .checkBoxUnchecked {
            height: 18px;
            width: 18px;
            border-radius: 4px;
            border: 1.5px solid #213649;
          }

          .checkBoxChecked {
            height: 18px;
            width: 18px;
            border-radius: 4px;
            border: 1.5px solid #213649;
            background-color: #213649;
            align-items: center;
            justify-content: center;
            display: flex;
          }

          .MuiFormControlLabel_root {
            margin: 0px;
          }

          .checkbox_root {
            color: #213649;
          }
        }

        .option_right_panel {
          padding: 10px 20px;
        }
      }

      .footer {
        display: flex;
        justify-content: flex-end;
        padding-top: 40px;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;

        .banner {
          background-color: #f5f5f5;
          border: 1px solid #dfe3e4;
          justify-content: center;
          align-items: center;
          display: flex;
          width: 100%;
          color: #c11111;
          font-weight: 800;
          font-family: Avenir;
          font-size: 16px;
          height: 48px;
          margin-bottom: 20px;
        }

        .bannerCorrect {
          background-color: #f5f5f5;
          border: 1px solid #dfe3e4;
          justify-content: center;
          align-items: center;
          display: flex;
          width: 100%;
          color: #259945;
          font-weight: 800;
          font-family: Avenir;
          font-size: 16px;
          height: 48px;
          margin-bottom: 20px;
        }

        .submit_btn {
          @media screen and (max-width: $mobile) {
            width: 100%;
            /* border: 1px solid #215c73; */
          }
        }

        .backbtn {
          @media screen and (max-width: $mobile) {
            width: 100%;
            // border: 1px solid #215c73;
          }
        }
      }
    }
  }
}