.clients-list-view-header {
  display: flex;
  margin: 10px 0;
  float: right;

  .input-field {
    margin: 0 10px;
    width: 300px;

    @media screen and (max-width: 600px) {
      width: auto;
    }

    .MuiOutlinedInput-root {
      height: 50px;
      background-color: white;

      .MuiSvgIcon-root {
        margin-right: 10px;
      }

      input {
        height: 16px;
      }
    }
  }

  .export-button {
    background-color: #215c73;

    &:hover {
      background-color: #215c73;
    }

    svg {
      margin-right: 5px;
    }
  }
}

.icon-button {
  .MuiIconButton-label {
    svg {
      font-size: 24px;
    }
  }
}
