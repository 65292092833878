@import 'utils/styles/styles.scss';
.chat-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  &-info {
    display: flex;
    align-items: center;

    &__title {
      margin: 0 0 0 10px;

      font-size: 19px;
      font-weight: bold;
    }
    @media screen and (max-width: $mobile-size) {
      &__title {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}
