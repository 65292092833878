@import '../../../../utils/styles/styles.scss';

.form_body {
  display: flex;
  justify-content: center;
  align-items: center;

  .form_container {
    text-align: center;
    flex-basis: 846px;
    padding: 20px 20px 0px;

    .title_class {
      font-size: 22px;
      font-weight: 400;
      font-family: $font-family-Avenir;
      margin-bottom: 40px;
    }

    .card_container {
      display: flex;
      gap: 20px;
      padding: 24px;
      border-radius: 4px;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      border: 1px solid $grey-tint;

      .field_container {
        flex-basis: 368px;
        flex-grow: 1;

        .error {
          &:after {
            border-bottom-color: #dfe3e4 !important;
          }
        }

        .personalize_dropdown {
          // position: absolute;
          // top: 42px;
          // right: 20px;
          font-family: Avenir;
          font-size: 12px;
          font-weight: 600;
          line-height: 20px;
          color: rgba(0, 0, 0, 1);
          display: flex;
          gap: 5px;
          cursor: pointer;
          padding-left: 10px;
        }

        .personalize_dropdownpanel {
          position: absolute;
          top: 80px;
          right: -10px;
          box-shadow: 0 2px 4px 0 rgba(157, 157, 157, 0.3);
          display: flex;
          flex-direction: column;
          gap: 0px;
          background-color: white;
          width: 200px;

          .personalize_dropdownpanel_item {
            border-bottom: 1px solid #e7e7e7;
            text-align: left;
            padding: 10px 15px;
            cursor: pointer;
            font-family: Avenir;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.5px;
            color: #4a4a4a;
            justify-content: space-between;
            display: flex;
            align-items: center;
          }

          .personalize_dropdownpanel_item_last {
            text-align: left;
            padding: 10px 15px;
            cursor: pointer;
            font-family: Avenir;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.5px;
            color: #4a4a4a;
            justify-content: space-between;
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .radio_btns_conatiner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: start;
      margin-top: 5px;

      .radio_btn_graphical {
        color: gray;
      }
    }

    .section {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: space-between;

      .checkbox_container {
        display: flex;
        justify-content: center;
        align-items: center;

        .checkbox {
          color: $primary-btn-color;
          padding: 0px 10px 0px 0px;
        }

        .checkbox_label {
          margin: 0px;
          font-family: $font-family-Avenir;
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          color: #4a4a4a;

          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }

      .btn_container {
        display: flex;
        gap: 10px;

        .email_input {
          height: 50px;
          width: 207px;
          padding: 10px;
          border: 1px solid #dfe3e4;
          border-radius: 4px;
          font-family: $font-family-Avenir;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;

          &:focus {
            outline: none;
            border: 1px solid #dfe3e4;
          }
        }

        .btn_preview {
          font-family: $font-family-Avenir;
          font-size: 16px;
          font-weight: 800;
          border: 1px solid $secondary-btn-color;
          height: 48px;
          padding: 0px 24px;
        }

        .btn_test {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: $font-family-Avenir;
          font-size: 16px;
          font-weight: 800;
          gap: 4px;
          height: 48px;
          padding: 0px 24px;
        }
      }
    }

    .template_editor_container {
      width: 100%;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      position: relative;

      .personalize_dropdown {
        position: absolute;
        top: 42px;
        right: 20px;
        font-family: Avenir;
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        color: rgba(0, 0, 0, 1);
        display: flex;
        gap: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
      }

      .personalize_dropdownpanel {
        position: absolute;
        top: 80px;
        right: -10px;
        box-shadow: 0 2px 4px 0 rgba(157, 157, 157, 0.3);
        display: flex;
        flex-direction: column;
        gap: 0px;
        background-color: white;
        width: 200px;

        .personalize_dropdownpanel_item {
          border-bottom: 1px solid #e7e7e7;
          text-align: left;
          padding: 10px 15px;
          cursor: pointer;
          font-family: Avenir;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.5px;
          color: #4a4a4a;
          justify-content: space-between;
          display: flex;
          align-items: center;
        }

        .personalize_dropdownpanel_item_last {
          text-align: left;
          padding: 10px 15px;
          cursor: pointer;
          font-family: Avenir;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.5px;
          color: #4a4a4a;
          justify-content: space-between;
          display: flex;
          align-items: center;
        }
      }

      .error {
        color: red;
      }
    }

    .email_label {
      text-align: start;
      font-size: 16px;
      font-weight: 800;
      font-family: $font-family-Avenir;
      margin: 20px 0px 0px;
    }
  }
}

.stepper_container {
  width: 100%;
}

.preview_conatiner {
  border: 2px solid $grey-background;
  width: 100%;
  box-shadow: 0px 8px 8px 0px $grey-background;

  .preview_btns {
    display: flex;
    gap: 8px;
    justify-content: end;
    padding: 10px 20px;
    width: 100%;
    border-bottom: 2px solid $grey-background;
    text-align: end;

    .image {
      cursor: pointer;
    }
  }

  .preview_body {
    display: flex;
    justify-content: center;

    .responsive_preview {
      width: 420px;
      padding: 20px;
      border-left: 1px solid #e7e7e7;
      border-right: 1px solid #e7e7e7;

      @media screen and (max-width: $mobile-size) {
        padding: 0px;
      }
    }

    .responsive_desktop_preview {
      padding: 20px;
    }
  }
}

.Mui_select {
  display: flex !important;
  align-items: center !important;
}

.email_main_container {
  width: 100%;

  .email_main {
    width: 100%;
  }

  .email_root {
    border: 1px solid rgba(0, 0, 0, 0.23);
  }

  .email_notchedOutline {
    border: none;
  }

  .email_adornedEnd {
    padding-right: 1px;
  }

  .endAdornment {
    background-color: #f5f5f5;
    padding: 10px 25px;

    .select_root {
      padding-right: 100px;

      @media screen and (max-width: 425px) {
        padding-right: 25px;
      }
    }

    @media screen and (max-width: 425px) {
      padding: 10px 10px;
    }
  }
}
