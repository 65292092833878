@import '~utils/styles/styles.scss';

// .client-course-session-time-actions {
//   display: grid;
//   grid-auto-flow: column;
//   grid-column-gap: 10px;
// }

html {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.main-container-session-8 {
  display: flex;
  padding: 10px 38px;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 576px) {
    padding: 0px 15px;
  }
}

.main-container-session-8-for-card {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.contribution-view-calendar {
  @media screen and (max-width: $tablet) {
    width: 100%;
    margin: 0 auto;
  }
}

.Temp-One-Page-Styling {
  width: 100%;
  margin: 0px auto;
}

.one-to-one-client-share-button {
  margin: 0 0 0 auto !important;
  margin-bottom: 20px !important;
  width: fit-content !important;

  span {
    justify-content: right;
  }

  @media screen and (max-width: 576px) {
    margin-bottom: 0 !important;
  }
}

@media screen and (max-width: 576px) {
  .Temp-One-Page-Styling {
    width: 90% !important;
    margin: 0px auto !important;
  }

  .main-about-div {
    padding: 0px !important;
  }
}
