.calendar-container-custom {
  margin-top: 0px;
  padding: 0px 16px 16px 16px;
  font-family: 'Brandon Text';
  justify-content: center;
  font-style: normal;
  font-size: 17px;
  font-weight: 600;
  margin: auto;

  .easy-booking-title {
    font-family: Avenir;
    font-size: 20px;
    font-weight: 850;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 10px;
    margin-bottom: 0;
    color: #215c73;
    text-align: center;
  }

  .non-easy-booking-title {
    display: none;
  }

  .custom-calendar {
    width: 100%;
    overflow-x: auto;
    background-color: white;
    border-radius: 25px;
    padding: 24px 20px;
    padding-top: 0px;
    box-shadow: 0 2px 5px 1px rgb(64 60 67 / 5%);
    &.dark-theme {
      .rbc-month-view {
        height: 250px;
        border: none;

        .rbc-header {
          border-bottom: none;
        }

        .rbc-header + .rbc-header {
          border-left: none;
        }

        .rbc-month-row {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .rbc-row-content {
            .rbc-row {
              .rbc-now {
                font-weight: 900 !important;
                color: white !important;
              }
            }
          }
        }

        .rbc-month-row + .rbc-month-row {
          border-top: none;
        }

        .rbc-date-cell {
          text-align: center;
          padding-right: 0px;
          margin-left: 3px;
        }
      }
    }
    .rbc-toolbar {
      position: relative;
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;

      &-label {
        position: absolute;
        left: -9px;
        font-size: 50px;
        font-family: 'Brandon Text';
        font-style: normal;
        font-weight: 700;
      }
    }

    .rbc-month-view {
      height: 250px;
      border: none;

      .rbc-header {
        border-bottom: none;
      }

      .rbc-header + .rbc-header {
        border-left: none;
      }

      .rbc-month-row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .rbc-row-content {
          .rbc-row {
            .rbc-now {
              font-weight: 900 !important;
              color: black !important;
            }
          }
        }
      }

      .rbc-month-row + .rbc-month-row {
        border-top: none;
      }

      .rbc-date-cell {
        text-align: center;
        padding-right: 0px;
        margin-left: 3px;
      }
    }

    .rbc-time-view {
      overflow-x: scroll;
    }

    @media screen and (max-width: 1200px) {
      .rbc-time-view {
        width: 90vw;
      }

      .rbc-toolbar {
        flex-direction: column;

        &-label {
          position: static;
          margin: 5px 0;
        }
      }
    }
  }
}

.toolbar-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  height: 0px;
  margin-bottom: 67px;
  padding: 0px 10px 0px 5px;

  &.calendly {
    align-items: center !important;
    height: auto !important;
    margin-top: 20px;
    margin-bottom: 20px !important;
    padding: 0px 5px 0px 5px !important;
    .btn-current {
      padding: 0 !important;
    }
    .navigation-buttons {
      display: flex;
      align-items: center;
      max-height: 100%;
      gap: 10px;
      svg {
        cursor: pointer;
      }
    }
  }
}

.btn-back {
  border: none;
  padding: 10px;
  margin: 3px;
  font-size: 30px;
}

.btn-current {
  border: none;
  padding: 8px 20px;
  margin: 3px;
  font-size: 20px !important;
}

.btn-next {
  border: none;
  padding: 10px;
  margin: 3px;
  font-size: 30px;
}

.label-date {
  display: flex;
  justify-content: center;
  align-items: center;
}
