.top-note {
  /* margin-bottom: 10px; */
}

.top-note p {
  color: #858585 !important;
  font-size: 14px;
  font-family: Avenir;
  font-size: 14px;
  font-weight: 350;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  /* max-width: 400px;   */
}

.sub-heading-share-popup {
  font-family: Inter;
  font-size: 22px;
  font-weight: 700;
  line-height: 23px;
  text-align: left;
  color: #000000DE;
}

.emailtext p {
  font-family: Avenir !important;
  font-size: 12px !important;
  font-weight: 300 !important;
  line-height: 16px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  margin-bottom: 0px !important;
  margin-top: 16px;
}

.share-recording-text p {
  font-family: Avenir !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.share-btn p {
  font-family: Avenir !important;
  font-size: 14px !important;
  font-weight: 900 !important;
  line-height: 16px !important;
}

.Instruction-note div {
  font-family: Inter !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 15px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.Invite-Purchase p {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
}

.lead-magnet-heading {
  font-family: Avenir;
  font-size: 16px;
  font-weight: 800;
  line-height: 22px;
  text-align: left;
  color: #1E1E1E;
  ;
}

.lead-magnet-sub-heading {
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #1E1E1E;
  ;
}

.lead-magnet-input-field {
  width: 646.18px;
  height: 40px;
  padding: 0px 14px 0px 14px;
  gap: 654px;
  border-radius: 4px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  border-top: 1px solid rgba(223, 227, 228, 1)
}

.dark-mode .cohere-modal {
  background-color: rgb(45, 47, 49);
}

.lead-magnet-form-modal .cohere-modal {
  /* width: 800px; */
  width: 727px;
}

@media screen and (max-width: 425px) {
  .lead-magnet-form-modal .cohere-modal {
    width: 300px;
  }
}

@media screen and (max-width: 576px) {
  .lead-magnet-form-modal .cohere-modal {
    width: 500px;
  }
}

.feature-feedback-form-modal .cohere-modal {
  height: 578px;
}

@media screen and (max-width: 576px) {
  .feature-feedback-form-modal .cohere-modal {
    height: 525px;
  }
}

.feature-feedback-form-modal .cohere-modal .body {
  max-height: none !important;
  padding: 0 25px;
}

.feature-feedback-form-modal-dark .cohere-modal {
  height: 578px;
  background: #2d2f31;
}

@media screen and (max-width: 576px) {
  .feature-feedback-form-modal-dark .cohere-modal {
    height: 525px;
    background: #2d2f31;
  }
}

.feature-feedback-form-modal-dark .cohere-modal .body {
  max-height: none !important;
  padding: 0 25px;
  background: #2d2f31;
}

.passcode-text {
  font-style: normal;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0;
}

.invite-links-container .share-btn {
  margin-top: 0;
  background-color: transparent;
}

.invite-links-container>div:not(:last-child) .share-btn {
  /* margin-bottom: 20px; */
}

@media only screen and (max-width: 480px) {
  .share-btn p {
    font-size: 11px !important;
  }
}