.question_container {
  width: 100%;
  display: flex;
  gap: 10px;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;

  &:hover {
    // border: 1px dotted #e7e7e7;
    box-shadow: 0 1px 3px rgba(23, 23, 23, 0.24);
  }

  .dragicon_container {
    .drag_icon {
      color: #cfcfcf;
    }
  }

  .question {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .question_type {}

    .question_desc {
      font-family: Avenir;
      font-size: 16px;
      font-weight: 900;
      line-height: 23.94px;
      color: #282b2b;
      overflow: hidden;
      max-height: 50px;
    }

    .question_desc_place {
      font-family: Avenir;
      font-size: 16px;
      font-weight: 400;
      line-height: 23.94px;
      color: #282b2b;
    }
  }

  .actionicon_container {
    position: absolute;
    cursor: pointer;
    right: 15px;
  }
}

.question_container_responsive {
  border-bottom: 1px solid #cfcfcf;
  border-radius: 0px;
}

.focused_question {
  background-color: #f9f9f9;

  &:hover {
    border: none;
  }
}

.question_preview_container {
  width: 100%;
  display: flex;
  gap: 10px;
  padding: 10px;
  border-radius: 4px;
  background-color: #ffffff;
  position: relative;
  margin-bottom: 5px;

  .dragicon_container {
    .drag_icon {
      color: #b2cae0;
    }
  }

  .question {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .question_type {}

    .question_desc {
      font-family: Avenir;
      font-size: 16px;
      font-weight: 900;
      line-height: 23.94px;
      color: #282b2b;
      padding-top: 5px;
    }
  }

  .actionicon_container {
    position: absolute;
    cursor: pointer;
    right: 15px;
  }
}