.testimonial-modal {
  .cohere-modal {
    width: 100%;

    .body {
      padding: 20px;
    }
  }
}

.testimonial-template-modal {
  .cohere-modal {
    min-width: 380px;

    &.cohere-modal-dark {
      .body {
        .MuiFormLabel-root {
          color: white;
        }
        .MuiOutlinedInput-root {
          .MuiOutlinedInput-input {
            color: white;
          }
          svg {
            color: white;
          }
        }
      }
    }

    .body {
      padding: 20px;

      .MuiFormLabel-root {
        color: #282b2b;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
      }

      .select-default {
        .MuiSelect-root {
          color: #d3d3d3;
        }
        .MuiSelect-nativeInput {
          font-family: 'Roboto';
        }
      }
    }

    .modal-footer-container {
      .modal-footer-buttons {
        .cancel-btn {
          padding: 10.8px 31.152px 10.8px 31.518px !important;
        }

        .submit-btn {
          padding: 11px 30.231px 11px 30.589px !important;
        }
      }
    }
  }
}
