@import '~utils/styles/styles.scss';

.chat-message {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: repeat(2, max-content);
  grid-column-gap: 15px;

  & &__author-avatar {
    grid-row: span 2;
  }

  &__info {
    grid-column: 2;

    margin: 0;
  }

  &__content {
    align-self: start;
    justify-self: start;
    max-width: 100%;
    & > a > .chat-message-content-media-document {
      grid-template-columns: 32px auto;
      grid-template-rows: repeat(2, 20px);
      & > p {
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  &-info {
    font-family: 'Avenir';

    &__author {
      font-size: 16px;
      font-weight: 900;
    }

    &__time {
      color: $gray-color;
      font-size: 13.72px;
      font-weight: 500;
    }
  }

  &-content {
    &__text {
      white-space: pre-line;
      margin: 0;
    }
  }
}
