@import '../../../../utils/styles/styles.scss';

.form_body {
  display: flex;
  justify-content: center;
  align-items: center;

  .form_container {
    text-align: center;
    flex-basis: 846px;
    padding: 20px 20px 0px;

    p {
      margin-bottom: 0px;
    }

    .title_class {
      font-size: 22px;
      font-weight: 400;
      font-family: $font-family-Avenir;
      margin-bottom: 40px;
    }

    .cards_container {
      display: flex;
      gap: 18px;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      .scratch_card {
        flex-basis: 390px;
        border-radius: 4px;
        border: 1px solid $grey-tint;
        padding: 40px 45px;
        height: 220px;
        cursor: pointer;

        .heading {
          font-size: 18px;
          font-family: $font-family-Avenir;
          font-weight: 800;
          margin-bottom: 18px;
        }

        .text {
          font-size: 14px;
          font-family: $font-family-Avenir;
          text-align: center;
        }

        img {
          width: 42px;
          height: 36px;
          margin-bottom: 18px;
        }
      }

      .pre_design_card {
        flex-basis: 390px;
        border-radius: 4px;
        border: 1px solid $grey-tint;
        padding: 40px 45px;
        height: 220px;
        cursor: pointer;

        position: relative;

        .comingsoon_badge {
          position: absolute;
          top: 0px;
          left: 0px;
          font-size: 14px;
          font-style: italic;
          font-family: $font-family-Avenir;
          font-weight: 600;
          background-color: #215c73;
          color: white;
          padding: 2px 6px;
          border-radius: 4px;
        }

        .heading {
          font-size: 18px;
          font-family: $font-family-Avenir;
          font-weight: 600;
          margin-bottom: 18px;
        }

        .text {
          font-size: 14px;
          font-family: $font-family-Avenir;
          text-align: center;
        }

        img {
          width: 42px;
          height: 36px;
          margin-bottom: 18px;
        }
      }

      .selected {
        border: 2px solid #215c73;
        border-style: dotted;
      }
    }
  }
}

.stepper_container {
  width: 100%;
}

.preview_conatiner {
  border: 2px solid $grey-background;
  width: 100%;
  box-shadow: 0px 8px 8px 0px $grey-background;

  .preview_btns {
    display: flex;
    gap: 8px;
    justify-content: end;
    padding: 10px 20px;
    width: 100%;
    border-bottom: 2px solid $grey-background;
    text-align: end;

    .image {
      cursor: pointer;
    }
  }

  .preview_body {
    display: flex;
    justify-content: center;

    .responsive_preview {
      width: 420px;
      padding: 20px;
      border-left: 1px solid #e7e7e7;
      border-right: 1px solid #e7e7e7;

      @media screen and (max-width: $mobile-size) {
        padding: 0px;
      }
    }

    .responsive_desktop_preview {
      padding: 20px;
    }
  }
}