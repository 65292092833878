.container {
  position: absolute;
  width: 300px;
  background-color: white;
  top: 45px;
  right: 45px;
  z-index: 1;
  border-radius: 5px;
  box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;

  .top_container {
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dfe3e4;

    .heading {
      font-family: Avenir;
      font-size: 16px;
      font-weight: 800;
      line-height: 16px;
      color: #213649;
    }

    .cross_btn {
      cursor: pointer;
    }
  }

  .body_container {
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .main_container {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .heading {
        font-family: Avenir;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        color: #47627b;
      }

      .filter_container {
        width: 100%;

        .filter {
          border: 1px solid #b2cae0;
          border-radius: 5px;
          padding: 5px 10px;
        }

        .filter_status {
          border: 1px solid #b2cae0;
          border-radius: 5px;
          padding: 5px 5px;
        }

        .filter_select_status {
          font-family: Avenir;
          font-size: 16px;
          font-weight: 400;
          line-height: 16px;
          color: #213649;

          &:focus {
            background-color: white !important;
          }
        }

        .filter_select {
          &:focus {
            background-color: white !important;
          }
        }
      }

      .date_filter_container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        border-radius: 5px;
        border: 1px solid #b2cae0;
        padding: 5px 8px;

        .date_heading {
          font-family: Avenir;
          font-size: 16px;
          font-weight: 400;
          line-height: 16px;
          color: #7d9cb8;
        }

        .date_picker_container {
          .date_underline {
            &::before {
              border-bottom: none !important;
            }

            &::after {
              border-bottom: none !important;
            }
          }

          .date_input {
            padding: 7px 0 5px !important;
          }
        }
      }
    }

    .footerbtn_container {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      .clear_btn {
        width: 125px;
        padding: 0px;
        height: 35px;
      }

      .submit_btn {
        width: 125px;
        padding: 0px;
        height: 35px;
      }
    }
  }
}