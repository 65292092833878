$CreateCampaignBtnColor: #c9b382;
$CreateCampaignDisabledBtnColor: #ededed;

.container {
  width: 100%;
  position: relative;

  .headerContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .heading {
      font-family: Avenir;
      font-size: 22px;
      font-weight: 400;
      line-height: 30.05px;
      color: black;
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .createCampaignBtn {
      font-family: Avenir;
      font-size: 14px;
      font-weight: 900;
      line-height: 16px;
      background-color: $CreateCampaignBtnColor;
      border: 1px solid $CreateCampaignBtnColor;
      border-radius: 4px;
      color: white;
      padding: 15px 25px;

      &:disabled {
        background-color: $CreateCampaignDisabledBtnColor;
        border: 1px solid $CreateCampaignDisabledBtnColor;
        color: #a7a7a7;
      }
    }
  }
  .bodyContainer {
    width: 100%;
    padding: 60px 45px;
    margin-top: 15px;
    border: 1.2px solid #dfe3e4;
    border-radius: 4px;
    background-color: #ffffff;

    .slider {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .paginationWrapper {
    display: flex;
    justify-content: end;
    align-items: center;

    nav {
      background-color: #fafafa;
      padding: 0px;
      margin-top: 20px;
    }
  }
}
