.thumbnail-avatar-container {
  display: flex;

  .thumbnail-avatar {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 48px;
    height: 48px;
    min-width: 20px;
    margin: auto;
    border-radius: 24px;

    .thumbnail-input-avatar {
      opacity: 0;
    }
  }
}

.thumbnail-avatar-text {
  margin-bottom: 0.5rem;
  margin-right: 1.5rem;
  text-align: center;

  transform: translate(0, 4px);
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-family: Avenir;
  font-weight: 300;
  line-height: 1rem;
  letter-spacing: 0.4px;
}