.container {
  padding: 25px 0px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  .main_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    .heading {
      font-family: Avenir;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      color: #213649;
    }
    .tag_list {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .tag_container {
        position: relative;

        .cross_icon {
          cursor: pointer;
          position: absolute;
          top: -10px;
          right: -5px;
        }
        .tag {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: -0.24px;
          color: #ffffff;
          padding: 2px 8px;
          background-color: #c9b382;
          border-radius: 5px;
        }
      }
    }

    .input_underline {
      &::before {
      }
      &::after {
      }
    }
  }
}
