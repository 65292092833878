.container {
  width: 250px;
  background-color: white;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 48px;
  box-shadow: 0 2px 4px 0 rgba(157, 157, 157, 0.3);
  border: 1px solid #e7e7e7;
  z-index: 1000;

  .listItem {
    padding: 15px 20px;
    // border-bottom: 1px solid #e7e7e7;
    font-family: Avenir;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #4a4a4a;
  }

  .listItemBorder {
    border-bottom: 1px solid #e7e7e7;

  }
}