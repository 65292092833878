@import 'styles/varibles.scss';

.side {
  display: flex;
  flex-direction: column;

  .text_regular {
    font-family: Avenir;
    font-size: 13.7px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: 0.4px;
    color: $black-87;
    margin-bottom: 0;
  }
}
.side_left {
  float: left;
}
.side_right {
  float: right;
}
