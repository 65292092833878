.container {
    padding: 20px 0px;

    .container_norefer {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        margin-top: 20px;

    }

}