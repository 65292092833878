@import 'utils/styles/styles.scss';
@import 'styles/varibles.scss';

$BorderColor: #dfe3e4;

.client-journey {
  &__headers {
    // border: 1px solid $BorderColor;
    box-shadow: 0 0px 4px 0 rgba(157, 157, 157, 0.3);
    position: sticky;
    top: 90px;
    z-index: 1;
  }
  &__content {
    padding: 0 55px;
  }
  @media screen and (max-width: $mobile-size) {
    &__headers {
      top: 54px;
    }

    &__content {
      padding: 16px 16px;
    }
  }
}
