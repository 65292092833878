@import '../../../../../utils/styles/styles.scss';

.container {
  width: 100%;

  .search_box_container {
    width: 100%;
  }

  .item_container {
    height: 500px;
    padding: 24px;
    overflow-y: auto;
    overflow-x: hidden;

    .list_items_container {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .list_item {
        width: 23%;

        .selected {
          border: 2px solid #215c73;
          border-radius: 4px;
        }

        .text {
          font-size: 14px;
          font-weight: 400;
          margin-top: 4px;
          font-family: $font-family-Avenir;
          text-align: center;
        }

        .title {
          font-size: 14px;
          font-weight: 800;
          font-family: $font-family-Avenir;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.87);
          text-align: center;
          margin: 0px;
          margin-top: 10px;
        }

        .email_subject {
          font-size: 14px;
          font-weight: 400;
          font-family: $font-family-Avenir;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.87);
          text-align: center;
          margin: 0px;
        }
      }
    }
  }
}
