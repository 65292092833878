.chat-message-input-upload-attachment {
  display: grid;

  align-items: center;
  justify-items: center;

  &__upload,
  &__progress {
    grid-column: 1;
    grid-row: 1;
  }
}
