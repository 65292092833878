.video-chat-toolbar-action {
  display: flex;
  flex-direction: column;
  align-items: center;

  color: white;

  &__label {
    margin: 0;

    font-size: 10px;
  }

  &-content {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;

    &__end-adornment {
      margin-left: -12px;

      z-index: 2;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
