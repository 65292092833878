.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  // margin-top: 10px;

  .actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .deleteBtn {
      height: 40px;
      width: 75px;
      padding: 7px 14px;
      border: 1px solid #e7e7e7;
      border-radius: 4px;
      background-color: #ffffff;
      font-family: Avenir;
      font-size: 14px;
      font-weight: 800;
      line-height: 20px;
      letter-spacing: -0.24px;
      color: #215c73;
      margin-left: 10px;
    }

    .filterBtn {
      height: 40px;
      width: 92px;
      padding: 7px 16px;
      border: 1px solid #e7e7e7;
      border-radius: 4px;
      background-color: #ffffff;
      font-family: Avenir;
      font-size: 14px;
      font-weight: 800;
      line-height: 20px;
      letter-spacing: -0.24px;
      color: #215c73;
    }
  }

  .tableContainer {
    width: 100%;
    margin-top: 10px;

    .no_campaign_view {
      display: flex;
      justify-content: center;
      margin-top: 50px;
    }
  }
}
