@import '~utils/styles/styles.scss';

.chats-list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  & &__firstControlblock {
    display: flex;
    align-items: center;
    width: 45%;

    @media screen and (max-width: $mobile-size) {
      width: 20%;
    }
  }

  & &__select {
    margin-left: 7px;

    @media screen and (max-width: $laptop) {
      margin-left: 0px;
      padding-left: 0px;
    }
  }

  & &__mark-as-read,
  & &__show-favorite {
    padding: 6px;
  }

  & &__show-favorite {
    color: black;
    @media screen and (max-width: $mobile-size) {
      width: 15px;
      height: 15px;
    }
  }

  & &__search-filter {
    width: 100%;
    max-width: 375px;
    margin-left: 6px;
    margin-top: 4px;
  }

  &-toolbar {
    width: 55%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media screen and (max-width: $mobile-size) {
      width: 80%;
    }

    &__separator {
      width: 1px;
      align-self: stretch;
      margin: 0 15px;
      background-color: $light-gray-color-5;

      @media screen and (max-width: $mobile-size) {
        margin: 0 10px;
      }
    }

    .MuiFormControl-root {
      width: 100%;
      max-width: 165px;

      @media screen and (max-width: $mobile-size) {
        max-width: 80px;
      }
    }
    .MuiInputBase-root {
      margin-top: 0px;

      @media screen and (max-width: $mobile-size) {
        font-size: 12px;
      }
    }
  }
}

.chats-list-header-wrapper {
  .search-filter-mobile {
    width: 100%;
    margin-top: 15px;
    .MuiInputBase-root {
      width: 100%;
      .MuiInputBase-input {
        @media screen and (max-width: $mobile-size) {
          font-size: 12px;
          margin-bottom: 5px;
        }
      }
      .MuiSvgIcon-root {
        @media screen and (max-width: $mobile-size) {
          width: 20px;
          height: 20px;
          margin-bottom: 5px;
          margin-left: 3px;
        }
      }
    }
  }
}
