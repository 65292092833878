.upcoming-contributions-list-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &__contributions {
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }
}
