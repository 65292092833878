.container {
  padding: 0px 0px;

  .container_norefer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    margin-top: 20px;

    .no_refrealls {
      font-family: Avenir;
      font-size: 16px;
      font-weight: 350;
      line-height: 24px;
      text-align: center;
      color: #000000de;
    }

    .welcome_button {
      font-family: 'Avenir' !important;
      font-size: 14px !important;
      font-weight: 800 !important;
      line-height: 24.5px !important;
      text-align: center !important;
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
      align-items: center !important;
      padding: 6px 16px 6px 16px !important;
      height: 48px !important;
    }
  }
}
