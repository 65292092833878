@import '../../../../../utils/styles/styles.scss';
$mobile: 576px;

.form_body {
    display: flex;
    justify-content: center;
    align-items: center;

    .form_container {
        text-align: center;
        flex-basis: 846px;
        padding: 20px;

        p {
            margin-bottom: 0px;
        }

        .title_class {
            font-size: 22px;
            font-weight: 400;
            font-family: $font-family-Avenir;
            margin-bottom: 40px;
        }

        .cards_container {
            display: flex;
            gap: 18px;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            .scratch_card {
                flex-basis: 390px;
                border-radius: 4px;
                border: 1px solid $grey-tint;
                padding: 40px 45px;
                height: 260px;
                cursor: pointer;

                .heading {
                    font-size: 18px;
                    font-family: $font-family-Avenir;
                    font-weight: 800;
                    margin-bottom: 18px;
                    margin-top: 15px;
                }

                .text {
                    font-size: 14px;
                    font-family: $font-family-Avenir;
                    text-align: center;

                    @media screen and (max-width: $mobile) {
                        font-size: 11px;
                    }
                }

                img {
                    width: 42px;
                    height: 36px;
                    margin-bottom: 18px;
                }
            }

            .scratch_card_quiz {
                flex-basis: 390px;
                border-radius: 4px;
                border: 1px solid $grey-tint;
                padding: 40px 45px;
                height: 240px;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .heading {
                    font-size: 18px;
                    font-family: $font-family-Avenir;
                    font-weight: 800;
                    margin-bottom: 18px;
                    margin-top: 18px;
                }

                .text {
                    font-size: 14px;
                    font-family: $font-family-Avenir;
                    text-align: center;

                    @media screen and (max-width: $mobile) {
                        font-size: 11px;
                    }
                }

                img {
                    width: 42px;
                    height: 36px;
                    margin-bottom: 18px;
                }
            }

            .pre_design_card {
                flex-basis: 390px;
                border-radius: 4px;
                border: 1px solid $grey-tint;
                padding: 0px 42px;
                height: 240px;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: relative;
                gap: 15px;

                .comingsoon_badge {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    font-size: 14px;
                    font-style: italic;
                    font-family: $font-family-Avenir;
                    font-weight: 600;
                    background-color: #215c73;
                    color: white;
                    padding: 2px 6px;
                    border-radius: 4px;
                }

                .heading {
                    font-size: 18px;
                    font-family: $font-family-Avenir;
                    font-weight: 800;
                    // margin-bottom: 18px;
                }

                .text {
                    font-size: 14px;
                    font-family: $font-family-Avenir;
                    text-align: center;

                    @media screen and (max-width: $mobile) {
                        font-size: 11px;
                    }
                }

                img {
                    width: 42px;
                    height: 36px;
                    margin-bottom: 18px;
                }
            }

            .pre_design_card_quiz {
                flex-basis: 390px;
                border-radius: 4px;
                border: 1px solid $grey-tint;
                padding: 40px 45px;
                height: 240px;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: relative;

                .comingsoon_badge {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    font-size: 14px;
                    font-style: italic;
                    font-family: $font-family-Avenir;
                    font-weight: 600;
                    background-color: #215c73;
                    color: white;
                    padding: 2px 6px;
                    border-radius: 4px;
                }

                .heading {
                    font-size: 18px;
                    font-family: $font-family-Avenir;
                    font-weight: 800;
                    margin-bottom: 18px;
                    margin-top: 18px;
                }

                .text {
                    font-size: 14px;
                    font-family: $font-family-Avenir;
                    text-align: center;

                    @media screen and (max-width: $mobile) {
                        font-size: 11px;
                    }
                }

                img {
                    width: 42px;
                    height: 36px;
                    margin-bottom: 18px;
                }
            }

            .pre_design_card_disabled {
                flex-basis: 390px;
                border-radius: 4px;
                border: 1px solid $grey-tint;
                padding: 40px 45px;
                height: 260px;
                // cursor: pointer;
                position: relative;

                .comingsoon_badge {
                    position: absolute;
                    top: 8px;
                    right: 8px;
                    font-size: 14px;
                    font-style: italic;
                    font-family: $font-family-Avenir;
                    font-weight: 600;
                    background-color: #215c73;
                    color: white;
                    padding: 2px 6px;
                    border-radius: 4px;

                }

                .heading {
                    font-size: 18px;
                    font-family: $font-family-Avenir;
                    font-weight: 600;
                    margin-bottom: 18px;
                    opacity: 0.3;
                    margin-top: 15px;
                }

                .text {
                    font-size: 14px;
                    font-family: $font-family-Avenir;
                    text-align: center;
                    opacity: 0.3;

                    @media screen and (max-width: $mobile) {
                        font-size: 11px;
                    }
                }

                img {
                    width: 42px;
                    height: 36px;
                    margin-bottom: 18px;

                }
            }


            .selected {
                border: 2px solid #215c73;
                border-style: solid;
            }
        }
    }
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    height: 100px;

    .segment_name {
        width: 100%;
    }

    .field {
        width: 100%;

        .textfield {
            width: 100%;
        }

    }

}