@import 'utils/styles/styles.scss';

.banners-client {
  margin-bottom: 24px;

  & > div {
    margin-bottom: 5px;
  }
}

.banner-container {
  @media screen and (max-width: $mobile-size) {
    &__text {
      font-size: 14px;
    }
  }
}
