@import '../../../../utils/styles/styles.scss';

.container {}

.preview_conatiner {
  border: 2px solid $grey-background;
  width: 100%;
  box-shadow: 0px 8px 8px 0px $grey-background;

  .preview_btns {
    display: flex;
    gap: 8px;
    justify-content: end;
    padding: 10px 20px;
    width: 100%;
    border-bottom: 2px solid $grey-background;
    text-align: end;

    .image {
      cursor: pointer;
    }
  }

  .preview_body {
    display: flex;
    justify-content: center;

    .responsive_preview {
      width: 420px;
      padding: 20px;
      border-left: 1px solid #e7e7e7;
      border-right: 1px solid #e7e7e7;

      @media screen and (max-width: $mobile-size) {
        padding: 0px;
      }
    }

    .responsive_desktop_preview {
      padding: 20px;
    }
  }
}