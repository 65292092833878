@font-face {
  font-family: 'Nexa';
  src: url('../assets/fonts/Nexa-Bold.otf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa';
  src: url('../assets/fonts/Nexa-Light.otf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../assets/fonts/Avenir-Roman.eot');
  src: local('Avenir Roman'), local('Avenir-Roman'),
    url('../assets/fonts/Avenir-Roman.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Avenir-Roman.woff2') format('woff2'), url('../assets/fonts/Avenir-Roman.woff') format('woff'),
    url('../assets/fonts/Avenir-Roman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../assets/fonts/Avenir-Light.eot');
  src: local('Avenir Light'), local('Avenir-Light'),
    url('../assets/fonts/Avenir-Light.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Avenir-Light.woff2') format('woff2'), url('../assets/fonts/Avenir-Light.woff') format('woff'),
    url('../assets/fonts/Avenir-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Book';
  src: url('../assets/fonts/Avenir-BookOblique.eot');
  src: local('Avenir Book Oblique'), local('Avenir-BookOblique'),
    url('../assets/fonts/Avenir-BookOblique.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Avenir-BookOblique.woff2') format('woff2'),
    url('../assets/fonts/Avenir-BookOblique.woff') format('woff'),
    url('../assets/fonts/Avenir-BookOblique.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Black Oblique';
  src: url('../assets/fonts/Avenir-BlackOblique.eot');
  src: local('Avenir Black Oblique'), local('Avenir-BlackOblique'),
    url('../assets/fonts/Avenir-BlackOblique.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Avenir-BlackOblique.woff2') format('woff2'),
    url('../assets/fonts/Avenir-BlackOblique.woff') format('woff'),
    url('../assets/fonts/Avenir-BlackOblique.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir';
  src: url('../assets/fonts/Avenir-Heavy.eot');
  src: local('Avenir Heavy'), local('Avenir-Heavy'),
    url('../assets/fonts/Avenir-Heavy.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Avenir-Heavy.woff2') format('woff2'), url('../assets/fonts/Avenir-Heavy.woff') format('woff'),
    url('../assets/fonts/Avenir-Heavy.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../assets/fonts/Avenir-HeavyOblique.eot');
  src: local('Avenir Heavy Oblique'), local('Avenir-HeavyOblique'),
    url('../assets/fonts/Avenir-HeavyOblique.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Avenir-HeavyOblique.woff2') format('woff2'),
    url('../assets/fonts/Avenir-HeavyOblique.woff') format('woff'),
    url('../assets/fonts/Avenir-HeavyOblique.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir';
  src: url('../assets/fonts/Avenir-MediumOblique.eot');
  src: local('Avenir Medium Oblique'), local('Avenir-MediumOblique'),
    url('../assets/fonts/Avenir-MediumOblique.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Avenir-MediumOblique.woff2') format('woff2'),
    url('../assets/fonts/Avenir-MediumOblique.woff') format('woff'),
    url('../assets/fonts/Avenir-MediumOblique.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir';
  src: url('../assets/fonts/Avenir-LightOblique.eot');
  src: local('Avenir Light Oblique'), local('Avenir-LightOblique'),
    url('../assets/fonts/Avenir-LightOblique.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Avenir-LightOblique.woff2') format('woff2'),
    url('../assets/fonts/Avenir-LightOblique.woff') format('woff'),
    url('../assets/fonts/Avenir-LightOblique.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Book';
  src: url('../assets/fonts/Avenir-Book.eot');
  src: local('Avenir Book'), local('Avenir-Book'),
    url('../assets/fonts/Avenir-Book.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Avenir-Book.woff2') format('woff2'), url('../assets/fonts/Avenir-Book.woff') format('woff'),
    url('../assets/fonts/Avenir-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../assets/fonts/Avenir-Black.eot');
  src: local('Avenir Black'), local('Avenir-Black'),
    url('../assets/fonts/Avenir-Black.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Avenir-Black.woff2') format('woff2'), url('../assets/fonts/Avenir-Black.woff') format('woff'),
    url('../assets/fonts/Avenir-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../assets/fonts/Avenir-Oblique.eot');
  src: local('Avenir Oblique'), local('Avenir-Oblique'),
    url('../assets/fonts/Avenir-Oblique.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Avenir-Oblique.woff2') format('woff2'),
    url('../assets/fonts/Avenir-Oblique.woff') format('woff'),
    url('../assets/fonts/Avenir-Oblique.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir';
  src: url('../assets/fonts/Avenir-Medium.eot');
  src: local('Avenir Medium'), local('Avenir-Medium'),
    url('../assets/fonts/Avenir-Medium.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Avenir-Medium.woff2') format('woff2'), url('../assets/fonts/Avenir-Medium.woff') format('woff'),
    url('../assets/fonts/Avenir-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter-Bold.ttf');
  src: local('Inter Bold'), local('Inter-Bold'),
  url('../assets/fonts/Inter-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon Text';
  src: url('../assets/fonts/BrandonText-Regular.ttf');
  src: local('Brandon Regular'), local('BrandonText Regular'),
    url('../assets/fonts/BrandonText-Regular.ttf') format('truetype');
  // font-weight: bold;
  font-style: normal;
}
