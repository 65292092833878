.copylink_btn {
  width: 160px;
  padding: 11px 16px;
  border-radius: 4px;
  font-family: Avenir !important;
  font-size: 14px !important;
  font-weight: 900 !important;
  line-height: 16px !important;
  display: flex;
  align-items: center;
  gap: 5px;
}