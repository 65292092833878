.content_container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  background-color: #f5f5f5;
  border-radius: 0.25rem;
  padding: 30px;

  .cross_icon {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
  }

  .file_name {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 800;
    line-height: 16px;
    text-decoration: underline;
  }
}
