.container {
  width: 100%;

  .input_underline {
  }

  .input_disabled {
    &::before {
      border-bottom-style: solid !important;
    }
  }
}
