@import '~utils/styles/styles.scss';

.custom-enrollment-questions {
  // margin-top: 24px;

  .drag-icon {
    color: #b2cae0;
    cursor: grab;
  }

  .question {
    align-items: center;
    width: 100%;

    .question-title {
      display: block;
      margin-bottom: 16px;
      @media screen and (max-width: 576px) {
        margin-bottom: 0px;
      }
    }

    .question-container {
      margin-bottom: 16px;

      .MuiGrid-item {
        padding: 5px;
      }

      .MuiInputBase-root {
        .MuiSelect-root {
          padding-top: 16px;
          padding-bottom: 16px;
        }
      }
    }

    .add-option {
      display: flex;
      width: fit-content;
      align-items: center;
      background-color: #dfe3e4;
      text-transform: unset;
      font-size: 14px;
      font-weight: 500;
      gap: 10px;

      @media screen and (max-width: 576px) {
        font-size: 12px;
        padding: 8px;
      }
    }

    .options-container {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .option-container {
        .MuiIconButton-root {
          &:first-child {
            margin-right: 10px;
          }

          &:last-child {
            margin-left: 10px;
            height: 100%;
          }
        }

        .MuiIconButton-root {
          padding: 0px;
        }

        .MuiOutlinedInput-input {
          padding: 10px !important;
        }
      }
    }

    .question-buttons-container {
      &.margin-top {
        margin-top: 16px;
      }
    }
  }

  .question-error-container {
    padding: 0 8px;

    .MuiIconButton-root {
      &:first-child {
        padding-left: 0;
      }
    }
  }

  .question-error {
    color: red;
  }

  .save-question {
    display: flex;
    align-items: center;
    margin-top: 16px;
    text-transform: unset;
    color: #ffffff;
    background-color: #d1b989;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    padding-left: 0px;
    padding-right: 10px;
    font-weight: 800;

    &:hover {
      background-color: #d1b989;
    }

    svg {
      margin-right: 2px;
      width: 24px;
      height: 24px;
      margin-bottom: 2px;
    }
  }

  .add-question-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;

    @media screen and (max-width: 576px) {
      justify-content: flex-end;
    }

    .add-question {
      display: flex;
      align-items: center;
      margin-top: 30px;
      text-transform: unset;
      color: #ffffff;
      background-color: #d1b989;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      padding-left: 0px;
      padding-right: 10px;
      font-weight: 800;

      &:hover {
        background-color: #d1b989;
      }

      svg {
        margin-right: 2px;
        width: 24px;
        height: 24px;
        margin-bottom: 2px;
      }
    }
    .add-question-disabled {
      display: flex;
      align-items: center;
      margin-top: 30px;
      text-transform: unset;
      color: #9b9b9b;
      background-color: #ededed;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      padding-left: 0px;
      padding-right: 10px;
      font-weight: 800;

      &:hover {
        background-color: #ededed;
      }

      svg {
        margin-right: 2px;
        width: 24px;
        height: 24px;
        margin-bottom: 2px;
      }
    }
  }

  .optional-option {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;

    @media screen and (max-width: 992px) {
      justify-content: flex-start;
    }

    label {
      margin-bottom: 0px;
    }

    p {
      margin-bottom: 0px;
    }
  }
}
