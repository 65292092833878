.client-journey-upcoming-contributions-list {
  & &__title {
    color: rgba(0, 0, 0, 0.87);

    margin: 0 0 16px 0;

    font-size: 18.3px;
    font-weight: 500;
    font-family: 'Avenir';
  }
}
