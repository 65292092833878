.calendar-container {
  margin-top: 24px;

  .custom-calendar {
    width: 100%;
    overflow-x: auto;

    .rbc-toolbar {
      position: relative;
      display: flex;
      justify-content: space-between;

      &-label {
        position: absolute;
        left: 220px;
      }
    }

    .rbc-month-view {
      height: 500px;
    }

    .rbc-time-view {
      overflow-x: scroll;
    }

    @media screen and (max-width: 1200px) {
      .rbc-time-view {
        width: 90vw;
      }

      .rbc-toolbar {
        flex-direction: column;

        &-label {
          position: static;
          margin: 5px 0;
        }
      }
    }

    &.dark-theme {
      .rbc-month-view {
        border-color: #696969;

        .rbc-header,
        .rbc-day-bg,
        .rbc-month-row {
          border-color: #696969;
        }
      }

      .rbc-row-content {
        .rbc-off-range {
          color: white;
        }
      }

      .rbc-off-range-bg {
        background: rgba(221, 221, 221, 0.1);
      }

      .rbc-today {
        background: rgba(221, 221, 221, 0.2);
      }

      .rbc-btn-group {
        button {
          color: white;

          &:hover {
            color: black;
          }
        }
      }
    }
  }
}
.schedule-meeting-form-modal {
  position: relative;
  .MuiAutocomplete-popupIndicator {
    height: 20px;
    width: 20px;
  }
  .MuiAutocomplete-clearIndicator {
    display: none;
  }
  .css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator:hover {
    background-color: white;
  }
  .css-113ntv0-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator:hover {
    background-color: white;
  }

  .MuiInput-underline.Mui-disabled:before {
    border-bottom-style: solid !important;
    border-color: #979797 !important;
  }

  .WithStyles\(ForwardRef\(Input\)\)-underline-26:before {
    border-color: #979797 !important;
  }

  .WAMuiChipInput-underline-42.WAMuiChipInput-disabled-41:before {
    border-bottom-style: solid !important;
    border-color: #979797 !important;
  }

  width: 100% !important;
  max-width: 100% !important;
  min-width: 80% !important;

  .MuiGrid-grid-md-6 {
    padding: 0 6px !important;
  }

  .radio-icon-style {
    .MuiButtonBase-root {
      height: 30px !important;
    }
  }

  .select-input-styling {
    margin-left: 20px !important;
  }

  .WAMuiChipInput-chipContainer-32 {
    .MuiButtonBase-root {
      height: auto !important;
      width: auto !important;
    }
  }
}

@media (max-width: 750px) {
  .schedule-meeting-form-modal {
    width: 100% !important;
  }
}

.coach-empty-slots-button {
  cursor: pointer !important;
  background: white;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
  height: 40px !important;
  border-radius: 4px !important;
  margin: 5px !important;
}

.active-time-slot {
  border-color: #cebb90 !important;
  color: #cebb90 !important;
}

.regular-time-slot {
  border-color: rgb(223, 223, 223) !important;
  color: black;
}

.email-padding {
  .WAMuiChipInput-underline-43.WAMuiChipInput-disabled-42:before {
    border-bottom-style: solid;
  }

  .fxcGeq {
    margin-left: 0px !important;
    max-width: 100% !important;
  }

  .MuiFormLabel-root.Mui-focused {
    color: black !important;

    .MuiInputLabel-formControl {
      color: black !important;
    }
  }
}

.select-input-styling {
  .MuiSelect-select.MuiSelect-select {
    white-space: break-spaces;
  }
}

.AutoSelectField {
  svg {
    height: 30px;
    margin-right: 10px;
  }
}

// .MuiPopover-paper {
//   margin-top: 5px !important;
// }
.dark-theme-enabled {
  .MuiPaper-root {
    background-color: #696969 !important;
  }
}

.contributionLabelText > div {
  font-family: Avenir;
}

.contributionLabelText {
  ::before {
    content: none !important;
  }
  ::after {
    content: none !important;
  }
}

.price-select-popup-dark > .MuiInput-formControl > svg {
  color: 'white';
}

.cohere-dark-mode {
  .easy-booking-header {
    color: black;
    background-color: #3f95b6;
  }

  .easy-booking-container {
    background-color: #2d2f31;
    box-shadow: 0px 0px !important;

    .user-details-side {
      background-color: #2d2f31 !important;
      border-right: 1px solid #696969 !important;

      &.mobile-view {
        border-right: 0px !important;
      }

      .user-details {
        p {
          color: #3f95b6;
        }
      }

      .duration-details {
        color: white;
      }

      .contribution-title {
        color: white;
      }

      .selected-date-time {
        color: white;
      }
    }

    .calendar-side {
      background-color: #2d2f31 !important;

      .one-one-session-card {
        background-color: #2d2f31 !important;

        .calendar-container-custom {
          .custom-calendar {
            background-color: #2d2f31 !important;
          }
        }

        .price-container {
          background-color: #2d2f31 !important;
        }
      }

      .one-one-session-slots-card {
        background-color: #2d2f31 !important;

        .easy-booking-slots {
          border: 1px solid #f5f5f550;
        }

        .easy-booking-timezone {
          .easy-booking-timezone-select {
            border: 1px solid #2d2f31;
            background-color: #2d2f31 !important;

            select {
              color: white;
              opacity: 0.8;
            }
          }

          > div {
            p {
              color: #3f95b6;
            }
          }
        }
        .no-slot-selected {
          color: white;
        }
      }
    }
  }
}

.easy-booking-container {
  max-width: 100%;
  box-shadow: 0px 0px !important;
  display: flex;
  width: fit-content;
  padding: 2px;
  margin: auto;
  height: 100%;
  margin-bottom: 10px;

  &.no-active-contribution {
    width: 100% !important;
  }

  .user-details-side {
    background-color: white;
    width: 323px;
    margin-right: 2px;
    padding: 5px 20px;
    border-right: 1px solid #f5f5f5 !important;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;

    &.mobile-view {
      border-right: 0px !important;
    }

    &.no-active-contribution {
      padding: 0;
      margin-right: 0;
      border-right-width: 0 !important;
      padding: 0px !important;
    }

    .easy-booking-purpose {
      margin-top: 15px;
    }

    .user-details {
      display: flex;

      > div {
        margin: auto 0;
        width: 30px;
        height: 30px;
      }

      p {
        margin: auto auto auto 10px;
        color: var(--cohere-primary-blue, #215c73);
        font-family: Avenir;
        font-size: 14px;
        font-style: normal;
        font-weight: 850;
        line-height: normal;
      }
    }

    .duration-details {
      margin-top: 10px;
      color: #000;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Avenir;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .contribution-title {
      margin-top: 0px;
      color: #000;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Avenir;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .selected-date-time {
      display: flex;
      margin-top: 10px;
      font-family: Avenir;
      font-size: 16px;
      font-weight: 850;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;

      p {
        margin: auto 0 auto 10px;

        &.selected-date {
          margin-right: 10px;
        }
      }

      svg {
        margin: auto 0;
        width: 16px !important;
      }
    }
  }

  .calendar-side {
    width: 448px;
    background-color: white;
    padding: 5px 10px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;

    .single-session-back-button {
      position: absolute;
      margin-top: 2px !important;

      &.free-booking {
        display: block !important;
        margin-top: 0 !important;
      }

      > div {
        margin-top: 0 !important;
      }
    }

    .one-one-session-slots-card {
      position: relative;
      background-color: white !important;

      .easy-booking-slots {
        border: 1px solid #f5f5f5;
        border-radius: 5px;
      }

      .easy-booking-timezone {
        max-height: 50vh;
        overflow: auto;
        .easy-booking-timezone-select {
          border-radius: 2px;
          border: 1px solid white;
          box-shadow: 0px 4px 17px 0px #0000000a;

          select {
            color: #696969;
          }
        }

        > div {
          width: 100% !important;

          p {
            color: #215c73;
            margin-bottom: 5px;
          }
        }
      }

      .easy-booking-slot-buttons {
        margin-top: 20px !important;
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 10px;

        button {
          font-family: Avenir;
          font-size: 16px;
          font-weight: 800;
          line-height: 22px;
          text-align: center;
          position: relative;
          width: 50%;
          height: 48px;
          padding: 10px 24px 10px 24px;
          gap: 20px;
          border-radius: 5px;

          :disabled {
            background-color: #ededed;
            color: #9b9b9b;
            fill: #9b9b9b;
            cursor: not-allowed;
            border: none;
          }
        }
      }

      .single-session-back-button {
        > div {
          > b {
            display: none;
          }

          .back-for-easy-booking {
            display: block;
          }
        }
      }

      .easy-booking-session-time-title {
        font-family: Avenir;
        font-size: 20px;
        font-weight: 850;
        line-height: 32px;
        letter-spacing: 0em;
        color: #215c73;
      }

      .no-slot-selected {
        text-align: center;
        display: block;
      }

      .slot-selection-description {
        margin-bottom: 20px;
        margin-top: 12px;

        > p {
          display: none;
        }
      }
    }

    .one-one-session-card {
      background-color: white !important;
      box-shadow: none;

      .session-date-time {
        display: none;
      }

      .price-container {
        background-color: white !important;
      }

      .one-one-session-header {
        display: none !important;
      }
    }

    .session-details {
      margin-bottom: 20px;

      .session-price-title {
        width: fit-content;
        background-color: #c9b382;
        color: white;
        width: 110px;
        height: 26.75px;
        padding: 4px 8px;
        border-radius: 4px;
        margin: auto;
        font-family: Avenir;
        font-size: 16px;
        font-weight: 350;
        line-height: 20px;
        letter-spacing: 0em;
      }

      .total-price {
        font-family: Avenir;
        font-size: 32px;
        font-weight: 900;
        line-height: 54px;
        letter-spacing: 0em;
        text-align: left;
        margin: 5px auto;
        width: fit-content;

        span {
          margin-left: 5px;
          font-weight: 300;
        }
      }

      .processing-fee,
      .due-now {
        width: 288px;
        display: flex;
        margin: auto;

        .payment-title {
          font-family: Avenir;
          font-size: 16px;
          font-weight: 350;
          line-height: 34px;
          letter-spacing: 0em;
          text-align: left;
        }

        .payment-amount {
          font-family: Avenir;
          font-size: 20px;
          font-weight: 800;
          line-height: 32px;
          letter-spacing: 0em;
          text-align: left;
          margin-left: auto;
        }

        .payment-currency {
          font-family: Avenir;
          font-size: 20px;
          font-weight: 300;
          line-height: 32px;
          letter-spacing: 0em;
          text-align: left;
          margin-left: 5px;
        }
      }
    }

    .booking-details {
      padding: 0 10px 0 28px;

      p {
        margin-bottom: 0;
        color: var(--cohere-primary-blue, #215c73);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Avenir;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    .toolbar-container {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      height: 0px;
      margin-bottom: 67px;
      padding: 0px 10px 0px 5px;
    }

    .btn-back-container {
      margin: auto 0 auto auto;

      .btn-back {
        border: none;
        padding: 10px;
        margin: 3px;
        font-size: 30px;
        color: gray;

        &.enabled-arrow {
          color: black;
        }
      }
    }

    .btn-current {
      border: none;
      padding: 8px 2px;
      color: #282b2b;
      font-family: Avenir;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .btn-next {
      border: none;
      padding: 10px 8px;
      margin: 3px;
      font-size: 30px;
      color: gray;

      &.enabled-arrow {
        color: black;
      }
    }

    .label-date {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .easy-booking-slots-container {
      .slot-header {
        display: flex;
        font-family: Avenir;
        font-size: 20px;
        font-weight: 850;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        color: #215c73;

        p {
          margin: auto 0 auto 10px;
        }

        span {
          cursor: pointer;

          svg {
            margin-bottom: 5px;
          }
        }
      }

      .time-zone-selection-container {
        p {
          color: var(--cohere-primary-blue, #215c73);
          font-family: Avenir;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        .time-zone-selection-dropdown {
          > div {
            width: 100%;
            border: 1px solid;
            border-radius: 5px;
            border-color: rgba(0, 0, 0, 0.04);
          }
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .easy-booking-header {
    width: 100%;
    max-width: 365px;
    margin-top: 16px;
  }

  .easy-booking-container {
    box-shadow: 0px 0px !important;
    display: block;
    height: 100%;

    .user-details-side {
      .easy-booking-purpose {
        display: none;
      }

      width: unset;
      margin-right: 0;
      text-align: center;
      margin-bottom: 2px;

      .selected-date-time {
        justify-content: center;
      }

      .user-details {
        > div {
          margin: auto 0 auto auto;
        }
      }
    }

    .calendar-side {
      width: unset;
      min-width: 365px;
      text-align: center;
      padding: 0;

      .session-details {
        padding: 20px 0;
        border-bottom: 2px solid #f5f5f5;
      }

      .easy-booking-slots-container {
        max-width: 365px;
        padding: 10px;

        .slot-header {
          justify-content: center;
          margin-bottom: 20px;
        }

        .time-zone-selection-container {
          padding: 20px;

          p {
            text-align: left;
          }
        }
      }

      .booking-details {
      }
    }
  }
}

.booking-title {
  text-align: left !important;
  font-size: 20px;
  font-family: Avenir;
  font-weight: 800;
  word-wrap: break-word;
}

.calendar-container-custom {
  margin-top: 0px;
  padding: 0px 0px 16px 0px;
  font-family: 'Brandon Text';
  justify-content: center;
  font-style: normal;
  font-size: 17px;
  font-weight: 600;
  max-width: 500px;

  &.cohere-dark-mode {
    .custom-calendar {
      background-color: #252728;

      .rbc-month-view {
        height: 100% !important;
        .rbc-header {
          color: white;
        }

        .rbc-month-row {
          .rbc-row-content {
            .rbc-row {
              color: white;

              .rbc-now {
                color: black;
              }
            }
          }
        }
      }
    }
  }

  .custom-calendar {
    width: 100%;
    overflow-x: auto;
    background-color: white;
    border-radius: 25px;
    padding: 0;
    padding-top: 0px;
    box-shadow: none;

    .rbc-toolbar {
      position: relative;
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;

      &-label {
        position: absolute;
        left: -9px;
        font-size: 50px;
        font-family: 'Brandon Text';
        font-style: normal;
        font-weight: 700;
      }
    }

    .rbc-month-view {
      height: 100% !important;
      border: none;
      font-family: Avenir;

      .rbc-header {
        border-bottom: none;
        color: black;
        text-align: center;
        font-family: Avenir;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 28.333px;
        /* 141.667% */
      }

      .rbc-header + .rbc-header {
        border-left: none;
      }

      .rbc-month-row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-basis: unset;
        height: 50px !important;

        .rbc-row-content {
          .rbc-row {
            .rbc-now.rbc-current {
              color: white;
            }


            .selected-date-container {
              cursor: pointer;
              position: relative;

              .selected-date {
                position: absolute;
                bottom: 0px;
                left: 17px;
                font-weight: 400;
                font-size: 17px;
                color: white;

                @media screen and (max-width: 750) {
                  left: 13px;
                }
              }
            }
          }
        }

        .rbc-row-bg {
          justify-content: space-around;
          margin-left: 2px;

          .rbc-day-bg {
            margin-top: 9px !important;
          }
        }
      }

      .rbc-month-row + .rbc-month-row {
        border-top: none;
      }

      .rbc-date-cell {
        text-align: center;
        padding-right: 0px;
        font-weight: 400;
      }
    }

    .rbc-time-view {
      overflow-x: scroll;
    }

    @media screen and (max-width: 1200px) {
      .rbc-time-view {
        width: 90vw;
      }

      .rbc-toolbar {
        flex-direction: column;

        &-label {
          position: static;
          margin: 5px 0;
        }
      }
    }
  }
}
