@import '~utils/styles/styles.scss';

.chats-list-chat {
  display: grid;
  grid-template-columns: 8px 12px 250px 40px 1fr;
  align-items: center;

  @media screen and (max-width: $mobile-size) {
    grid-template-columns: 0px 0px 120px 0px 1fr;
  }

  &--active {
    background-color: $light-gray-color-4;

    .chats-list-chat__read-indicator {
      opacity: 1;
    }
  }

  &__read-indicator {
    opacity: 0;

    width: 6px;
    height: 6px;

    border: 1px solid $dark-ocean-blue-color-2;
    border-radius: 50%;

    &--unread {
      opacity: 1;

      background-color: $dark-ocean-blue-color-2;
    }
  }

  &__general-info {
    grid-column: 3;
  }

  &__content {
    grid-column: 5;
  }

  &-content {
    display: grid;
    grid-template-columns: 1fr max-content 70px max-content;
    grid-column-gap: 10px;
    align-items: center;

    @media screen and (max-width: $mobile-size) {
      grid-column-gap: 5px;
      grid-template-columns: 1fr max-content max-content max-content;
    }

    & &__last-message {
      &--type {
        &--media {
          color: $dark-ocean-blue-color-2;

          font-style: italic;
        }
      }
    }

    &__last-message,
    &__typing-members,
    &__last-message-timestamp {
      margin: 0;

      font-weight: 300;
    }

    &__last-message,
    &__typing-members {
      overflow: hidden;

      white-space: nowrap;

      text-overflow: ellipsis;

      font-size: 15.8px;
      @media screen and (max-width: $mobile-size) {
        font-size: 12px;
        min-width: 30px;
        margin-top: 2px;
      }
    }

    & &__favorite {
      grid-column: 2;

      padding: 6px;
      margin: -6px;

      outline: none;

      color: $gray-color-3;

      &--favorite {
        color: $yellow-color;
      }
    }

    &__last-message-timestamp {
      grid-column: 3;

      font-size: 13.72px;
      @media screen and (max-width: $mobile-size) {
        font-size: 12px;
      }
    }

    &__last-message,
    &__last-message-timestamp {
      color: rgba(0, 0, 0, 0.87);
    }

    &__typing-members {
      color: $gray-color;
    }

    & &__more-options {
      grid-column: 4;

      padding: 6px;
      margin: -6px;

      outline: none;
    }
  }
}
