$video-chat-participant-background-color: #222222;

.video-chat-participant {
  position: relative;

  display: flex;
  align-items: stretch;
  justify-content: center;

  background-color: $video-chat-participant-background-color;

  &__identity {
    overflow: hidden;

    margin: 0;

    white-space: nowrap;
    text-overflow: ellipsis;

    color: white;
  }

  &__no-video-identity,
  &__video,
  &__toolbar {
    grid-column: 1;
    grid-row: 1;
  }

  &__no-video-identity {
    align-self: center;

    max-width: 92%;

    justify-self: center;
  }

  &__video {
    position: absolute;

    width: 100%;
    height: 100%;

    &--type {
      &--camera {
        object-fit: contain;
      }

      &--screen {
        object-fit: contain;
      }
    }

    &--mirrored {
      transform: scaleX(-1);
    }
  }

  &__toolbar {
    z-index: 2;

    align-self: end;

    justify-self: start;
  }

  &-toolbar {
    position: absolute;
    bottom: 0;
    left: 0;

    display: flex;
    align-items: center;

    padding: 1px 3px;

    background-color: #{$video-chat-participant-background-color}9d;

    &:empty {
      display: none;
    }

    & &__muted-mic {
      font-size: 1rem;
    }

    &__identity {
      align-self: center;

      max-width: 140px;

      font-size: 0.75rem;

      justify-self: center;
    }
  }

  &--dominant {
    outline: 2px solid #628db3;
  }
}
