$mobile: 576px;

.notSupportedLink {
  background-color: black;
  height: 500px;
  width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  @media (max-width: $mobile) {
    height: 150px;
    width: 300px;
  }

  .text {
    color: white;
  }
}
