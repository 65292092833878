.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;

  .segment_name {
    width: 100%;
  }

  .segment_groups {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .addNewSgementGroupBtn_container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    .addSegmentGroupBtn {
      height: 40px;
      padding: 0px 15px;
      border: 1px solid #c9b382;
      border-radius: 4px;
      color: #c9b382;
      background-color: white;
      font-family: Avenir;
      font-size: 14px;
      font-weight: 900;
      line-height: 16px;
      letter-spacing: 1.25px;
    }
  }
}
