@import '~utils/styles/styles.scss';

.session-attachments {
  .h4 {
    @media screen and (max-width: $mobile-size) {
      font-size: 14px;
    }
  }
  .notes {
    margin-left: 40px;
    cursor: pointer;

    @media screen and (max-width: $mobile-size) {
      font-size: 14px;
    }
  }
  .attached-file-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 15px;

    .download-attach {
      outline: 0;
    }
  }

  .attach-button {
    right: 0;
    bottom: -10px;
  }

  .session-empty-panel-content {
    font-style: italic;
    font-size: 13.7px;
    color: darkgray;
  }
}
