.btn-primary {
  height: 36px;
  text-align: center;
  font-family: Avenir;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 800 !important;
  line-height: 16px !important;
  color: black !important;
  background: white !important;
}

.sub-section-conatiner {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.attachment-files {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
