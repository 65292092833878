@import '~utils/styles/styles.scss';

.rich-text-notes-editor {
  &.loading-editor {
    display: none;
  }

  .copy-and-share-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    margin-top: 12px;

    .share-via-email {
      cursor: pointer;
      background-color: #efefef;
      padding: 8px 10px;
      border-radius: 2px;
      color: black;
      font-size: 14px;
      width: fit-content;

      svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }

    .copy-to-clipboard {
      cursor: pointer;
      background-color: #efefef;
      padding: 8px 10px;
      border-radius: 2px;
      color: black;
      font-size: 14px;
      width: fit-content;

      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }

    .cke_bottom {
      .cke_path {
        display: none;
      }
    }
  }
}

.notes-email-popup {
  .cohere-modal {
    width: 540px;

    .body {
      .emailInput {
        margin-left: 0px;
      }
    }
  }
}

.cke_dialog_body {
  .cke_dialog_tabs {
    .cke_dialog_tab {
      &[title='Upload'] {
        display: none;
      }
    }
  }
}
