.applications-table,
.application-response-model .cohere-modal {
  .approve-button {
    margin: 5px 10px 5px 0px;
    color: white;
    width: 98px;
    border: 1.4px solid #c9b382;
  }

  .reject-button {
    margin: 5px 10px 5px 0px;
    color: black;
    background-color: white;
    border-color: black;
    border: 1px solid;
    width: 98px;

    &:hover {
      background-color: white;
    }
  }

  .approved-button {
    pointer-events: none;
    color: #cdba8f;
    border-color: #cdba8f;
    background-color: white;
    border: 1px solid;
    width: 98px;
  }

  .rejected-button {
    pointer-events: none;
    border-color: black;
    background-color: white;
    border: 1px solid;
    width: 98px;
    color: lightgray;
  }

  .modal-footer-container {
    float: right;
    width: 100%;

    .detail-response-footer-container {
      width: 100%;

      button {
        float: right;
      }

      .detail-response-footer-buttons {
        // height: 60px;
        button {
          padding: 0.62rem 1.5rem;
        }
        .approve-button,
        .approved-button {
          display: flex;
          width: 96px;
          height: 48px;
          padding: 10px 24px;
          justify-content: center;
          align-items: center;
          gap: 20px;
          margin-left: 0px;
        }
        .reject-button,
        .rejected-button {
          display: flex;
          width: 96px;
          height: 48px;
          padding: 10px 24px;
          justify-content: center;
          align-items: center;
          gap: 20px;
          margin-left: 0px;
        }
        .approve-button,
        .reject-button {
          margin-left: unset;
        }
      }

      .detail-response-navigation-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    button {
      margin-left: 10px;
      border-radius: 5px;
      font-weight: 400;
    }
  }

  .modal-text {
    font-family: 'Avenir';
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
  }

  .card-radius {
    border-radius: 8px;
  }

  .client-name {
    color: #4a4a4a;
  }

  .link {
    text-decoration: underline;
  }

  .create-time {
    color: #7d9cb8;
  }

  .display-inline-block {
    display: inline-block;
  }
}

.application-form-detail {
  .chat-icon {
    padding: 0px;

    span {
      padding: 0;
    }
  }
}

.app-form-created-success {
  .cohere-modal {
    width: 500px;
    min-height: 175px;
  }
}
