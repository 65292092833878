$dark-ocean-blue-color: #116582;
$dark-ocean-blue-color-2: #235b72;
$light-gray-color: #eaeaea;
$light-gray-color-2: #e7e7e7;
$light-gray-color-3: #c8c8c8;
$light-gray-color-4: #f9f9f9;
$light-gray-color-5: #dadada;
$gray-color: #909090;
$gray-color-2: #9b9b9b;
$gray-color-3: #979797;
$dark-gray-color: #4d4d4d;
$yellow-color: #e2bb63;
$white: white;
$light-brown: #d1b989;
$font-family-Avenir: 'Avenir';
$bluish-grey-blue: #213649;
$grey-tint: #DFE3E4;
$secondary-btn-color: #c9b382;
$primary-btn-color: #215C73;
$grey-background: #F5F5F5;
$mobile-size-sm: 425px;
$mobile-size: 576px;
$tablet: 768px;
$laptop: 992px;
$desktop: 1200px;
$lg-desktop: 1600px;