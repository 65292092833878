.container {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #e7e7e7;
  background-color: #ffffff;
  position: sticky;
  top: 142px;
  z-index: 10;

  .tab_container {
    width: 50%;
    border-bottom: 3px solid transparent;
    display: flex;
    align-items: center;
    padding: 0px 15px;

    .tab_btn {
      background-color: transparent;
      display: flex;
      justify-content: center;
      font-size: 16px;
      font-weight: 900;
      line-height: 16px;
      letter-spacing: 0px;
      color: rgba(0, 0, 0, 0.87);
      min-width: auto;
      font-family: Avenir;
      align-items: center;
      gap: 5px;
      padding: 1rem 0px;
    }

    .active_tab_btn {
      color: #116582 !important;
    }
  }

  .active_tab_container {
    border-bottom: 3px solid #215c73 !important;
  }
}
