.custom-blockdaycalendar {
  // box-shadow: none !important;
  // width: 100%;
  .rmdp-top-class {
    width: 100%;
    .rmdp-calendar {
      width: 100%;
      padding: 0px;
      .rmdp-day-picker {
        width: 100%;
        padding: 5px 10px 5px 0px;
        .rmdp-week {
          //   .rmdp-week-day {
          //     color: red;
          //   }
        }
      }
      .rmdp-day-picker > div:first-child {
        width: 100%;
        .rmdp-week {
          .rmdp-day {
            width: 35px !important;
            height: 35px !important;
            margin: 15px 0px;
          }
        }
      }
    }
    .rmdp-panel {
      .rmdp-panel-body {
        width: 100%;
        // left: 12px !important;
        padding: 0px 10px !important;
        // right: -8px !important;
      }
    }
    .rmdp-panel > div {
      overflow: auto !important;
    }
  }

  .rmdp-header {
    font-size: 16px;
    color: #116582;
    padding: 5px 10px 5px 0px;
    div {
      justify-content: space-between;
    }
  }

  .rmdp-header-values {
    color: black !important;
    font-weight: 700;
    margin: 0px;
    span {
      padding: 0px;
    }
  }
  .rmdp-week-day {
    color: #116582;
    font-size: 16px;
    font-weight: 600;
  }
  .rmdp-today {
    .sd {
      background-color: white;
      color: black;
    }
  }

  .rmdp-day {
    span {
      font-size: 16px !important;
    }
    .sd {
      font-size: 16px !important;
    }
  }

  .rmdp-selected {
    .sd {
      background-color: #116582 !important;
    }
  }

  .rmdp-panel-header {
    font-weight: 600;
  }

  .rmdp-panel-body > li {
    background-color: #116582;
    .b-deselect {
      background-color: #116582;
    }
    span {
      font-size: 22px;
    }
  }

  //   .rbc-toolbar {
  //     position: relative;
  //     display: flex;
  //     justify-content: space-between;

  //     &-label {
  //       position: absolute;
  //       left: 220px;
  //     }
  //   }

  //   .rbc-month-view {
  //     height: 500px;
  //     flex: unset;
  //   }

  //   .rbc-time-view {
  //     overflow-x: scroll;
  //   }

  @media screen and (max-width: 576px) {
    .rmdp-week-day {
      color: #116582;
      font-size: 14px;
      font-weight: 600;
    }

    .rmdp-day {
      span {
        font-size: 14px !important;
      }
      .sd {
        font-size: 14px !important;
        :hover {
          background-color: #116582 !important;
        }
      }
    }
  }
}
