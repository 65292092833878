@import './varibles';

// Backgrounds
.bg-white {
  background-color: $white;
}

.bg-snow {
  background-color: $snow;
}

// Borders
.br-50 {
  border-radius: 50%;
}

// Shadows
.bs-nobel {
  box-shadow: $box-shadow !important;
}

// Fonts
.h4 {
  font-family: Avenir;
  font-size: 22.3px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  color: $black-87;
  margin-bottom: 0;
}

.h5 {
  font-family: Avenir;
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.12px;
  color: $black;
  margin-bottom: 0;
}

.h6 {
  font-family: Avenir;
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.12px;
  color: $black;
  margin-bottom: 0;
}

.regular-text {
  font-family: Avenir;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.25;
  letter-spacing: 0.25px;
  color: $black-87;
}

// Borders
.boder-b-whisper {
  border-bottom: solid 1px $whisper;
}

.cursor-pointer {
  cursor: pointer;
}

.pe-none {
  pointer-events: none;
}
