.container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .header {
    border-bottom: 1px solid #dfe3e4;
    padding: 10px;
    background-color: #fafafa;
    justify-content: end;
    position: sticky;
    top: 0px;
    z-index: 100;

    .buttons_container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
    }

    .preview_btn {
      width: 94.36px;
      height: 40px;
      padding: 0px;
      letter-spacing: 0px !important;
    }

    .save_quiz_btn {
      width: 100px;
      height: 40px;
      padding: 0px;
      letter-spacing: 0px !important;
    }

    .publish_btn {
      width: 90px;
      height: 40px;
      padding: 0px;
      letter-spacing: 0px !important;
    }
  }

  .mobile_header {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    gap: 15px;
    border-bottom: 1px solid #e7e7e7;
    padding: 16px 18px;

    .back_btn_container {}

    .question {
      font-family: Avenir;
      font-weight: 800;
      font-size: 16px;
      line-height: 20px;
      color: #282b2b;
    }
  }

  .body {
    padding: 50px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    // height: 100vh;
    margin-bottom: 20px;

    .question_number {
      font-family: Avenir;
      font-size: 16px;
      font-weight: 900;
      line-height: 23.94px;
      color: #282b2b;
    }

    .question_type_container {}

    .question_heading {
      font-family: Avenir;
      font-size: 16px;
      font-weight: 900;
      line-height: 24px;
      color: #282b2b;
    }

    .question {
      font-family: Avenir;
      font-size: 20px;
      font-weight: 800;
      line-height: 20px;
      color: #213649;
    }

    .ckeditor_container {}

    .options_container {}

    .actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;

      .add_choice_btn_container {
        .add_choice_btn {
          background-color: #dfe3e4;
          font-family: Avenir;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #215c73;
          min-width: 0px;
          padding: 12px 15px;
        }
      }

      .save_and_add_btn_container {
        position: relative;

        .save_and_next {
          min-width: 0px;
        }

        .question_type_panel {
          top: 50px;
          left: 50px;
        }
      }

      .toggle_container {
        display: flex;
        align-items: center;
        gap: 10px;

        .heading {
          font-family: Avenir;
          font-weight: 350;
          font-size: 16px;
          line-height: 20px;
          color: rgba(0, 0, 0, 0.87);
        }

        .toggle_button {
          margin-bottom: 0px;
        }
      }
    }
  }
}

.container_left {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 16px;

  .buttonContainer {
    width: '50%';
    position: relative;

    .add_question_btn {
      letter-spacing: 0px !important;
    }

    .add_question_btn_responsive {
      width: 100%;
    }

    .customize_result_btn {
      letter-spacing: 0px !important;
    }

    .customize_result_btn_responsive {
      width: 100%;
    }
  }

  .questionContainer {
    .questions {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}

.header {
  border-bottom: 1px solid #dfe3e4;
  padding: 10px;
  background-color: #fafafa;
  height: 52px;
  width: 100%;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0px;
  z-index: 100;

  .header_text {
    font-family: Avenir;
    font-weight: 900;
    font-size: 16px;
    line-height: 16px;
    color: #282b2b;
  }
}

.container_right {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 20px;

  .dd_title {
    font-family: Avenir;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #213649;
  }
}

.remind_again {
  width: 100%;
  display: flex;
  flex-direction: column;

  .MuiFormControlLabel_root {
    // margin: 0px;
  }

  .checkbox_root {
    color: #215c73;
  }
}

.customize_email_btn {
  font-family: Avenir !important;
  font-weight: 800 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  letter-spacing: 0px !important;
  min-width: 0px !important;
  width: 100%;
  color: #215c73 !important;
  border: 1px solid #215c73 !important;
  background-color: transparent !important;
  padding: 10px 0px !important;
}