@import 'utils/styles/styles.scss';
.about-provider {
  padding: 0 240px;
  padding-top: 32px;

  .provider-avatar {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 36px;
    grid-column-gap: 112px;
  }

  .extra-info {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin: 24px 0;
    padding: 30px 50px;

    &-item {
      &:nth-child(1) {
        width: 25%;
      }

      &:nth-child(3) {
        width: 45%;

        & > span {
          overflow-wrap: anywhere;
        }
      }
    }
  }
}

@media screen and (max-width: $desktop) {
  .about-provider {
    padding: 0 10%;
    padding-top: 32px;
  }
}

@media screen and (max-width: $laptop) {
  .about-provider {
    padding: 0 16px;
    padding-top: 16px;
    &-info {
      grid-column-gap: 56px;
    }
  }
}

@media screen and (max-width: $mobile-size) {
  .about-provider {
    padding: 0 16px;
    padding-top: 16px;

    &-info {
      display: grid;
      grid-template-columns: none;
      grid-row-gap: 26px;
    }
    .provider-avatar {
      height: 300px;
    }

    .extra-info {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      margin: 24px 0;
      padding: 30px 10px;

      &-item {
        &:nth-child(1) {
          width: 100%;
        }

        &:nth-child(3) {
          width: 90%;
          justify-content: flex-start;
        }
      }
    }
  }
}
