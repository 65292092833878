@import 'utils/styles/styles.scss';
.cohealer-about {
  .cohealer-about-links {
    display: flex;
    padding: 0 240px;
    border: solid 1px #e7e7e7;

    .cohealer-about-link {
      text-decoration: none;
      font-size: 1rem;
      line-height: 1;
      font-weight: 900;
      color: #4a4a4a;
      padding: 16px 32px;
      position: relative;

      &.active {
        color: #116582;

        &::before {
          content: '';
          width: 100%;
          height: 4px;
          background-color: #116582;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
    }
  }
}

@media screen and (max-width: $laptop) {
  .cohealer-about {
    .cohealer-about-links {
      padding: 0 16px;
    }
  }
}
