@import '~utils/styles/styles.scss';

.conversations-unread-messages-count {
  height: 20px;
  padding: 3px 8px;

  margin: 0;

  border-radius: 10px;
  background-color: $dark-ocean-blue-color-2;
  color: white;

  font-size: 11px;
  font-weight: 900;
  text-align: center;
}
