.date_container {
  position: relative;

  svg {
    position: absolute;
    top: 15px;
    right: 10px;
    cursor: pointer;
  }

  .date_picker {
    border: 1px solid #dfe3e4;
    border-radius: 4px;
  }

  .inputRoot {
    height: 40px;
    width: 114px;
    // border: 1px solid #dfe3e4;
    // border-radius: 4px;
    padding: 0px 10px;
  }

  .inputUnderline {
    &::before {
      border-color: transparent;
      border-bottom: none !important;
    }

    &::after {
      border-bottom: none !important;
    }
  }

  .input {
    width: 100%;
    height: 100%;
    font-family: Avenir;
    font-weight: 500;
    font-size: 14px;
    color: #4a4a4a;
  }
}
