.video-chat-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: white;

  &__video-chat {
    align-self: stretch;
    flex-grow: 1;
  }

  &-connection-error {
    display: flex;
    flex-direction: column;
    align-items: center;

    & &__close {
      margin-top: 10px;
    }
  }

  &.dark-theme {
    .video-chat-participants-panel {
      background-color: #2D2F31;
      color: white;

      .video-chat-participants-panel-participants {
        border-color: #696969;
      }
    }

    .video-chat-notes-panel {

      background-color: #2D2F31;
      color: white;
    }

    .chat-container-video {

      >div {
        background-color: #2D2F31;
        color: white;

        .chat__header {
          border-color: #696969;
        }

        .chat-content__messages-history {
          border-color: #696969;
        }

        .chat-message-separator__separator {
          background-color: #696969;
        }
      }
    }
  }
}

.video-chat {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: 1fr 66px;

  &__content {
    grid-column: 1;
    grid-row: 1;

    display: grid;
    align-items: stretch;
    justify-content: stretch;
  }

  &__footer {
    grid-column: 1;
    grid-row: 2;
  }

  &__side-panel {
    grid-row: 1 / 3;
    grid-column: 2;

    align-self: stretch;

    width: 400px;
    height: 100vh;
  }

  &-footer {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-column-gap: 20px;
    align-items: center;

    padding: 0 36px;

    background-color: black;

    &-toolbar {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: max-content;
      grid-column-gap: 20px;
    }

    & &__leave-session {
      margin: 0;

      justify-self: end;

      color: white;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.video-chat-mobile {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: 1fr 170px;

  &__content {
    grid-column: 1;
    grid-row: 1;

    display: grid;
    align-items: stretch;
    justify-content: stretch;
  }

  &__footer {
    grid-column: 1;
    grid-row: 2;
  }


  &__side-panel {
    grid-row: 1;
    grid-column: 1 / 2;

    align-self: stretch;
    width: 100%;
    background-color: white;
    z-index: 100;
  }

  &-footer {
    display: grid;
    justify-content: center;
    grid-template-columns: max-content;
    grid-template-rows: 70px 70px;
    grid-row-gap: 10px;
    align-items: center;

    padding: 6px;

    background-color: black;

    &-toolbar {
      display: grid;
      justify-content: center;
      grid-column: 1;
      grid-auto-flow: column;
      grid-auto-columns: max-content;
      grid-column-gap: 10px;
    }

    & &__leave-session {
      margin: 0;

      justify-self: end;

      color: white;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}