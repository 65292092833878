.container {
    width: 700px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;

    .button_container {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        .invite_button {
            font-family: Avenir !important;
            font-size: 14px !important;
            font-weight: 900 !important;
            line-height: 24px !important;
            text-align: left !important;
            padding: 10px 12px 10px 12px !important;
            display: flex;
            gap: 10px;
            justify-content: center;
            align-items: center;

        }

        .tablecontainer {
            border: 1px solid #DFE3E4;
        }
    }
}