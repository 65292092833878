.custom-calendar {
  width: 100%;
  overflow-x: auto;

  .rbc-toolbar {
    position: relative;
    display: flex;
    justify-content: space-between;

    &-label {
      position: absolute;
      left: 220px;
    }
  }

  .rbc-month-view {
    height: 500px;
    flex: unset;
  }

  .rbc-time-view {
    overflow-x: scroll;
    .rbc-event {
      min-height: unset;
    }
  }

  @media screen and (max-width: 1200px) {
    .rbc-time-view {
      width: 90vw;
    }

    .rbc-toolbar {
      flex-direction: column;

      &-label {
        position: static;
        margin: 5px 0;
      }
    }
  }
}

.rmdp-panel-body {
  li {
    span {
      font-size: 14px;
    }
  }
}
