.chat-header-participants {
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  &__title {
    margin: 0 0 0 5px;

    font-weight: bold;
  }
}
