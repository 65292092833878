@import 'styles/varibles.scss';

.application-form-container {
  min-height: 100vh;
  background-color: #fafafa;

  .application-progress-bar {
    background-color: #ededed;
  }

  .contribution-title {
    margin: auto 30px;
    font-weight: bold;
    font-size: 24px;

    @media screen and (max-width: 576px) {
      font-size: 18px;
      margin: auto 15px;
    }
  }

  .application-form-header {
    display: flex;
    height: 100px;
    background-color: white;

    .header-content {
      margin: auto;
      margin-right: 30px;
      display: flex;

      @media screen and (max-width: 576px) {
        margin-right: 10px;
      }

      .profile-pic-container {
        top: 0;
        width: 40px;
        height: 40px;
        font-size: 1.25rem;
        position: relative;
      }

      .dropdown-container {
        display: flex;
        margin-left: 10px;

        #header-user-dropdown {
          margin: auto;
        }
      }
    }
  }

  .application-form-body {
    max-width: 800px;
    margin: auto;
    min-height: calc(100vh - 100px);
    padding: 50px;
    display: flex;

    .application-login-form-container {
      margin: auto;
      width: 100%;
      padding-bottom: 100px;

      .login-form {
        .login-error {
          color: red;
        }

        .forgot-password-container {
          margin-bottom: 20px;
        }

        .terms-and-conditions-link {
          color: #116582 !important;
          font-weight: 600;
          margin-bottom: 20px;
        }
      }
    }

    .application-form-body-container-form {
      height: 100%;
      margin: auto;
      width: 100%;
      display: flex;
      padding-bottom: 100px;

      .application-form {
        margin: auto;
        width: 100%;

        .application-login-form-container {
          margin: 0 auto;
          width: 100%;
          padding-bottom: 100px;

          .login-form {
            .login-error {
              color: red;
            }

            .forgot-password-container {
              margin-bottom: 20px;
            }

            .terms-and-conditions {
              color: #116582 !important;
              font-weight: 600;
              margin-bottom: 20px;
            }
          }
        }

        .question-statement {
          font-weight: bold;
          margin-bottom: 10px;
          font-size: 18px;
        }

        .question-number {
          color: lightgray;
        }

        .answer {
          margin-bottom: 30px;
        }
      }
    }

    .application-form-image {
      display: flex;

      img {
        margin: 0;
        max-width: 700px;
        border-radius: 5px;
      }
    }

    .application-form-image-mobile {
      display: flex;

      img {
        margin: 0;
        width: 100%;
        border-radius: 5px;
      }
    }

    .application-form-note {
      margin: auto;
      color: #4a4a4a;
      text-align: center;
      font-size: 20px;
      margin-top: 16px;
    }

    .form-next-button {
      float: right;
      width: 100px;
      min-width: unset;
      border-radius: 5px;
    }

    .form-back-button {
      width: 100px;
      min-width: unset;
      border-radius: 5px;
    }

    .application-form-input {
      background-color: white;
      margin: 16px 0;
    }
  }

  .application-form-body-mobile {
    max-width: 800px;
    margin: auto;
    min-height: calc(100vh - 100px);
    padding: 50px 15px;
    display: flex;

    .application-login-form-container {
      margin-top: 30px;
      width: 100%;
      padding-bottom: 100px;

      .login-form {
        .login-error {
          color: red;
        }

        .forgot-password-container {
          margin-bottom: 20px;
        }

        .terms-and-conditions-link {
          color: #116582 !important;
          font-weight: 600;
          margin-bottom: 20px;
        }
      }
    }

    .application-form-body-container-form {
      height: 100%;
      margin: auto;
      width: 100%;
      display: flex;
      padding-bottom: 100px;

      .application-form {
        margin: auto;
        width: 100%;

        .application-login-form-container {
          margin: 0 auto;
          width: 100%;
          padding-bottom: 100px;

          .login-form {
            .login-error {
              color: red;
            }

            .forgot-password-container {
              margin-bottom: 20px;
            }

            .terms-and-conditions {
              color: #116582 !important;
              font-weight: 600;
              margin-bottom: 20px;
            }
          }
        }

        .question-statement {
          font-weight: bold;
          margin-bottom: 10px;
          font-size: 18px;
        }

        .question-number {
          color: lightgray;
        }

        .answer {
          margin-bottom: 30px;
        }
      }
    }

    .application-form-image {
      display: flex;

      img {
        margin: 0;
        max-width: 700px;
        border-radius: 5px;
      }
    }

    .application-form-image-mobile {
      display: flex;

      img {
        margin: 0;
        width: 100%;
        border-radius: 5px;
      }
    }

    .application-form-note {
      margin: auto;
      color: #4a4a4a;
      text-align: center;
      font-size: 20px;
      margin-top: 16px;
    }

    .form-next-button {
      float: right;
      width: 100px;
      min-width: unset;
      border-radius: 5px;
    }

    .form-back-button {
      width: 100px;
      min-width: unset;
      border-radius: 5px;
    }

    .application-form-input {
      background-color: white;
      margin: 16px 0;
    }
  }
}

.application-form-container-dark {
  background-color: $DarkThemedCardBackgroundColor;

  .application-progress-bar {
    background-color: #ededed;
  }

  .contribution-title {
    color: $DarkThemedColor;
  }

  .application-form-header {
    background-color: $DarkThemedBackgroundColor;

    .header-content {
      .dropdown-container {
        background-color: $DarkThemedBackgroundColor;

        div>a {
          background-color: $DarkThemedBackgroundColor;
          color: $DarkThemedColor;
          border-bottom-color: $DarkThemedCardBackgroundColor;
        }
      }
    }
  }

  .application-form-body {
    .application-login-form-container {
      margin: auto;
      width: 100%;

      .login-form {
        .forgot-password-container {
          a {
            color: $DarkThemedColor;
          }
        }

        .terms-and-conditions {
          color: $DarkThemedColor;
        }
      }
    }

    .application-form-body-container-form {
      .application-form {
        .answer {
          color: $DarkThemedColor;

          span,
          input {
            color: $DarkThemedColor;
          }

          fieldset {
            border-color: $DarkThemedColor;
          }
        }

        .question-statement {
          color: $DarkThemedColor;
        }
      }
    }

    // .application-form-image {
    //   display: flex;

    //   img {
    //     margin: 0 auto;
    //     width: 410px;
    //     border-radius: 5px;
    //   }
    // }

    .application-form-note {
      color: $DarkThemedColor;
    }

    // .form-next-button {
    //   float: right;
    //   width: 100px;
    //   min-width: unset;
    //   border-radius: 5px;
    // }

    // .form-back-button {
    //   width: 100px;
    //   min-width: unset;
    //   border-radius: 5px;
    // }

    .application-form-input {
      background-color: $DarkThemedBackgroundColor;
      color: $DarkThemedColor;

      input {
        color: $DarkThemedColor;
      }

      label {
        color: $DarkThemedColor;
      }
    }

    .application-form-input>div {
      color: $DarkThemedColor; /////////////////////////////////////////////////////// TEST IT WELL
    }
  }
}

ul:has(.application-form-select-field) {
  background-color: $DarkThemedBackgroundColor !important;
  color: $DarkThemedColor;
}