@import '../../../../utils/styles/styles.scss';


.container {
    display: flex;
    align-items: center;
    gap: 8px;

    .label {
        font-size: 14px;
        font-family: $font-family-Avenir;
        font-weight: 800;
        color: $bluish-grey-blue;
    }
}