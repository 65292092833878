.container {
  display: flex;
  flex-direction: column;

  .topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }

    .createtag_btn_disabled {
      background-color: #ededed;
      color: #9b9b9b;
      fill: #9b9b9b;
      /* for svg */
      cursor: not-allowed;
      border: none;

      svg {
        path {
          fill: #9b9b9b;
        }
      }
    }
  }

  .searchBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .searchBar_input {
      width: 100%;
      max-width: 240px;
    }

    .searchBox {
      background-color: white !important;
    }

    .deleteBtn {
      height: 40px;
      min-width: 75px;
      padding: 7px 14px;
      border: 1px solid #e7e7e7;
      border-radius: 4px;
      background-color: #ffffff;
      font-family: Avenir;
      font-size: 14px;
      font-weight: 800;
      line-height: 20px;
      letter-spacing: -0.24px;
      color: #215c73;

      @media screen and (max-width: 768px) {
        min-width: 55px;
        padding: 0px 7px;
        font-size: 13px;
      }
    }

    .addTagBtn {
      height: 40px;
      min-width: 75px;
      padding: 7px 14px;
      border: 1px solid #e7e7e7;
      border-radius: 4px;
      background-color: #ffffff;
      font-family: Avenir;
      font-size: 14px;
      font-weight: 800;
      line-height: 20px;
      letter-spacing: -0.24px;
      color: #215c73;
    }

    .sendEmailBtn {
      height: 40px;
      min-width: 75px;
      padding: 7px 14px;
      border: 1px solid #e7e7e7;
      border-radius: 4px;
      background-color: #ffffff;
      font-family: Avenir;
      font-size: 14px;
      font-weight: 800;
      line-height: 20px;
      letter-spacing: -0.24px;
      color: white;
      background-color: #215c73;
    }

    .filterBtn {
      height: 40px;
      width: 92px;
      padding: 7px 16px;
      border: 1px solid #e7e7e7;
      border-radius: 4px;
      background-color: #ffffff;
      font-family: Avenir;
      font-size: 14px;
      font-weight: 800;
      line-height: 20px;
      letter-spacing: -0.24px;
      color: #215c73;
    }
  }

  .tagsTable {
    margin-top: 10px;
  }
}

.delete_modal_main {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 20px;

  .top_heading {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 800;
    line-height: 16px;
    color: black;
  }

  .form_control_label_container {
    .checkbox_root {
      padding-top: 0px;

      &:hover {
        background-color: transparent;
      }
    }

    .form_control {
      display: flex;
      align-items: flex-start;
    }

    .form_label {
      font-family: Avenir;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: black;
    }
  }

  .contact_list_heading {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 800;
    line-height: 24.5px;
    color: black;
  }

  .contact_list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
}