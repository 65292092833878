@import "utils/styles/styles.scss";
.discount {
  min-height: 60.38px;
  margin-top: -20px !important;

  @media screen and (max-width: $mobile-size) {
    & {
      min-height: 0;
      margin-top: 0 !important;
    }
  }
}

#create-contribution-form {
  .MuiTextField-root {
    .MuiInputBase-root {
      color: rgba(74, 74, 74, 1);
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: rgba(223, 227, 228, 1) !important;
    }
  }

  .MuiFormControlLabel-root {
    margin-bottom: 0 !important;
    // margin-left: 0 !important;
  }
}
