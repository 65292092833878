$font-family: 'Avenir';

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 15px 0px;

  .heading_text {
    font-family: $font-family;
    font-size: 16px;
    font-weight: 350;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
  }

  .domain_wrapper {
    width: 100%;

    .domain_label {
      width: auto;
      font-family: $font-family;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.87);
      margin: 5px 0px;
    }

    .domain_input {
      width: 100%;
    }

    .domain_root {
      border: 1px solid rgba(0, 0, 0, 0.23);
    }

    .domain_notchedOutline {
      border: none;
    }
  }

  .email_wrapper {
    width: 100%;

    .email_label {
      width: auto;
      font-family: $font-family;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.87);
      margin: 5px 0px;
    }

    .email_input {
      width: 100%;
    }

    .email_root {
      border: 1px solid rgba(0, 0, 0, 0.23);
    }

    .email_notchedOutline {
      border: none;
    }

    .email_adornedEnd {
      padding-right: 1px;
    }

    .endAdornment {
      background-color: #f5f5f5;
      padding: 18px 50px;
      max-width: 40%;
      padding: 18px 5px 18px 5px;
      // overflow-x: scroll;

      @media screen and (max-width: 500px) {
        padding: 18px 5px;
      }
    }
  }
}