@import '~utils/styles/styles.scss';

.chat-messages-history {
  position: relative;

  &__list {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &-list {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: $light-gray-color-3;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: $gray-color-2;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: $dark-gray-color;
    }

    & &__load-more-messages-progress {
      width: 30px !important;
      height: 30px !important;

      align-self: center;

      margin: 10px;
    }

    &__message {
      padding: 15px 24px;

      &--unread {
        background-color: #{$dark-ocean-blue-color}1f;
      }
    }

    &__message-separator {
      margin: 27px 0;
    }

    &__typing-members {
      padding: 0 24px 15px 24px;

      margin-top: auto;
    }
  }
}
