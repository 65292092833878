@import '~utils/styles/styles.scss';

.chat-participants-modal {
  min-width: 20%;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid $light-gray-color-2;

    &__title {
      margin: 0;

      font-size: 22.29px;
      font-weight: 500;
    }

    & &__close {
      margin: -12px;
    }
  }

  &-participant-name {
    margin: 0;

    font-size: 16px;
    font-weight: 500;
  }
}
