.table {
  width: 100%;

  .table_main {
    width: 100%;
    // border-collapse: separate;
    // border-spacing: 0px;

    .table_head_row {
      height: 60px;
    }
    .table_data_row {
      width: 100%;
      height: 50px;
      border: 1px solid #dfe3e4;

      td {
        // border: 1px solid #dfe3e4;
        border-right: 1px solid #dfe3e4;
        text-align: center;

        div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0px 20px;
        }
      }

      .error {
        padding: 16px;
        background-color: #f1f8ff;

        .error_heading {
          padding: 8px 0px;
          font-family: Avenir;
          font-size: 16px;
          font-weight: 400;
          line-height: 16px;

          .tooltip {
            display: flex;
            align-items: center;
            gap: 8px;
          }
        }

        .error_message {
          padding: 15px;
          background-color: #fff;
          font-family: Avenir;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 1px;
        }
      }
    }
  }
}
