@import '../../../../utils/styles/styles.scss';
$font-family: 'Avenir';

.form_body {
  display: flex;
  justify-content: center;
  align-items: center;

  .form_container {
    text-align: center;
    flex-basis: 846px;
    padding: 20px 20px 0px;

    .title_class {
      font-size: 22px;
      font-weight: 400;
      font-family: $font-family;
      margin-bottom: 40px;
    }

    .summary_container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 32px;

      .summary_content {
        width: 100%;
        border: 1px solid #dfe3e4;
        border-radius: 4px;
        padding: 30px;
      }

      .summary_main {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      .schedular {
        display: flex;
        flex-direction: column;
        gap: 32px;

        .schedule_email_container {
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid #dfe3e4;

          .heading_container {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 10px;

            .heading {
              font-family: $font-family;
              font-size: 14px;
              font-weight: 500;
              line-height: 16px;
              color: #213649;
            }
          }

          .toggle_container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            .toggle_text {
              font-family: $font-family;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              color: #000000;
            }

            .toggle_button_container {
              .toggle_button {
                margin: 0px;
              }
            }
          }

          .time_date_schedule {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 5px;
            margin-bottom: 10px;

            .time_picker_container {
              position: relative;

              svg {
                position: absolute;
                top: 15px;
                right: 20px;
                cursor: pointer;
              }

              .time_picker {
                display: flex;
                height: 40px;
                align-items: flex-end;
                width: 100%;
                padding: 0px 10px;
                // border: 1px solid #dfe3e4;
                // border-radius: 4px;
                height: 40px;
                width: 120px;

                input {
                  color: #000;
                  font-family: $font-family;
                  font-size: 14px;
                  font-weight: 500;
                  outline: none;
                  height: 100%;
                  width: 100%;
                  text-align: start;
                }

                a {
                  display: none;
                }
              }
            }

            .timezone_container {
              width: 177px;
              height: 40px;

              .timezone {
                height: 40px;
                width: 210px;
                border: 1px solid #dfe3e4;
                border-radius: 4px;
                justify-content: center;
                padding: 0px 10px;
              }

              .root_select {
                font-family: $font-family;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                text-align: left;
              }

              .select_select {
                &:focus {
                  background-color: white;
                }
              }

              .input_select {
                padding: 4px 0px 6px;
              }
            }
          }
        }

        .test_email_container {
          display: flex;
          flex-direction: column;

          .heading_container {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 8px;

            .heading {
              font-family: $font-family;
              font-size: 14px;
              font-weight: 500;
              line-height: 16px;
              color: #213649;
            }
          }

          .action_container {
            display: flex;
            justify-content: flex-start;
            gap: 5px;

            .email_input {
              height: 40px;
              width: 207px;
              padding: 10px;
              border: 1px solid #dfe3e4;
              border-radius: 4px;
              font-family: $font-family;
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;

              &:focus {
                outline: none;
                border: 1px solid #dfe3e4;
              }
            }

            .send_test_button {
              height: 40px;
              padding: 0px 24px;
              color: white;
              background-color: #c9b382;
              border: 1px solid #c9b382;
              border-radius: 4px;
              font-family: $font-family;
              font-size: 14px;
              font-weight: 800;
              line-height: 20px;
              text-align: center;
            }

            .preview_button {
              height: 40px;
              padding: 0px 24px;
              color: #c9b382;
              background-color: white;
              border: 1px solid #c9b382;
              border-radius: 4px;
              font-family: $font-family;
              font-size: 14px;
              font-weight: 800;
              line-height: 20px;
              text-align: center;
            }
          }
        }
      }

      .error {
        font-family: $font-family;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        color: red;
      }
    }
  }
}

.stepper_container {
  width: 100%;
}

.preview_conatiner {
  border: 2px solid $grey-background;
  width: 100%;
  box-shadow: 0px 8px 8px 0px $grey-background;

  .preview_btns {
    display: flex;
    gap: 8px;
    justify-content: end;
    padding: 10px 20px;
    width: 100%;
    border-bottom: 2px solid $grey-background;
    text-align: end;

    .image {
      cursor: pointer;
    }
  }

  .preview_body {
    display: flex;
    justify-content: center;

    .responsive_preview {
      width: 420px;
      padding: 20px;
      border-left: 1px solid #e7e7e7;
      border-right: 1px solid #e7e7e7;

      @media screen and (max-width: $mobile-size) {
        padding: 0px;
      }
    }

    .responsive_desktop_preview {
      padding: 20px;
    }
  }
}

.email_main_container {
  width: 100%;

  .email_main {
    width: 100%;
  }

  .email_root {
    border: 1px solid rgba(0, 0, 0, 0.23);
  }

  .email_notchedOutline {
    border: none;
  }

  .email_adornedEnd {
    padding-right: 1px;
  }

  .endAdornment {
    background-color: #f5f5f5;
    padding: 10px 25px;

    .select_root {
      padding-right: 100px;
      background-color: transparent;
      font-family: $font-family-Avenir;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      text-align: center;

      @media screen and (max-width: 500px) {
        padding-right: 25px;
      }
    }

    @media screen and (max-width: 500px) {
      padding: 10px 10px;
    }
  }
}