.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 15px;

  .choices_list {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .choice {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;

      .input_root {
        &:hover {
          .input_notchedOutline {
            border-color: #e7e7e7 !important;
          }
        }
      }

      .input_notchedOutline {
        border-color: #e7e7e7 !important;
      }

      .input_focused {
        .input_notchedOutline {
          border-color: #e7e7e7 !important;
        }
      }

      .cross_btn_container {
        cursor: pointer;
      }
    }
  }

  .btn_container {
    .add_choice_btn {
      width: 129px;
      height: 36px;
      background-color: #fff;
      font-family: Avenir;
      font-weight: 800;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0px;
      color: black;
      min-width: 0px;
      padding: 0px;
      border: 1px solid #215c73;

      &:hover {
        background-color: #fff;
      }
    }
  }
}
