.main {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0px;

  .heading {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: black;
  }

  .radio_buttons {
    display: flex;
    gap: 16px;
  }

  .file_dropzone_container {
    .placeholder {
      display: flex;
      justify-content: center;
      margin-top: 13px;
      font-family: Avenir;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }

  .tagname_container {
    .main_container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;

      .heading {
        font-family: Avenir;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        color: #213649;
      }

      .tag_container {
        .tag {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: -0.24px;
          color: #ffffff;
          padding: 2px 8px;
          background-color: #c9b382;
          border-radius: 5px;
        }
      }

      .input_underline {
        &::before {}

        &::after {}
      }
    }
  }

  .error {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: red;
  }
}