@import '../../../../utils/styles/styles.scss';

.form_body {
  display: flex;
  justify-content: center;
  align-items: center;

  .form_container {
    text-align: center;
    flex-basis: 846px;
    padding: 20px 20px 0px;

    .title_class {
      font-size: 22px;
      font-weight: 400;
      font-family: $font-family-Avenir;
      margin-bottom: 40px;
    }

    .topBar {
      display: flex;
      // justify-content: space-between;
      align-items: center;

      .segmentBtn {
        // height: 40px;
        width: auto;
        // padding: 0px 16px;
        border: 1px solid rgba(224, 224, 224, 1);
        border-radius: 4px;
        color: #215c73;
        background-color: white;
        font-family: Avenir;
        font-size: 14px;
        font-weight: 800;
        line-height: 20px;
        letter-spacing: -0.24px;

        &:disabled {
          background-color: #ededed;
          color: #9b9b9b;
          fill: #9b9b9b;
          /* for svg */
          cursor: not-allowed;
          border: none;
        }
      }

      .segmentBtn_div {
        width: auto;
        padding: 16px;
        border: 1px solid #dbe7f2;
        border-radius: 4px;
        color: #9b9b9b;
        background-color: #ededed;
        border: none;
        font-family: Avenir;
        font-size: 14px;
        font-weight: 800;
        line-height: 20px;
        letter-spacing: -0.24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .search {
        height: 53px;
      }
    }

    .editPanel {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      margin-top: 20px;

      .Mui_select {
        display: flex;
        align-items: center;
        height: 33px;
      }

      .select {
        width: 100%;
        // height: 40px;
        border-radius: 4px;
        flex-direction: column-reverse;
        padding: 0px 8px;
        text-align: left;

        div {
          font-family: Avenir;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.24px;
          color: black;
          padding-right: 0px;
        }

        fieldset {
          border: 1px solid rgba(224, 224, 224, 1);
        }
      }

      .editBtn {
        // height: 40px;
        width: auto;
        // padding: 0px 16px;
        border: 1px solid rgba(224, 224, 224, 1);
        border-radius: 4px;
        color: #215c73;
        background-color: white;
        font-family: Avenir;
        font-size: 14px;
        font-weight: 800;
        line-height: 20px;
        letter-spacing: -0.24px;
      }

      .createSegmentBtn {
        // height: 40px;
        width: auto;
        // padding: 0px 16px;
        border: 1px solid #dbe7f2;
        border-radius: 4px;
        color: white;
        background-color: #215c73;
        font-family: Avenir;
        font-size: 14px;
        font-weight: 800;
        line-height: 20px;
        letter-spacing: -0.24px;
      }
    }

    .table {
      margin-top: 20px;

      .tableContainer {
        overflow-x: auto;
        max-width: 100%;
        margin: 0 auto;
        border: 1px solid #e7e7e7;
        border-radius: 4px;

        .headerTableCell {
          font-weight: 900;
          padding: 10px 16px;

          .headerLabel {
            margin-bottom: 0px !important;
          }

          .headerCellLabel {
            font-family: Avenir;
            font-weight: 900;
            font-size: 14px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.87);
          }
        }

        .bodyTableCell {
          padding-top: 8px;
          padding-bottom: 8px;

          .bodyCellLabel {
            font-family: Avenir;
            font-weight: 350;
            font-size: 14px;
            line-height: 20.02px;
            color: rgba(0, 0, 0, 0.87);
            margin-bottom: 0px;
          }

          .bodyCellValue {
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .errorBody {
      top: 110;
      width: 45%;
      right: 350;
      background-color: white;
      margin-inline: 9%;
      border: 1px solid #dbe7f2;
      border-radius: 4px;
      z-index: 1;
      transition-duration: 1s;
    }
  }
}

.stepper_container {
  width: 100%;
}
