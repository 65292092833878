@import 'utils/styles/styles.scss';

.website-form {
  @media screen and (max-width: 576px) {
    form {
      padding: 0px;
    }
  }
}

.website-form-container {
  margin-bottom: 50px;

  .branding-container {
    background-color: white;
    padding: 20px;
    border-bottom: 1px solid #dfe3e4;

    .profile-avatar-container {
      max-width: unset;
    }

    .profile-picture {
      padding: 0;

      > p {
        margin-left: 0;
      }
    }
  }

  .website-info {
    background-color: white;
    padding: 0px 30px 0px 0px;
    border-radius: 2px;
    position: relative;

    .profile-section {
      .MuiFormHelperText-root {
        margin-left: 0;
      }
    }

    .profile-section:not(:last-of-type) {
      border-bottom: 1px solid #e7e7e7;
      padding-bottom: 30px;
    }
  }

  .banner-avatar-container {
    max-width: 184px;
    position: relative;
    background-color: #f7f9fa;
    font-size: 12px;
    padding: 10px;

    img {
      width: 100%;
    }

    .upload-image-button {
      position: absolute;
      float: right;
      right: 14px;
      bottom: 74px;
      border-radius: 48px;
      padding: 8px;
      display: flex;
      width: 34px;
      height: 34px;
      background: #cdba8f;
    }

    &:hover {
      .remove-image-button {
        display: flex;
      }
    }

    .remove-image-button {
      display: none;
      position: absolute;
      float: right;
      right: 4px;
      top: 4px;
      border-radius: 48px;
      width: 16px;
      height: 16px;
      background: #cdba8f;
    }

    .profile-avatar-text {
      display: none;
    }

    > p {
      margin-top: 10px;
      margin-bottom: 0;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      display: block;
      color: #797a7b;
    }
  }

  .profile-picture {
    padding-right: 5px;

    > p {
      max-width: 184px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .profile-avatar-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 184px;
    background-color: #f7f9fa;
    font-size: 12px;
    padding: 10px;
    position: relative;

    img {
      width: 100%;
    }

    span {
      background: #cdba8f;
    }

    .profile-avatar-text {
      display: none;
    }

    > p {
      margin-top: 10px;
      margin-bottom: 0;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      display: block;
      color: #797a7b;
    }

    > .profile-avatar {
      margin: auto !important;
    }

    .upload-image-button {
      position: relative;
      float: right;
      right: 0;
      bottom: 47px;
      border-radius: 48px;
      padding: 8px;
      display: flex;
      width: 34px;
      height: 34px;
      background: #cdba8f;
    }

    .profile-avatar-description {
      background: none;
      display: inline-block;
      text-align: center;
      color: #797a7b;
      margin-top: -20px;
      width: 100%;
      text-align: center;
    }
  }

  .field-title {
    margin-top: 20px;
    margin-bottom: 5px;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 350;
    line-height: 16px;
    color: #47627b;
  }

  .footer-settings {
    .field-title {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .input-field {
    margin-top: 0;
  }

  .select-field {
    height: 55px;
    background-color: white;
  }

  .select-color {
    border-radius: 5px;
    display: flex;
    padding: 3px;
    width: 145px;
    height: 32px;
    background-color: white;
  }

  .flex {
    display: flex;
  }

  .section-title {
    display: flex;
    margin-top: 25px !important;
    margin-bottom: 25px !important;

    .heading {
      display: flex;

      svg {
        width: 30px;
      }
    }

    .add-action {
      margin-left: auto;
      display: flex;
      float: right;

      .add-icon {
        background-color: #215c73;
        height: fit-content;
        padding: 10px;
        width: 32px;
        border-radius: 70px;
        display: flex;

        svg {
          margin: auto;
        }
      }
    }

    p {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #000000;
      margin-left: 10px;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .contribution-section {
    .thumbnail-avatar-container {
      margin: auto;
    }

    .no-link-image {
      .thumbnail-avatar-container {
        .thumbnail-avatar {
          border-radius: 1px;
        }
      }
    }

    .link-image {
      .thumbnail-change {
        cursor: pointer;
        position: absolute;
        top: 4px;
        bottom: 0;
        left: 0;
        right: 0;
        height: 48px;
        width: 48px;
        opacity: 0;
        transition: 0.5s ease;
        background-color: #131313;
        border-radius: 50%;

        &:hover {
          opacity: 1;
        }
      }
    }

    .d-flex {
      .no-image {
        width: 181px;
        text-align: center;
        line-height: 56px;
        margin-bottom: 0;
      }

      svg {
        width: 20px;
        margin: auto;
        margin-left: 8px;
      }

      .delete-container {
        display: flex;

        .delete-icon {
          width: unset;
        }
      }

      .draggable-icon {
        margin: auto;
        margin-right: 8px;

        svg {
          margin-right: 0px !important;
        }
      }

      .input-field {
        margin: auto;
        margin-left: 8px;
        margin-right: 8px;
        background-color: white;
      }

      img {
        height: 52px;
        margin: auto;
        margin-left: 8px;
        margin-right: 8px;
      }

      label {
        margin: auto;
      }

      label {
        margin: auto;

        input {
          width: inherit;
        }
      }
    }

    .draggable-item {
      margin-bottom: 20px;
      padding: 8px;
      background: rgb(241, 248, 255, 0.4);
      border-radius: 8px;

      .draggable-item-grid {
        .select-field {
          overflow: hidden;
        }
      }
    }
  }

  .message-settings {
    border-bottom: '1px solid #E7E7E7';
    display: flex;

    > p {
      margin: auto 0;
    }

    svg {
      cursor: pointer;
      margin-left: 10px;
    }

    .website-url {
      margin-bottom: auto;
      margin-top: auto;
      font-family: 'Inter';
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: #47627b;
    }
  }

  .footer-settings {
    display: flex;
    align-items: center;

    > p {
      margin-bottom: 0px;
    }
  }

  .general-settings-column {
    padding: 0px 5px 0px 15px;

    .profile-form {
      .profile-avatar-container {
        .upload-image-button {
          bottom: 44px;
          right: 0;
        }

        .remove-image-button {
          bottom: 14px;
          right: 0;
        }

        .profile-avatar-description {
          margin-top: 0;
        }
      }
    }
  }

  @media screen and (max-width: 576px) {
    .general-settings-column {
      padding: 10px 20px !important;
      margin-bottom: 50px;
    }
  }

  /* @media screen and (max-width: $desktop) {
      .website-info {
        .top-buttons-container {
          background-color: white;
          display: flex;
          flex-direction: row-reverse;
          padding-bottom: 20px;
  
          .top-buttons {
            right: unset;
            margin: auto;
            position: relative;
          }
        }
      }
    } */

  @media screen and (max-width: $desktop) and (min-width: $laptop) {
    .profile-picture {
      padding-right: 0;

      p {
        margin-left: 0;
      }

      .profile-form {
        .profile-avatar-container {
          margin-left: 0;
          width: 100%;
          max-width: unset;
        }
      }

      .banner-avatar-container {
        margin-left: 0;
        width: 100%;
        max-width: unset;
      }
    }

    .contribution-section {
      .d-flex {
        svg {
          width: 30px;
          margin: auto;
        }
      }

      .draggable-item {
        .draggable-item-grid {
          background: rgb(241, 248, 255, 0.4);
        }

        .MuiGrid-item {
          flex-wrap: wrap;

          .other-link-switch {
            margin-right: 10px;
          }

          .other-link-delete {
            margin-left: 10px;
            margin-right: 10px;
          }

          .select-field {
            margin-top: 20px;
            order: 2;
          }
        }

        .other-links-inputs {
          order: 2;

          .input-field {
            margin-top: 20px;
          }
        }
      }

      .section-title {
        flex-wrap: wrap;

        .heading {
          svg {
            margin: auto;
          }
        }

        .add-action {
          margin-left: auto;
          margin-right: auto;
          margin-top: 20px;
        }
      }
    }
  }

  @media screen and (max-width: $laptop) {
    .general-settings-column {
      padding: 0px;
      /*    display: none; */
    }

    .branding-column-mobile {
      display: block;
    }

    .field-title {
      font-size: 14px;
    }

    .website-info {
      background-color: #e5e5e5;
      padding: 0;

      .profile-section {
        margin-bottom: 20px;
        padding: 0 30px;

        &:not(:last-of-type) {
          border-bottom: none;
        }

        .section-title {
          p {
            font-size: 16px;
          }
        }
      }

      .MuiGrid-container {
        background-color: white;
      }

      .draggable-item {
        .draggable-item-grid {
          background: rgb(241, 248, 255, 0.4);

          .other-links-inputs {
            background: rgb(241, 248, 255, 0.4);
          }
        }
      }
    }
  }

  @media screen and (max-width: $mobile-size) {
    .profile-picture {
      padding-right: 0;

      p {
        margin-left: 0;
      }

      .profile-form {
        .profile-avatar-container {
          margin-left: 0;
          width: 100%;
          max-width: unset;
        }
      }

      .banner-avatar-container {
        margin-left: 0;
        width: 100%;
        max-width: unset;
      }
    }

    .contribution-section {
      .d-flex {
        .no-image {
          width: 100px;
          text-align: center;
          line-height: 56px;
          margin-bottom: 0;
          font-size: 14px;
        }

        svg {
          width: 20px;
          margin: auto;
        }

        img {
          height: 38px;
        }

        .delete-container {
          width: 40px;
        }
      }

      .draggable-item {
        .draggable-item-grid {
          background: rgb(241, 248, 255, 0.4);
        }

        .MuiGrid-item {
          flex-wrap: wrap;

          .other-link-switch {
            margin-right: 10px;
          }

          .other-link-delete {
            margin-left: 10px;
            margin-right: 10px;
          }

          .select-field {
            margin-top: 20px;
            order: 2;
          }
        }

        .other-links-inputs {
          order: 2;

          .input-field {
            margin-top: 20px;
          }
        }
      }

      .section-title {
        flex-wrap: wrap;

        .heading {
          svg {
            margin: auto;
          }
        }

        .add-action {
          margin-left: auto;
          margin-right: auto;
          margin-top: 20px;
        }
      }
    }
  }

  .right-10 {
    position: relative;
    right: 10px;
  }

  .theme-btn-color {
    color: white;
    background-color: #116582;

    &:hover {
      color: white;
      background-color: #203e51;
    }
  }
}
