$mobile: 992px;

.header {
  border-bottom: 1px solid #dfe3e4;
  padding: 5px 10px;
  background-color: #fafafa;
  justify-content: end;
  position: sticky;
  top: 0px;
  z-index: 100;

  @media screen and (max-width: $mobile) {
    top: 80px;
    padding: 10px 10px;
  }

  .buttons_container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;

    @media screen and (max-width: $mobile) {
      justify-content: space-between;
    }
  }

  .preview_btn {
    width: 94.36px;
    height: 40px;
    padding: 0px;
    letter-spacing: 0px !important;

    @media screen and (max-width: $mobile) {
      width: 125px;
    }
  }

  .save_quiz_btn {
    width: 100px;
    height: 40px;
    padding: 0px;
    letter-spacing: 0px !important;

    @media screen and (max-width: $mobile) {
      width: 125px;
    }
  }

  .publish_btn {
    width: 90px;
    height: 40px;
    padding: 0px;
    letter-spacing: 0px !important;

    @media screen and (max-width: $mobile) {
      width: 125px;
    }
  }
}

.remind_again {
  width: 100%;
  display: flex;
  flex-direction: column;

  .MuiFormControlLabel_root {
    // margin: 0px;
  }

  .checkbox_root {
    color: #215c73;
  }
}