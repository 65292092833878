
    @media only screen and (max-width: 600px) {
            .ck ck-editor__main {
                width: '364px';
            }
        }

                .MuiNativeSelect-select {
                    padding: 0px !important;
                    margin-top: 12px !important;
                }
                                .MuiNativeSelect-iconOutlined {
                                    right: -8px !important;
                                }
   
