.container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .heading {
    font-family: Avenir;
    font-weight: 350;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0px;
    color: rgba(0, 0, 0, 0.87);
  }

  .options_list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
