.container {
  position: relative;

  .question_type_select {
    width: 196px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #dfe3e4;
    padding: 0px 16px 0px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .icon_type_container {
      display: flex;
      align-items: center;
      gap: 7px;

      .icon {
        width: 16px;
        background-color: #E7E7E7;
        padding: 2px;
        border-radius: 2px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .type_name {
        font-family: Avenir;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        color: #213649;
      }

      .type_name_selected {
        font-family: Avenir;
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        color: #213649;
      }
    }
  }
}