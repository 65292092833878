.container {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.all_tags_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding-bottom: 25px;
}