.cohere-modal {
  button {
    min-width: auto;
  }

  border-radius: 5px;
}

.submit-btn {
  padding: 11px 30px !important;
  border-radius: 4px !important;
}

.cancel-btn {
  padding: 10px 63px !important;
  border-radius: 4px !important;
}

.cancel-btn-quizes {
  padding: 10px 37px !important;
  border-radius: 4px !important;
}

.cancel-understood-btn {
  padding: 10px 20px !important;
  border-radius: 4px !important;
}

.cancel-reduced-btn {
  padding: 10px 30px !important;
  border-radius: 4px !important;
}

.create-application-form-modal {
  .cancel-btn {
    padding: 10px 30px !important;
  }

  .cohere-modal {
    max-height: 750px;
  }
}

.help {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

@media only screen and (max-width: 990px) {
  .cancel-btn {
    padding: 10px 20px !important;
  }
}

.cohere-modal-dark {
  h3 {
    color: white;
  }

  input {
    color: black;
  }

  form {
    span {
      color: white;
    }

    a,
    label {
      color: white;
    }

    .MuiInput-root {
      color: white;
    }
  }

  fieldset {
    border-color: #696969;
  }

  input {
    color: white;
  }
}

.icon_class_button {
  span {
    margin: 0px;
  }
}