.container {
  border-bottom: 1px solid #dfe3e4;
  padding-bottom: 24px;

  .confirmed_session_container {
    padding-bottom: 8px;

    .radio {
      padding: 2px 8px;
    }

    .label {
      white-space: nowrap;
      font-size: 14px;
    }
  }

  .book_availability_container {
    .radio {
      padding: 2px 8px;
    }

    .label {
      white-space: nowrap;
      font-size: 14px;
    }
  }

  .select_service {
    font-weight: 700 !important;
  }
}
