@import '../../../../utils/styles/styles.scss';

.imageContainer {
  position: relative;
  width: 100%;

  .overlay {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: black;
    opacity: 1;
    display: none;
    transition: opacity 0.5s ease;
  }

  .templateImg {
    opacity: 0.4;
    display: block;
    width: 100%;
    height: 160px;
    border: 1px solid #dfe3e4;
    border-radius: 4px;
    transition: 0.5s ease;
    overflow: hidden;
    backface-visibility: hidden;
  }

  .middle {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transition: 0.5s ease;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;

    .btn {
      border-radius: 26px;
      padding: 7px 18px;
      font-weight: 500;
      font-family: $font-family-Avenir;
      margin: 4px 0px;
    }
  }

  &:hover {
    // .templateImg {
    //   opacity: 0.5;
    // }

    .overlay {
      display: block;
      opacity: 0.3;
    }

    .middle {
      opacity: 1;
    }
  }
}
