.container {
    flex: 1;
    display: flex;
    flex-direction: column;


    .preview_container {
        padding: 20px 400px;

        .question_container {
            display: flex;
            flex-direction: row;
            gap: 5px;
            margin-left: 5px;

            .header {
                font-family: Avenir;
                font-weight: 900;
                font-size: 16px;
                color: #282B2B;
            }

        }

        .header {
            font-family: Avenir;
            font-weight: 900;
            font-size: 16px;
            color: #282B2B;
        }

        .question {
            margin-top: 10px;
        }

        .options_container {
            .sub_header {
                font-family: Avenir;
                font-weight: 500;
                font-size: 14px;
                color: #282B2B;
            }

            .option_block {
                display: flex;
                flex-direction: row;
                margin-top: 10px;
                background-color: #FFFFFF;

                .option_left_panel {
                    // background-color: #DFE3E4;
                    padding: 10px;

                    .checkBoxUnchecked {
                        height: 18px;
                        width: 18px;
                        border-radius: 4px;
                        border: 1.5px solid #213649;

                    }

                    .checkBoxChecked {
                        height: 18px;
                        width: 18px;
                        border-radius: 4px;
                        border: 1.5px solid #213649;
                        background-color: #213649;
                        align-items: center;
                        justify-content: center;
                        display: flex;
                    }
                }

                .option_right_panel {
                    padding: 10px 20px;
                }
            }

            .footer {

                display: flex;
                justify-content: flex-end;
                padding-top: 40px;
                flex-direction: column;
                align-items: flex-end;
                width: 100%;

                .banner {
                    background-color: #F5F5F5;
                    border: 1px solid #DFE3E4;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    width: 100%;
                    color: #C11111;
                    font-weight: 800;
                    font-family: Avenir;
                    font-size: 16px;
                    height: 48px;
                    margin-bottom: 20px;
                }

                .bannerCorrect {
                    background-color: #F5F5F5;
                    border: 1px solid #DFE3E4;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    width: 100%;
                    color: #259945;
                    font-weight: 800;
                    font-family: Avenir;
                    font-size: 16px;
                    height: 48px;
                    margin-bottom: 20px;

                }
            }

        }

    }


}