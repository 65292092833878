.react-international-phone-input-container {
  width: 100%;
  gap: 10px;
  &.phone-error {
    .react-international-phone-input {
      border-color: red;
    }
  }
  .react-international-phone-country-selector {
    .react-international-phone-country-selector-button {
      padding: 11px 9px;
      height: auto;
      border-radius: 10px;
    }
  }
  .react-international-phone-input {
    width: 100%;
    padding: 10px 14px;
    height: auto;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #282b2b;
  }
}

.enrollment-form {
  &.dark-mode {
    .enrollment-questions {
      .MuiTypography-body1 {
        color: white;
      }

      .MuiFormGroup-root {
        .MuiCheckbox-colorSecondary {
          &.Mui-checked {
            color: white;
          }
        }
      }

      .MuiFormControlLabel-root {
        .MuiCheckbox-colorSecondary {
          &.Mui-checked {
            color: white;
          }
        }
      }

      .MuiFormGroup-root {
        .MuiRadio-colorSecondary {
          &.Mui-checked {
            color: white;
          }
        }
      }

      .MuiIconButton-label {
        color: white;
      }
    }
  }

  .MuiIconButton-label {
    color: #213649;
  }

  .enrollment-questions {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .options-container {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .MuiFormControlLabel-root {
        .MuiButtonBase-root {
          padding: 0 9px 0 9px;
        }
      }
    }

    .MuiTypography-body1 {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      color: #213649;
    }

    .MuiOutlinedInput-root {
      border-radius: 10px;
      overflow: hidden;
      padding: 0;
    }

    .MuiFormGroup-root {
      .MuiRadio-colorSecondary {
        &.Mui-checked {
          color: #213649;
        }
      }
    }

    .MuiInputBase-input {
      font-size: 14px;
      font-weight: 400;
      background-color: white;
      padding: 15.7px 14px;
    }

    .MuiFormControlLabel-root {
      margin-bottom: 0px;
      .MuiCheckbox-colorSecondary {
        &.Mui-checked {
          color: #213649;
        }
      }
    }
  }
}
