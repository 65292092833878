@import '../../../../utils/styles/styles.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  .form_body {
    display: flex;
    justify-content: center;
    align-items: center;

    .form_container {
      text-align: center;
      flex-basis: 846px;
      padding: 20px 20px 0px;

      .field_container {
        margin-bottom: 24px;

        .error {
          &:after {
            border-bottom-color: #dfe3e4 !important;
          }
        }

        .select {
          width: 100%;

          div {
            display: flex;
            align-items: center;
          }
        }

        .personalize_dropdown {
          // position: absolute;
          // top: 42px;
          // right: 20px;
          font-family: Avenir;
          font-size: 12px;
          font-weight: 600;
          line-height: 20px;
          color: rgba(0, 0, 0, 1);
          display: flex;
          gap: 5px;
          cursor: pointer;
          padding-left: 10px;
        }

        .personalize_dropdownpanel {
          position: absolute;
          top: 80px;
          right: -10px;
          box-shadow: 0 2px 4px 0 rgba(157, 157, 157, 0.3);
          display: flex;
          flex-direction: column;
          gap: 0px;
          background-color: white;
          width: 200px;

          .personalize_dropdownpanel_item {
            border-bottom: 1px solid #e7e7e7;
            text-align: left;
            padding: 10px 15px;
            cursor: pointer;
            font-family: Avenir;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.5px;
            color: #4a4a4a;
            justify-content: space-between;
            display: flex;
            align-items: center;
          }

          .personalize_dropdownpanel_item_last {
            text-align: left;
            padding: 10px 15px;
            cursor: pointer;
            font-family: Avenir;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.5px;
            color: #4a4a4a;
            justify-content: space-between;
            display: flex;
            align-items: center;
          }
        }
      }

      .title_class {
        font-size: 22px;
        font-weight: 400;
        font-family: $font-family-Avenir;
        margin-bottom: 40px;
      }
    }
  }
}

.stepper_container {
  width: 100%;
}

.email_main_container {
  width: 100%;

  .email_main {
    width: 100%;
  }

  .email_root {
    border: 1px solid rgba(0, 0, 0, 0.23);
  }

  .email_notchedOutline {
    border: none;
  }

  .email_adornedEnd {
    padding-right: 1px;
  }

  .endAdornment {
    background-color: #f5f5f5;
    padding: 10px 25px;

    .select_root {
      padding-right: 100px;

      @media screen and (max-width: 425px) {
        padding-right: 25px;
      }
    }

    @media screen and (max-width: 425px) {
      padding: 10px 10px;
    }
  }
}