.container {
  .dd_title {
    font-family: Avenir;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #213649;
    margin-bottom: 10px;

    .input {
      padding: 11.5px 14px !important;
    }
  }

  .selected_item {
    font-family: Avenir;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #213649;
  }

  .unselected_item {
    font-family: Avenir;
    font-weight: 300;
    font-size: 16px;
    line-height: 16px;
    color: #213649;
  }
}

.select_select {
  &:focus {
    padding: 15px 14px;
  }
}

.select_input_root {
  height: 50px !important;
  border: 1px solid #e7e7e7 !important;
}

.select_input_notchedOutline {
  border: none !important;
}

.textfield_input_root {
  height: 50px;
  border: 1px solid #e7e7e7 !important;
}

.textfield_input_notchedOutline {
  border: none !important;
}
