@import '../../../../../utils/styles/styles.scss';

.heading {
  font-size: 20px;
  font-weight: 300;
  font-family: $font-family-Avenir;
}

.color_select {
  width: 140px !important;
  background-color: transparent;
}
