.text {
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #1e1e1e;
}

.buttons_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: flex-end;

  .invite_button {
    font-family: Avenir !important;
    font-size: 14px !important;
    font-weight: 900 !important;
    line-height: 24px !important;
    text-align: left !important;
    padding: 10px 10px !important;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    color: white;
    background: #c9b382 !important;
    border-radius: 4px;
    letter-spacing: 0.25px !important;
  }

  .resources_container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;

    .resources_button {
      font-family: 'Avenir' !important;
      font-size: 14px !important;
      font-weight: 800 !important;
      line-height: 24.5px !important;
      text-align: center !important;
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
      align-items: center !important;
      padding: 10px !important;
      color: #c9b382 !important;
      border: 1px solid #c9b382 !important;
      letter-spacing: 0.25px !important;
    }
  }
}
