.container {
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .headerText {
      font-family: Avenir;
      font-size: 22px;
      font-weight: 900;
      line-height: 30.05px;
      letter-spacing: 0.1899999976158142px;
      text-align: left;
      color: #282b2b;
    }
  }

  .bottom_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-top: 10px;

    .welcome_button {
      font-family: 'Avenir' !important;
      font-size: 14px !important;
      font-weight: 800 !important;
      line-height: 24.5px !important;
      text-align: center !important;
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
      align-items: center !important;
      padding: 6px 16px 6px 16px !important;
      height: 48px !important;
      width: fit-content !important;
    }

    .orderList {
      position: relative;
      left: -20px;

      .text {
        font-family: Avenir;
        font-size: 16px;
        font-weight: 350;
        line-height: 24px;
        text-align: left;
        color: #000000de;
        margin-top: 10px;
      }
    }

    .tnc {
      font-family: Avenir;
      font-size: 12px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: #215c73;
      text-decoration: underline;
      cursor: pointer;
      margin-bottom: 20px;
    }
  }
}
