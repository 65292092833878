.container {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.delete_modal_main {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 20px;

  .top_heading {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 800;
    line-height: 16px;
    color: black;
  }

  .form_control_label_container {
    .checkbox_root {
      padding-top: 0px;

      &:hover {
        background-color: transparent;
      }
    }

    .form_control {
      display: flex;
      align-items: flex-start;
    }

    .form_label {
      font-family: Avenir;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: black;
    }
  }

  .contact_list_heading {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 800;
    line-height: 24.5px;
    color: black;
  }

  .contact_list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
}