.StripeElement {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 6px 11px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  transition: all 0.3s;

  &:hover {
    border-color: #40a9ff;
  }

  &.StripeElement--invalid {
    border-color: #ff4d4f;
  }
}
