@import "~utils/styles/styles.scss";

#summary_check.MuiExpansionPanelSummary-root {
  .Mui-expanded {
    justify-content: center;
  }
}
.course-session-time {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px;
  background-color: #fafafa;
  border-radius: 4px;
  border: 1px solid #eaf0f2;

  .course-session-times-block-wrapper {
    display: flex;

    @media screen and (max-width: 1400px) {
      order: 2;
      padding-right: 25px;
    }

    @media screen and (max-width: 1199px) {
      order: 1;
    }

    @media screen and (max-width: 950px) {
      order: 2;
    }
  }

  .course-session-times-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__number {
    padding-right: 20px;
    @media screen and (max-width: $mobile-size) {
      font-size: 14px;
    }
  }

  &__wrapper {
    padding-right: 50px;

    @media screen and (max-width: $lg-desktop) {
      margin-bottom: 25px;
    }

    @media screen and (max-width: $lg-desktop) {
      padding-right: 25px;
    }
    &--pointer {
      cursor: pointer;
    }
  }

  &__sub-category {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__label {
    margin-bottom: 10px;

    @media screen and (max-width: $mobile-size) {
      font-size: 14px;
    }
  }
  &__selfpaced-label {
    font-weight: 900;
    margin-bottom: 10px;
    font-family: Avenir;
    font-size: 18px;
    @media screen and (max-width: $mobile-size) {
      font-size: 18px;
    }
  }

  &__value {
    margin-bottom: 0px;
    @media screen and (max-width: $mobile-size) {
      font-size: 12px;
    }
  }

  &__actions {
    align-self: center;
    display: flex;
    justify-self: flex-end;
    order: 3;
    position: relative;

    button:nth-child(even) {
      margin-left: 10px;
    }
  }

  &__attach-recording-container {
    width: 100%;
    order: 4;
  }

  &__attach-recording {
    float: right;
    // width: 172px;
    width: fit-content;
  }
  &__attach-selfpaced {
    float: right;
    // width: 220px;
    width: fit-content;
  }
  &__self-paced-actions {
    align-self: center;
    display: flex;
    justify-self: flex-end;
    position: relative;

    @media screen and (max-width: 1199px) {
      margin: initial;
      order: 2;
      padding-right: 25px;
    }

    @media screen and (max-width: 949px) {
      margin: auto;
      order: 1;
      padding-right: 25px;
      width: 100%;
    }

    @media only screen and (max-width: 950px) {
      display: flex;
      margin: auto;
      order: 1;
      padding: 0;
    }

    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
      display: flex;
      order: 1;
      width: 100%;
    }

    button:nth-child(even) {
      margin-left: 10px;
    }
  }
}
