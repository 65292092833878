.mainContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;

  .partner_container {
    border: 1px solid #dfe3e4;
    padding: 40px 24px 40px 24px;
    gap: 20px;
    border-radius: 4px;
    .partner_details {
      margin-top: 30px;
      display: flex;
      flex-direction: row;

      .partner_details_description {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        justify-content: space-between;
        margin-top: 5px;

        .partner_title {
          font-family: Avenir;
          font-size: 22px;
          font-weight: 800;
          line-height: 18px;
          text-align: left;
          color: '#000000DE';

          .sub_detail {
            font-family: Avenir;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            text-align: left;
            color: '#000000DE';
            margin-left: 10px;
          }
        }

        .referrals_detail {
          font-family: Avenir;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          text-align: left;
          color: '#000000DE';
        }
        .referrals_detail_sub {
          font-family: Avenir;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          text-align: left;
          color: '#4A4A4A';
        }

        .referrals_detail_date {
          font-family: Avenir;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          text-align: left;
          color: '#4A4A4A';
        }
      }
    }

    .partner_details_progress {
      width: 100%;
      margin-top: 30px;

      .partner_details_progress_bar {
        width: 100%;
        height: 6px;
        background-color: #f5f5f5;
        border-radius: 10px;

        .partner_details_progress_bar_filled {
          height: 6px;
          background-color: #215c73;
          border-radius: 10px;
        }
      }

      .partner_details_progress_values {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;

        .progress_value {
          font-family: Avenir;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          text-align: left;
          color: '#000000DE';
        }
      }
    }

    .learn_more {
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      font-family: Avenir;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      text-align: left;
      color: #215c73;
      align-items: center;
      text-decoration: underline;
      cursor: pointer;
      gap: 4px;
    }
  }
}
