.label {
  margin-bottom: 20px;
}

.input_options {
  margin-top: -20px !important;
}

.input_sessions {
  margin-bottom: 25px !important;
}

.body-text {
  font-size: 0.875rem;
}

.expansionpanel__rootclass {
  &::before {
    display: none !important;
  }
}
