@import 'utils/styles/styles.scss';
.conversation {
  position: relative;

  height: 100%;

  &__back-to-conversations {
    position: absolute;
    top: -35px;

    cursor: pointer;
  }

  &-back-to-conversations {
    display: flex;
    align-items: center;

    &__title {
      margin: 0;

      font-weight: bold;
    }
  }
}

@media screen and (max-width: $laptop) {
  .conversation {
    margin-top: 55px;

    &__back-to-conversations {
      position: absolute;
      top: -45px;

      cursor: pointer;
    }
  }
}
