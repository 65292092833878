.card-container {
  width: 100%;

  .card-body-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__text {
      display: inline-block;
      line-height: 22px;
      margin-bottom: 0;

      &-amount {
        text-transform: uppercase;
      }
    }
  }
}
