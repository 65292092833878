.video-chat-speaker-view {
  display: grid;
  grid-template-rows: 114px 1fr;

  &__speaker {
    display: grid;
    align-items: stretch;
    justify-content: stretch;
    position: relative;
  }

  &-header {
    display: flex;
    overflow: auto hidden;

    background-color: black;

    &-participants-list {
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 0 36px;

      margin: auto;

      &__item {
        width: 168px;
        height: 84px;

        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}
