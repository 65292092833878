.delete-application-form-modal {
    .cohere-modal {
        min-width: 380px;
        width: 100%;

        .body {
            padding: 20px;
        }

        .modal-footer-container {
            .modal-footer-buttons {
                .cancel-btn {
                    padding: 10.8px 31.152px 10.8px 31.518px !important;
                }

                .submit-btn {
                    padding: 11px 30.231px 11px 30.589px !important;
                }
            }
        }
    }
}
