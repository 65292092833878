.video-chat-notes-panel {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &__title {
    align-self: start;
    margin: 5px;
    margin-bottom: 20px;
    font-weight: bold;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin: 23px 2px 23px 23px;

    & button {
      margin-left: 10px;
      min-width: 100px;
    }
  }

  &__statusBar {
    display: none;
    margin: 5px 0px 5px 5px;
    text-align: right;

    & p {
      margin: unset;
      font-size: 12px;
    }
  }
}