.application-selection-modal {
  .cohere-modal {
    width: 100%;

    .body {
      padding: 20px;
    }
  }
}

.contribution-dark-mode {
  .cohere-modal {
    .application-form {
      .content-container {
        .question-container {
          .title {
            color: white;
          }
        }
      }
      .text-field {
        background: #252728;
        color: white;

        &.big {
          padding: 16px;
        }
        &.small {
          padding: 10px;
        }
      }
      .MuiOutlinedInput-root {
        border-color: #252728 !important;
        .MuiSvgIcon-root {
          color: white !important;
        }
      }
    }
  }
}

.application-template-modal {
  .cohere-modal {
    width: 100%;
    .body {
      padding: 20px;
    }
    .cancel-btn {
      padding: 10.8px 31.152px 10.8px 31.518px !important;
    }
    .submit-btn {
      padding: 11px 30.231px 11px 30.589px !important;
    }
    .select {
      .MuiSelect-select {
        &:focus {
          background-color: white;
        }
      }
      .MuiInputBase-root {
        color: #000;
        border: 1px solid #0000003b;
        border-radius: 4px;
        height: 60px;
        padding: 5px;
        padding-left: 0.87rem;
      }
      .MuiSelect-icon {
        margin-right: 0.87rem;
      }
      &.default {
        .MuiInputBase-root {
          color: #d3d3d3;
          border: 1px solid #0000003b;
          border-radius: 4px;
          height: 60px;
          padding: 5px;
          padding-left: 0.87rem;
        }
      }
      .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: unset;
      }
    }
  }
}

.application-form {
  .options-container {
    display: flex;

    .MuiFormControl-root {
      .MuiOutlinedInput-root {
        &.Mui-focused {
          .MuiOutlinedInput-notchedOutline {
            border-width: 1px;
          }
        }
        .MuiInputBase-input {
          &::placeholder {
            color: #282b2b;
          }
        }
      }
    }
  }

  .content-container {
    max-height: 40vh;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: 4px;

    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 5px;
    }
  }

  .select {
    .MuiSelect-select {
      &:focus {
        background-color: white;
      }
    }
    .MuiInputBase-root {
      color: #000;
      border: 1px solid #0000003b;
      border-radius: 4px;
      height: 60px;
      padding: 5px;
      padding-left: 0.87rem;
    }
    .MuiSelect-icon {
      margin-right: 0.87rem;
    }
    &.default {
      .MuiInputBase-root {
        color: #d3d3d3;
        border: 1px solid #0000003b;
        border-radius: 4px;
        height: 60px;
        padding: 5px;
        padding-left: 0.87rem;
      }
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: unset;
    }
  }

  .text-field {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dbe7f2;
    background: #f5f5f5;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: #b2cae0;

    &.big {
      padding: 16px;
      background: white;
    }
    &.small {
      padding: 10px;
    }
  }

  .question-delete-button {
    width: 119px;
    height: 36px;
    margin: 0px;
    border-radius: 5px;
    border: 1.25px solid #000;
  }

  .question-add-button {
    height: 36px;
    padding: 7px 16px;
    border-radius: 5px;
  }

  .saved-question {
    > div:first-child {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .drag-container {
      display: flex;
      gap: 0.62rem;

      .drag-icon {
        color: #b2cae0;
        cursor: grab;
      }

      .question-container {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
        width: 100%;

        .MuiInputBase-input {
          &::placeholder {
            color: #475059;
          }
        }
      }
    }

    .title {
      display: inline-block;
      overflow-wrap: break-word;
      color: #213649;
      font-family: Avenir;
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: 20px; /* 100% */
    }

    .optional {
      color: #213649;
      font-family: Avenir;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 350;
      line-height: 1.25rem;
    }

    .icon-button {
      padding: 0px 5px;
    }
  }

  .add-question {
    margin-top: 20px;
    button {
      height: 48px;
      padding: 5px 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }
}

.create-application-form-modal {
  .cohere-modal {
    .header {
      border-bottom: 1px solid #e7e7e7;
    }
    .body {
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }
}

.app-form-created-success {
  .cohere-modal {
    width: 500px;
    min-height: 175px;
  }
}

.quiz_btns_container {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 576px) {
    flex-direction: column;
    margin-top: 10px;
  }
}
