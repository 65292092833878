$CreateCampaignBtnColor: #c9b382;
$CreateCampaignDisabledBtnColor: #ededed;

.container {
  .connectAccountBtn {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 900;
    line-height: 16px;
    background-color: $CreateCampaignBtnColor;
    border: 1px solid $CreateCampaignBtnColor;
    border-radius: 4px;
    color: white;
    padding: 15px 25px;

    &:disabled {
      background-color: $CreateCampaignDisabledBtnColor;
      border: 1px solid $CreateCampaignDisabledBtnColor;
      color: #a7a7a7;
    }
  }

  .checkbox_root {
    padding: 0px 5px 0px 0px !important;
  }
}

.text {
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 32.16px;
  text-align: left;
  color: #000000;
}