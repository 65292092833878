.container {
    padding: 10px 20px;

    .left_column {
        display: flex;
        flex-direction: column;
        padding: 20px 0px;

        .header {
            font-family: Avenir;
            font-size: 24px;
            font-weight: 800;
            line-height: 16px;
            text-align: left;
            color: #000000DE;
        }

        .orderList {
            position: relative;
            left: -20px;
            margin-top: 20px;

            .text {
                font-family: Avenir;
                font-size: 16px;
                font-weight: 350;
                line-height: 24px;
                text-align: left;
                color: #000000DE;
                margin-top: 10px;

            }
        }

        .button_container {
            display: flex;
            flex-direction: column;
            gap: 50px;

            .welcome_button {
                font-family: 'Avenir' !important;
                font-size: 14px !important;
                font-weight: 800 !important;
                line-height: 24.5px !important;
                text-align: center !important;
                display: flex !important;
                flex-direction: row !important;
                justify-content: center !important;
                align-items: center !important;
                padding: 6px 16px 6px 16px !important;
                height: 48px !important;
                width: fit-content !important;
            }

            .tnc {
                font-family: Avenir;
                font-size: 12px;
                font-weight: 500;
                line-height: 24px;
                text-align: left;
                color: #215C73;
                text-decoration: underline;
                cursor: pointer;
            }
        }

    }
}