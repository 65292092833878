.footer-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .cancel-upload {
    background-color: white;
    color: black;
    border: 1.25px solid #215c73;
    &:hover {
      background-color: white;
    }
    &:disabled {
      opacity: 0.8;
    }
  }

  button {
    text-align: center;
    padding: 10px 16px;
    border-radius: 4px;
    font-weight: 600;
    line-height: 22px;
  }
}
.add-thumbnail-popup {
  .cross-icon-hide {
    padding-top: 0 !important;
  }

  .body {
    padding: 16px;
    min-width: 500px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
@media screen and (max-width: 575px) {
  .add-thumbnail-popup {
    .body {
      min-width: 300px;
    }
  }
}
